import React from 'react'
import { Col, FormFeedback, Label, Row } from 'reactstrap'
import Select from "react-select"

function DropDown({id, label, name,  options, onChange, onBlur, value,  errors, touched,}) {
  return (
    <Row className="mb-3">
        <Col lg={5} sm={7} className="mx-auto">
            <Label htmlFor={id}>{label}</Label>
            <Select
                id={id}
                name={name}
                className="border border-1 border light"
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
            />
            {errors && touched ? (
            <FormFeedback type="invalid">{errors}</FormFeedback>
          ) : null}
        </Col>
    </Row>
  )
}

export default DropDown