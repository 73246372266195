import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container,Row } from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { Link } from "react-router-dom";
import { getUsers } from "slices/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import convertDateFormat from "Components/Custom/DateFormat";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import { deleteUser } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getRoles } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";
import { getTeams } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import withRouter from "Components/Common/withRouter";

const head = [
  { id: 1, name: "#", isSortable: true, key: "id" },
  { id: 2, name: "User", isSortable: true, key: "name"},
  { id: 3, name: "User Type", isSortable: true, key: "user_type"},
  { id: 4, name: "Role", isSortable: true, key: "role"},
  { id: 8, name: "Teams",},
  { id: 5, name: "Created", isSortable: true, key: "created_at"},
  { id: 6, name: "Updated", isSortable: true, key: "updated_at"},
  { id: 7, name: "Actions"}
];
// redux code
const selectProperties = createSelector(
  (state: any) => state.users,
  (users) => ({
    users: users.usersList,
    loading: users.loading,
    pagination: users.pagination
  })
);

function Users({ router }: any) {
  const { navigate, location } = router;
  document.title = "Users | Content Pro";

  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const { users, loading, pagination } = useSelector(selectProperties);

  // local state mng for getting business data
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(loading);
    setUsersData(users);
  }, [users, loading]);

  // for deletion 
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] =useState<any>(null);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    if (deleteItem) {
      const response = await deleteUser(deleteItem.id);
      setDeleteItem(null);
      setDeleteModal(false);
      setIsDeleted(response); 
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const [filterData, setFilterData] = useState<any>({roles: [], teams: []}); 
  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {

      const roles = await getRoles(businessId, navigate);
      const teams = await getTeams(businessId, "", navigate); 
      setFilterData({
        teams: teams?.map(item => ({ label: item.team_name, value: item.id })) || [],
        roles: roles?.map(item => ({ label: `${item.name}`, value: item.id })) || []
      });
      setIsLoading(false);
    }
    getData();
  }, [businessId, navigate]);

  return (
  <>
    <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => {setDeleteModal(false); setDeleteItem(null);}}
      message={`User ${deleteItem?.name} with role ${deleteItem?.roles[0].name}`}
    />

    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Card className="spiner_div_activities">
              <PaginatedTable
                tableHead={head}
                onGetData = {getUsers}
                pagination = {pagination}
                isLoading = {isLoading}
                buttonText = "Add New User" 
                isAddButton = {true} 
                to={`create?businessId=${businessId}`}
                query = {`business_id=${businessId}`}
                isDeleted = {isDeleted}
                title = 'Users List'
                businessHead=""
                hasFilter={true}
                filterData= {[
                  { label: 'Roles',key: 'role_id',options: filterData.roles}, 
                  { label: 'Teams',key: 'team_id',options: filterData.teams}, 
                ]}
              >
                {!isLoading && usersData.length !== 0 && usersData.map((item : any, index : any) => (
                  <tr key={item.id}>

                    <th scope="row">{item.id}</th>
                    <td className="d-flex">
                      <div className="flex-shrink-0 me-2 my-auto">
                        {item?.image ? 
                          <img src={item?.image} alt="" className="avatar-xs rounded-circle"/>
                          :
                          <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                              {item.name?.charAt(0)}
                            </div>
                          </div>
                        }
                        </div>
                        <div>
                        <h5 className='font-size-14 mb-1'>
                          {item.name}
                          {/* <Link to={`${item.id}`} className='text-dark'>{item.name}</Link> */}
                        </h5>
                        <p className="text-muted mb-0">{item.email}</p>
                      </div>
                    </td>
                    <td>{item?.user_type}</td>
                    <td>{item?.roles?.[0]?.name}</td>
                    <td>{item.teams.map(item => <span className="badge badge-soft-info font-size-11 m-1" key={item.id}>{item.team_name}</span>) || 'No Assigned'}</td>
                    <td>{convertDateFormat(item.created_at)}</td>
                    <td>{convertDateFormat(item.updated_at)}</td>
                    <td className="d-flex">
                      {item?.user_type === "Member" && <>
                      <Link to={`${item.id}/edit?businessId=${businessId}`} className="btn btn-sm btn-soft-info mx-2 ">
                        <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                      </Link> 
                      <Button className="btn btn-sm btn-soft-danger " onClick={() => {setDeleteModal(true); setDeleteItem(item)}}>
                        <CustomIcon className="mdi " iconName= "mdi-trash-can" id={`delete-${item.id}`} text="Delete"/>
                      </Button>
                      </>}
                    </td>

                  </tr>
                ))}
                {!isLoading && usersData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
              </PaginatedTable>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </>
  );
}

export default withRouter(Users);
