import React from 'react';
import { LinkWithQuery } from "helpers/link_helper";
import CustomIcon from "Components/Custom/CustomIcon";

const ActionButton = ( item, returnURL, setCancelItem ) => {
    const status = item?.publish_status_name; 
    const platformID = item?.id; 
    const link = item?.publish_url;
  
  const buttons = {
    view: (
      <a
        target="_blank"
        href={link}
        rel="noreferrer"
        className="btn btn-sm btn-soft-success me-1"
        title="View Post"
      >
        <CustomIcon className="mdi" iconName="mdi-eye-outline" text="View" id={`actionButton_${platformID}`} />
      </a>
    ),
    details: (
      <LinkWithQuery
        project={true}
        to={platformID}
        className="btn btn-sm btn-soft-secondary me-1"
        title="View Details"
        queries={`return=${returnURL}`}
      >
        <CustomIcon className="mdi" iconName="mdi-file-document" text="Details" id={`actionButton_${platformID}`} />
      </LinkWithQuery>
    ),
    edit: (
      <LinkWithQuery
        project={true}
        to={platformID}
        className="btn btn-sm btn-soft-primary me-1"
        title="Edit Post"
        queries={`return=${returnURL}`}
      >
        <CustomIcon className="mdi" iconName="mdi-pencil-outline" text="Edit" id={`actionButton_${platformID}`} />
      </LinkWithQuery>
    ),
    cancel: (
      <button
        className="btn btn-sm btn-soft-warning me-1"
        title="Cancel Post"
        onClick={() => {setCancelItem(item)}}      >
        <CustomIcon className="mdi" iconName="mdi-cancel" text="Cancel" id={`actionButton_${platformID}`} />
      </button>
    ),
    republish: (
      <LinkWithQuery
        project={true}
        to={platformID}
        className="btn btn-sm btn-soft-danger me-1"
        title="Republish"
        queries={`return=${returnURL}`}
      >
        <CustomIcon className="mdi" iconName="mdi-replay" text="Republish" id={`actionButton_${platformID}`} />
      </LinkWithQuery>
    ),
  };

  const renderButton = () => {
    switch (status) {
      case "Published":
        return buttons.view;
      case "Scheduled":
        return buttons.cancel;
      case "Draft":
        return buttons.edit;
      case "Ready For Publish":
      case "Failed":
        return buttons.edit;
      case "Cancelled":
        return buttons.republish;
      default:
        return <span>Error</span>;
    }
  };

  return <>{renderButton()}</>;
};

export default ActionButton;
