import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import SocialIcon from "../Activity/UI/SocialIcon";

interface IntegrationProps {
  platforms: {
    id: number, 
    name: string, 
    slug:string, 
    status: string, 
    posts: number
  }[]; 
}

const IntegrationsStats: React.FC<IntegrationProps> = ({platforms}) => {
  console.log(platforms)

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="shadow-sm border-0 dashboard-activity-bg">
            <CardBody>
            <CardTitle className="h4 mb-4">Social Integrations Stats</CardTitle>
              <Row>
                {platforms.length !== 0 && platforms.map((platform) => (
                  <Col xs={6} md={3} key={platform?.id} className="mb-3">
                    <div className="d-flex  ">
                      <div className="mb-2">
                        <SocialIcon
                          slug={platform?.slug}
                          title={""}
                        />
                      </div>
                      <div className="d-flex flex-column items-start">
                      <div className="avatar-xs avatar-xs-social-integration ">
            <div className="  text-primary  ">
              {platform?.name}
            </div>
          </div>
                     
                      <p className="my-0 py-0">
                        <strong>Posts:</strong> {platform.posts}
                      </p>
                      <p
                        className={`mb-0 font-weight-bold text-${
                          platform.status === "Connected" ? "success" : "danger"
                        }`}
                      >
                        {platform.status}
                      </p>
                      </div>
                     
                    </div>
                  </Col>
                ))}
                {platforms.length === 0 && 
                  <p className="text-danger my-4">No accounts are currently integrated. Please add an account to view its stats.</p>
                }
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default IntegrationsStats;
