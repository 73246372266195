import React, { useEffect, useState } from "react";
import withRouter from "Components/Common/withRouter";
import GoBackButton from "Components/Common/GoBackButton";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import ActivityDetail from "./Details/ActivityDetail";
import {
  getActivityDetail,
  getPriorities,
} from "helpers/API Calls/Project/Activity/Activity";
import Priority from "./Details/Priority";
import HashTags from "./Details/HashTags";
import ImageGallery from "./Details/Media/ImageGallery";
import AuthorNotes from "./Details/AuthorNotes";
import ActivityFeed from "./Details/ActivityFeed";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getUsers } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getActivityStates } from "helpers/API Calls/Project/Settings/Activity State";
import Comments from "./Details/Comments";
import { getRoleDetail } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    user: user.user,
    permissions: user.permissions,
  })
);

function ProjectsOverview({ router }: any) {
  const { navigate, location, params } = router;
  //meta title
  document.title = "Activity Details | Content Pro";
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const projectId = searchParams.get("projectId");
  const returnURL = searchParams.get("return");
  const activityId = params.id;

  const { user, permissions } = useSelector(selectProperties);

  const [activityData, setActivityData] = useState<any>(null);
  const [priorityOptions, setPriorityOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState([]);
  const [activityStates, setActivityStates] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      // activity data
      const data = await getActivityDetail(activityId, navigate);
      if (data) {
        setActivityData(data);
      } else {
        setActivityData(null);
      }

      // priority
      const priorities = await getPriorities();
      if (priorities) {
        setPriorityOptions(
          priorities.map((item) => {
            return { label: item.priority_name, value: item.id };
          })
        );
      }

      // users
      const users = await getUsers(businessId, navigate);
      if (users && users.length > 0) {
        setUsers(users.map((item) => ({ name: item.name, id: item.id })));
      } else {
        setUsers([]);
      }

      // states
      // getting states for dropdown based on user
      let stateData;
      if (user?.user_type === "Member") {
        const roleStates = await getRoleDetail(user?.roles?.[0]?.id, navigate);
        // filter states for current project
        stateData = roleStates?.action_states?.filter(
          (item) => Number(item?.project_id) === Number(projectId)
        );
      } else {
        stateData = await getActivityStates(projectId, navigate);
      }

      if (stateData && stateData.length > 0) {
        setActivityStates(
          stateData?.map((item) => ({
            label: item.state_name,
            value: item.id,
            color: item.color,
          }))
        );
      } else {
        setActivityStates([]);
      }

      setIsLoading(false);
    };
    getData();
  }, [activityId, businessId, projectId, user, navigate]);

  const canEdit =
    user?.user_type === "Member" ? permissions?.includes("edit_post") : true;
  const canEditState =
    user?.user_type === "Member"
      ? permissions?.includes("edit_post_state")
      : true;
  const canArchive =
    user?.user_type === "Member" ? permissions?.includes("archive_post") : true;
  const canUploadFiles =
    user?.user_type === "Member" ? permissions?.includes("upload_files") : true;
  const canManageLogs =
    user?.user_type === "Member"
      ? permissions?.includes("view_activity_logs")
      : true;
  const canManageVersions =
    user?.user_type === "Member"
      ? permissions?.includes("view_version_logs")
      : true;
  const canPublish =
    user?.user_type === "Member" ? permissions?.includes("publish_post") : true;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <GoBackButton
            to={`/${returnURL !== null ? returnURL : "posts-list"}`}
            project={true}
          />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <div className="spiner_div ">
                  <Spinner color="primary" />
                </div>
              ) : (
                <Card>
                  {activityData ? (
                    <CardBody>
                      <ActivityDetail
                        activityInfo={{
                          createdAt: activityData?.created_at,
                          createdBy: activityData?.creator_name,
                          title: activityData?.title,
                          dueDate: activityData?.due_date,
                          team: activityData?.team_name,
                          status: activityData?.status,
                        }}
                        activityContent={{
                          content: activityData?.content,
                          updatedAt: activityData?.content_updated_at,
                          updatedBy: activityData?.content_updated_by_name,
                        }}
                        activityState={{
                          state: activityData?.activity_state?.state_name,
                          state_color: activityData?.activity_state?.color,
                          state_id: activityData?.activity_state?.id,
                        }}
                        activityType={{
                          type: activityData?.activity_types.activity_type,
                          type_color: activityData?.activity_types.color,
                        }}
                        additionalData={{
                          projectId,
                          activityId,
                          activityStates,
                          queries: `return=${returnURL}`,
                        }}
                        permissions={{
                          canEdit,
                          canArchive,
                          canPublish,
                          canManageLogs,
                          canEditState,
                          canManageVersions,
                        }}
                      />

                      <div className="d-md-flex d-sm-block ">
                        {/* <Category/> */}
                        <Priority
                          priorityOptions={priorityOptions}
                          selectedPriority={{
                            label: activityData?.priority_name,
                            value: activityData?.priority_id,
                          }}
                          id={activityData?.id}
                          canEdit={canEdit}
                        />
                        <HashTags
                          tags={
                            activityData?.activity_tags?.[0]?.tag_name || ""
                          }
                          id={activityData?.id}
                          canEdit={canEdit}
                        />
                      </div>

                      <Row className="my-4" style={{ overflowX: "auto" }}>
                        <h5 className="fw-bold font-size-21 pb-1">Media</h5>
                        <ImageGallery
                          files={activityData?.files.map((item) => {
                            return {
                              id: item.id,
                              type: item.file_type,
                              file: item.file,
                              integration_types: item.integration_types,
                            };
                          })}
                          canUploadFiles={canUploadFiles}
                          activityId={activityId}
                          projectId={projectId}
                        />
                      </Row>
                      <div >
                        <Row>
                          <AuthorNotes
                            comments={activityData?.activity_notes.map(
                              (item) => {
                                return {
                                  id: item.id,
                                  user: item.user,
                                  comment: item.comment,
                                  updatedAt: item.updated_at,
                                };
                              }
                            )}
                            user={user}
                            users={users}
                          />
                        </Row>

                        <Row>
                          <ActivityFeed
                            activity={
                              activityData?.activity_logs.map((item) => ({
                                name: item?.user?.name,
                                timestamp: item.created_at,
                                action: item.content,
                              })) || []
                            }
                            returnURL={returnURL}
                          />
                        </Row>

                        <Row>
                          <Comments
                            feed={activityData?.activity_discussions.map(
                              (item) => {
                                return {
                                  id: item.id,
                                  user: item.user,
                                  comment: item.comment,
                                  updatedAt: item.updated_at,
                                  action_type: "message",
                                };
                              }
                            )}
                            user={user}
                            users={users}
                          />
                        </Row>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody className="text-center">
                      <h4 className="text-danger">
                        Activity Details Unavailable
                      </h4>
                      <p className="text-muted">
                        We're sorry, but we couldn't retrieve the activity
                        details at this time. This may be due to insufficient
                        permissions, missing data, or a network error. Please
                        try refreshing the page or contact support if the issue
                        persists.
                      </p>
                    </CardBody>
                  )}
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withRouter(ProjectsOverview);
