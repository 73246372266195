import React, { useEffect, useState } from 'react'
import { Form, Card, CardBody, Col, Row, Button, Label } from "reactstrap";
import InputElement from 'Components/Custom/InputElement';
import * as yup from "yup";
import { useFormik } from "formik";
import {getBusinessDetail, editBusiness} from "helpers/API Calls/Super Admin/businesses"
import {getTimezones} from "helpers/API Calls/General/timezone"
import { Spinner } from "reactstrap";
import GoBackButton from 'Components/Common/GoBackButton';
import placeholderImage from "assets/images/img.png"
import Dropzone from 'Components/Custom/DropZone';
import DropDown from 'Components/Custom/DropDown';
import withRouter from 'Components/Common/withRouter';

const statuses = [
  { label: "Active", value: "1" },
  { label: "In Active", value: "0" },
]

function EditBusiness({ router }: any) {
  const { navigate, params } = router;
  document.title = "Edit Business | Content Pro";

  const businessId: any = params.businessId;

  // local state mng for getting data
  const [businessData, setBusinessData] = useState<any>({});
  const [timezones, setTimezones] = useState<any>([]); 
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // image handling
  const [imageUrl, setImageUrl] = useState<string>(""); // state for image URL
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
   
  //Basic Information
  const formik: any = useFormik({
    initialValues: {
      businessName: "",
      ownerName: "", 
      ownerEmail: "", 
      timezone: {}, 
      status: {}, 
    },
    validationSchema: yup.object().shape({
      businessName: yup.string()
      .matches(/^[A-Za-z0-9\s]+$/, 'Organization Name can only contain letters, numbers, and spaces')
      .min(3, 'Organization Name must be at least 3 characters')
      .max(50, 'Organization Name cannot exceed 50 characters')
      .required("Please Enter Your Organization Name"),
      ownerName: yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Owner Name can only contain letters and spaces')
      .min(3, 'Owner Name must be at least 3 characters')
      .max(20, 'Owner Name cannot exceed 20 characters')
      .required('Please Enter Owner Name'),
      ownerEmail: yup.string()
      .email("Please enter a valid email address")
      .required("Please Enter Your Email"),
      timezone: yup.object().shape({label: yup.string().required(),value: yup.number().required("Please Select timezone."),}),
      status: yup.object().shape({label: yup.string().required(),value: yup.string().required(),})
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        business_name: values.businessName,
        owner_name: values.ownerName,
        owner_email: values.ownerEmail,
        timezone_id: values.timezone.value,
        status: values.status.value, 
      }
      const response = await editBusiness({data: data, image: selectedImage || null}, businessData.id, navigate); 
      if (response) {
        setSubmitting(false);
      }
      if (response?.success) {
        setTimeout(() => { navigate(`/admin/businesses`); }, 2000);
      }
      return;
    },
  });

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getBusinessDetail(businessId, navigate); 
      const timezone = await getTimezones();
      setTimezones(timezone.map(item => ({ label: `${item.name}, ${item.timezone}`, value: item.id })));  
      setBusinessData(data);
      setImageUrl(data?.image);
      formik.setValues({
        businessName: data?.business_name,
        timezone: { label: `${data?.timezone_name}, ${data?.timezone}`, value: data?.timezone_id },
        ownerName: data?.owner_name,
        ownerEmail: data?.owner_email,
        status: { label: data?.status === 1 ? "Active" : "In Active", value: data?.status }
      });
      setIsLoading(false);
    }
    getData(); 
  }, [businessId]);
  


  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Businesses" breadcrumbItem="Edit Business" isGoBackButton = {true} to="/admin/businesses" admin={true}/> */}
        <GoBackButton to="/admin/businesses" admin={true}/>
        <Row >
          <Col>
            <Card >
            {isLoading ? (
                <Spinner
                  color="primary"
                  className="position-absolute top-50 start-50"
                />
              ) : (
              <CardBody>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0 flex-grow-1 ">Edit Business</h5>
                  <div className="flex-shrink-0">{/* ride justified data */}</div>
                </div>
                <Form id="business-form" onSubmit={formik.handleSubmit} autoComplete="off">

                  <Row>
                    <Col className="mx-auto" lg={5} sm={7}>
                      <div className="mb-3">
                        <Label htmlFor="name-input">Business Logo</Label>
                        <Dropzone
                          accept = {{"image/*": [".png", ".jpeg", ".jpg"]}}
                          onDrop = {(acceptedFiles) => {setSelectedImage(acceptedFiles[0]);}}
                          renderContent = {() => (
                            <div className="text-center cursor-pointer">
                              <div className="position-relative d-inline-block" >
                                <div className="avatar-xl rounded">
                                  <div className="avatar-title bg-light ">
                                    {selectedImage ? (
                                      <img src={URL.createObjectURL(selectedImage)} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    ) : (
                                      <img src={imageUrl || placeholderImage} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </Col>
                  </Row>

                  <InputElement 
                    name = "Business Name" 
                    type="text"
                    identifier="businessName"
                    value ={formik.values.businessName} 
                    onChange = {formik.handleChange} 
                    errors = {formik.errors.businessName} 
                    touched = {formik.touched.businessName}
                    onBlur={formik.handleBlur}
                  />
                  <InputElement 
                    name = "Owner's Name" 
                    type="text"
                    identifier="ownerName"
                    value ={formik.values.ownerName} 
                    onChange = {formik.handleChange} 
                    errors = {formik.errors.ownerName} 
                    touched = {formik.touched.ownerName}
                    onBlur={formik.handleBlur}
                  />
                  <InputElement 
                    name = "Owner's Email" 
                    type="email"
                    identifier="ownerEmail"
                    value ={formik.values.ownerEmail} 
                    onChange = {formik.handleChange} 
                    errors = {formik.errors.ownerEmail} 
                    touched = {formik.touched.ownerEmail}
                    disabled={true}
                    onBlur={formik.handleBlur}
                  />

                  <DropDown
                    id="timezone"
                    name="timezone"
                    label="Timezone"
                    options={timezones}
                    onChange={(selectedOption) => {formik.setFieldValue("timezone", selectedOption)}}
                    onBlur={formik.handleBlur}
                    value={formik.values.timezone}
                    touched={formik.touched.timezone}
                    errors = {formik.errors.timezone} 
                  />

                  <DropDown
                    id="business-status"
                    name="business-status"
                    label="Status"
                    options={statuses}
                    onChange={(selectedOption) => {formik.setFieldValue("status", selectedOption)}}
                    onBlur={formik.handleBlur}
                    value={formik.values.status}
                    touched={formik.touched.status}
                    errors = {formik.errors.status} 
                  />
                
                  <div className="d-flex flex-wrap gap-2 justify-content-end col-lg-5 col-sm-7 mx-auto px-1 mt-1">
                    <Button type="submit" color="success" disabled={formik.isSubmitting}>{formik.isSubmitting ? 'Saving...' : 'Save Changes'}</Button>
                  </div>

                </Form>
              </CardBody>
              )
            }
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(EditBusiness);