import { editActivity } from 'helpers/API Calls/Project/Activity/Activity';
import React, { useState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

function HashTags({ tags, id, canEdit }) {
  const [tag, setTag] = useState<string>(tags);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [originalTag, setOriginalTag] = useState<string>(tags);

  const handleTagChange = (e: any) => {
    setTag(e.target.value);
    setIsOptionSelected(true);
  };

  // Function to handle button click
  const handleButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const data = {
      edit_type: "tags",
      tags: [tag ? { tag_name: tag } : {}],
    };
    const response = await editActivity(data, id);
    if (response?.success) {
      setIsOptionSelected(false);
      setOriginalTag(tag);
    }
  };

  // Prevent form submission on pressing Enter
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
      <Form className="me-2 d-flex" id="tagForm" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <FormGroup className="select2-container d-flex">
          <Label className="form-label my-auto me-3" htmlFor="tags">
            Tags
          </Label>
            <Input
              id="tags"
              name="tags"
              type="text"
              placeholder="Enter Tags..."
              value={tag}
              onChange={handleTagChange}
              onKeyDown={handleKeyDown}
              disabled={!canEdit}
            />
          {canEdit && isOptionSelected && tag !== originalTag && (
              <Button type="button" className="ms-2 btn btn-success" onClick={handleButtonClick} disabled={!isOptionSelected}>
                <i className="fas fa-check-circle fa-lg"></i>
                
              </Button>
          )}
        </FormGroup>
      </Form>
  );
}

export default HashTags;
