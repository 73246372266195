import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button} from "reactstrap";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import CustomIcon from "Components/Custom/CustomIcon";
import { getBusinesses } from "slices/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import convertDateFormat from "Components/Custom/DateFormat";
import {deleteBusiness} from "helpers/API Calls/Super Admin/businesses"

const head = [
  {id: 1, name: "#", isSortable: true, key: "id"},
  {id: 2, name: "Business", isSortable: true, key: "business_name"},
  {id: 3, name: "Business Owner", isSortable: true, key: "owner_name"},
  {id: 8, name: "Status", isSortable: true, key: "status"},
  {id: 5, name: "Created", isSortable: true, key: "created_at"},
  {id: 6, name: "Updated", isSortable: true, key: "updated_at"},
  {id: 7, name: "Time Zone", isSortable: true, key: "timezone_name"},
  {id: 9, name: "Actions"}
]

function BusinessList() {
  document.title = "Businesses | Content Pro"

  // redux code
  const selectProperties = createSelector(
    (state: any) => state.businesses,
    (businesses) => ({
      businesses: businesses.businessList,
      loading: businesses.loading,
      pagination: businesses.pagination
    })
  );
  const { businesses, loading, pagination } = useSelector(selectProperties);

  // local state mng for getting business data
  const [businessData, setBusinessData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(loading);
    setBusinessData(businesses);
    // console.log('data from business component local state', businesses)
  }, [businesses, loading]);

  // for deletion 
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] =useState<any>();
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    if (deleteItem) {
      const response = await deleteBusiness(deleteItem.id);
      setDeleteItem(null);
      setDeleteModal(false);
      setIsDeleted(response); 
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  return (
    <>
      <DeleteModal
      show={deleteModal}
      onDeleteClick={handleDelete}
      onCloseClick={() => {setDeleteModal(false); setDeleteItem({});}}
      message={`Business ${deleteItem?.business_name}(${deleteItem?.business_key})`}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Businesses" breadcrumbItem="Businesses List" /> */}
          <Row>
            <Col>
              <Card className="spiner_div_activities"
              >
                <PaginatedTable
                  tableHead={head}
                  onGetData = {getBusinesses}
                  pagination = {pagination}
                  isLoading = {isLoading}
                  buttonText = "Add New Business" 
                  isAddButton = {true} 
                  to = 'create'
                  isDeleted = {isDeleted}
                  title = 'Businesses List'
                  
                  businessHead="business-head"
                >
                  {businessData.length !== 0 && businessData.map((item : any, index : any) => (
                    <tr key={item.id}>

                      <th scope="row">{item.id}</th>
                      <td className="d-flex">
                        <div className="flex-shrink-0 me-2 my-auto">
                          {item?.image ? 
                            <img src={item?.image} alt="" className="avatar-xs rounded-circle"/>
                            :
                            <div className="avatar-xs">
                              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                {item.business_name?.charAt(0)}
                              </div>
                            </div>
                          }
                          </div>
                          <div>
                          <h5 className='font-size-10 mb-1'>
                            <Link to={`${item.id}`} className='text-dark'>{item.business_name}</Link>
                          </h5>
                          <p className="text-muted font-size-10 mb-0">{item.business_key}</p>
                        </div>
                      </td>
                      <td>
                        <h5 className='font-size-10 mb-1'>{item.owner_name}</h5>
                        <p className="text-muted font-size-10 mb-0">{item.owner_email}</p>
                      </td>
                      <td>{item.status === 1 ? <span className="badge badge-soft-success font-size-11">Active</span> : <span className="badge badge-soft-danger font-size-11">In Active</span> }</td>
                      <td><p className="font-size-10 mb-0"> {convertDateFormat(item.created_at)}</p>
                       </td>
                      <td><p className="font-size-10 mb-0">{convertDateFormat(item.updated_at)}</p></td>
                      <td>{!(item.timezone) ? <p className="text-danger mx-auto">Not Selected</p> : 
                      <>
                        <h5 className='font-size-10 mb-1'>{item.timezone_name}</h5>
                        <p className="text-muted font-size-10 mb-0">{item.timezone}</p>
                      </>
                      }</td>
                      <td className="d-flex">
                        <Link to={`${item.id}`} className="btn btn-sm btn-soft-primary">
                            <CustomIcon className="mdi " iconName= "mdi-eye-outline" id={`view-${item.id}`} text="View"/>
                        </Link>
                        <Link to={`${item.id}/edit`} className="btn btn-sm btn-soft-info mx-2 ">
                          <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                        </Link> 
                        <Button className="btn btn-sm btn-soft-danger " onClick={() => {setDeleteModal(true); setDeleteItem(item)}}>
                          <CustomIcon className="mdi " iconName= "mdi-trash-can" id={`delete-${item.id}`} text="Delete"/>
                        </Button>
                      </td>

                    </tr>
                  ))}
                  {businessData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
                </PaginatedTable>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default BusinessList;
