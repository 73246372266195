import React, { useState } from "react";
import { Container } from "reactstrap";
import IntegrationCards from "./Integrations/integration-types";
import ConnectedAccounts from "./Integrations/Connected Accounts/connected-accounts";

function Integrations() {
  document.title = "Integrations | Content Pro";
  
  const [refreshConnectedAccounts, setRefreshConnectedAccounts] = useState(false);
  const handleRefreshConnectedAccounts = () => {setRefreshConnectedAccounts((prevState) => !prevState);};

  const [socialPlatforms, setSocialPlatforms] = useState<any[]>([]);


  return (
    <div className="page-content">
      <Container fluid>
        <ConnectedAccounts refresh={refreshConnectedAccounts} socialPlatforms={socialPlatforms} OnUpdate={handleRefreshConnectedAccounts}/>
        <IntegrationCards onAccountAdded={handleRefreshConnectedAccounts} socialPlatforms={socialPlatforms} setSocialPlatforms={setSocialPlatforms}/>
      </Container>
    </div>
  );
}

export default Integrations;
