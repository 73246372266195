import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import MediaHolder from "./MediaHolder";
import { deleteFile, uploadFile } from "helpers/API Calls/Project/Activity/files";
import { getIntegrationTypes } from "helpers/API Calls/Project/Settings/Integrations";

interface MediaItem {
  id: number;
  type: "image" | "video";
  file: string | null;
  fileData?: File | null;
  integration_types?: any[];
}

interface ImageGalleryProps {
  files: MediaItem[];
  canUploadFiles: boolean;
  activityId: number;
  projectId: string | null;
}

function ImageGallery({ files, canUploadFiles, activityId, projectId }: ImageGalleryProps) {

  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [loadingMediaId, setLoadingMediaId] = useState<number | null>(null);

  // Ensure at least one empty slot is always present
  useEffect(() => {
    if (files.length > 0) {
      const newFiles = [...files];
      // Ensure there is one empty slot at the end
      if (newFiles[newFiles.length - 1].file !== null) {
        newFiles.push({ id: newFiles.length, type: "image", file: null });
      }
      setMediaItems(newFiles);
    } else {
      // If no files, ensure there's at least one empty slot
      setMediaItems([{ id: 0, type: "image", file: null }]);
    }
  }, [files]);

  const handleMediaUpload = async (index: number, fileData: File | null, fileUrl: string | null, type: "image" | "video") => {
    setLoadingMediaId(index);
    const newMediaItems = [...mediaItems];
    newMediaItems[index] = { id: index, file: fileUrl, fileData, type, integration_types:[] };
    setMediaItems(newMediaItems);

    // Add a new empty slot if the last one is no longer empty
    if (newMediaItems[newMediaItems.length - 1]?.file !== null) {
      newMediaItems.push({ id: newMediaItems.length, type: "image", file: null });
    }

    const data = {
      file_type: type, 
      activity_id: activityId,
      file: fileData,
      project_id: projectId
    };

    const response = await uploadFile(data);
    setLoadingMediaId(null);
    if (!response?.success) {
      setMediaItems(mediaItems)
    }else{
      newMediaItems[index].id = response?.data?.id; 
      setMediaItems(newMediaItems);
    }
  };

  const handleDeleteMedia = async (id: number) => {
    setLoadingMediaId(id);
    const response = await deleteFile(id);
    setLoadingMediaId(null);
  
    if (response) {
      const newMediaItems = mediaItems.filter((media) => media.id !== id);
      
      // Check if there's no media items left after deletion
      if (newMediaItems.length === 0) {
        // If all media is deleted, ensure at least one empty slot
        setMediaItems([{ id: 0, type: "image", file: null }]);
      } else {
        // If there are other media items, just update the state without adding an empty slot
        setMediaItems(newMediaItems);
      }
    }
  };

  const [integrations, setIntegrations] = useState<[]>([]);

  useEffect(() => {
    const getData = async () => {
      const types = await getIntegrationTypes(0);
      if (types && types.length > 0) {
        setIntegrations(types.filter(item => item.type_slug !== 'facebook')?.map(item => ({ label: item.type_name, value: item.id })));
      }else{setIntegrations([]); }
    }
    getData();
  }, []);

  const handleUpdateIntegrationTypes = (tags: any[], id: number) => {
    setMediaItems((prevMediaItems) =>
      prevMediaItems.map((item) =>
        item.id === id ? { ...item, integration_types: tags.map((tag: any) => ({id: tag.value, type_name: tag.label})) } : item
      )
    );
  };

  return (
    <Col style={{ maxWidth: "fit-content" }}>
      <Row className="flex-nowrap justify-content-start">
        {mediaItems.map((media, index) => (
          <MediaHolder
            key={media.id}
            media={media}
            onMediaUpload={(fileData:any, type) => handleMediaUpload(index, fileData, URL.createObjectURL(fileData), type)}
            onDeleteMedia={() => handleDeleteMedia(media.id)}
            onSave={handleUpdateIntegrationTypes}
            tagOptions = {integrations}
            loading={loadingMediaId === (media.id)}
            canUploadFiles={canUploadFiles}
          />
        ))}
      </Row>
    </Col>
  );
}

export default ImageGallery;
