import { toast } from "react-toastify";
import request from "../../request_helper";
import getFormData from "Components/Custom/Formdata";
import toasterTime from "config";

export const updateProfile = async (reqData) => {
    const formData = getFormData(reqData);
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "update_profile",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body : formData, 
        };
        const response = await request(args); 
        const data = await response;
        toast.success(response.message, { autoClose: toasterTime});
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return {success: false, message: error.message}
    }
}

export const changePassword = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "change_password",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        const data = await response;
        toast.success(response.message, { autoClose: toasterTime});
        return data; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return {success: false, message: error.message}
    }
}