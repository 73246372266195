import React from "react";
import { Link } from "react-router-dom";

interface NotificationProps {
  message: string; 
  children?: React.ReactNode;
}


const Notification: React.FC<NotificationProps> = ({ message, children}) => {
  return (
    <div className="email-verification-notification  mb-sm-4">
      <span className="my-auto notification-color" dangerouslySetInnerHTML={{ __html: message }}/>
      {children}
    </div>
  );
};

export default Notification;


