import { publishBulkContent, scheduleBulkContent } from "helpers/API Calls/Project/Publish/Publish";
import { useState } from "react";

function usePublishActions({ publishItems,publishData, getPublishList, closeModal, inputRef, setSelectedItems }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handlePublish = async () => {
      setIsSubmitting(true);
      closeModal();
      const response = await publishBulkContent({ publish: publishItems.map(item => ({publish_content_id : publishData.items.find(data => data.id === item.id).publish_content_id}))});
      setIsSubmitting(false);
      if (response?.success) {
        getPublishList();
        setSelectedItems([])
        return response.data.map((item, index) => ({...item, post: publishItems[index]}))
      }else{
        return []
      }
    };
  
    const handleSchedule = async (event) => {
      event.preventDefault(); 
      setIsSubmitting(true);
      closeModal();
      const selectedDateTime = inputRef.current.value;

      // Create a Date object from the input value
      const date = new Date(selectedDateTime);

      // Function to pad single digits with leading zero
      const pad = (num) => String(num).padStart(2, "0");

      // Format the date as "YYYY-MM-DD HH:mm:ss"
      const formattedDate = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
      const response = await scheduleBulkContent({ schedule: publishItems.map(item => ({
        publish_content_id : publishData.items.find(data => data.id === item.id).publish_content_id,
        schedule_date : formattedDate,
      })),
      });
      setIsSubmitting(false);
      if (response?.success) {
        getPublishList();
        setSelectedItems([])
        return response.data.map((item, index) => ({...item, post: publishItems[index]}))
      }else{
        return []
      }
    };
  
    return { handlePublish, handleSchedule, isSubmitting, setIsSubmitting };
  }
  
export default usePublishActions; 

