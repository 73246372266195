import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

export interface ProgressType {
  id: number;
  color: string;
  label: string;
  count: number;
}

interface PublishStatusProps {
  rawData: { [key: string]: string };
}

// Function to map backend data to ProgressType array
const getData = (data: { [key: string]: string }): ProgressType[] => {
  const keys = Object.keys(data);

  return keys.map((key, index) => {
    switch (key) {
      case "published_count":
        return { id: index, label: "Published", count: parseInt(data[key]), color: 'success' };
      case "failed_count":
        return { id: index, label: "Failed", count: parseInt( data[key]), color: 'danger' };
      case "in_progress_count":
        return { id: index, label: "In Progress", count: parseInt( data[key]), color: 'warning' };
      default:
        return { id: index, label: key, count: parseInt( data[key]), color: 'secondary' };
    }
  });
};

const PublishStatusChart: React.FC<PublishStatusProps> = ({ rawData }) => {
  const posts = getData(rawData);

  return (
    <Card>
      <CardBody>
        <CardTitle className="h4">Post Status Overview</CardTitle>
        <div>
          <ul className="list-group list-group-flush">
            {posts.map((item: ProgressType, index: number) => (
              <li className="list-group-item" key={index}>
                <div className="py-2">
                  <h5 className={`font-size-14 text-${item.color}`}>
                    {item.label}
                    <span className="float-end">{item.count}</span>
                  </h5>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default PublishStatusChart;
