import React, { useEffect, useState } from "react";
import {Col, Container, Row, Spinner,} from "reactstrap";
import Projects from "./Projects";
import PlanStats from "./PlanStats";
import PostStats from "./PostStats";
import Notification from "./Notification";
import RecentActivities from "./RecentActivities";
import TeamStats from "./TeamsStats";
import { getDashboardData } from "helpers/API Calls/General/BusinessDashboard";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import withRouter from "Components/Common/withRouter";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    user: user.user,
    permissions: user.permissions, 
  })
);

function Dashboard({ router }: any) {
  const { navigate, location } = router;
  document.title = "Dashboard | Content Pro";

  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get('businessId');

  const [businessData, setBusinessData] = useState<any>(); 
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)

      // business data 
      const data = await getDashboardData(businessId, navigate);
      if(data) setBusinessData(data);

      setIsLoading(false)
    }
    fetchData();
  }, [setShowNotification, businessId, navigate]);

  const { user, permissions } = useSelector(selectProperties);

  const canPublish=user?.user_type==='Member'?(permissions?.includes('publish_post')):true;

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center min-vh-100" >
          <Spinner color="primary" />
        </div>
      ) : 
      <>
        <Notification showNotification={showNotification} setShowNotification={setShowNotification} businessData={businessData?.['business-info']}/>

        <div id="main-content" className="page-content" style={showNotification ? {"paddingTop": "0px"} : {}}>
          {businessData ? 
          <Container fluid className="overflow-hidden">

            <Row>
              <Col className="col-12">
                <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                  <div className="text-center d-flex">
                    {businessData?.['business-info']?.image && (
                      <div className="me-3 my-auto">
                        <img
                          src={businessData?.['business-info']?.image}
                          alt="Business Logo"
                          className="business-logo"
                        />
                      </div>
                    )}
                    <div>
                      <h2 className="mb-1 business-name">
                        {businessData?.['business-info']?.business_name || "Business Name"}
                      </h2>
                      <p className="text-muted mb-0 business-key">
                        {businessData?.['business-info']?.business_key || "Business Key"}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">

              <Col lg={6}>
                <Projects projects={businessData?.projects}/>
                <TeamStats teams = {businessData?.teams?.map(item => ({name: item.name, count: item.value}))}/>
                {user?.user_type !=='Member' && <PlanStats subscribedPlan={businessData?.subscribedPlan}/>}
              </Col>

              <Col lg={6}>
                <RecentActivities activities={businessData?.activities} canPublish={canPublish}/>
                <PostStats rawData={businessData?.postStats?.[0]}/>
              </Col>

            </Row>

          </Container>
          :
          (
            <div className="d-flex justify-content-center align-items-center min-vh-100" >
              <div className="text-center">
                <h4 className="text-danger mb-3">Oops! Something went wrong.</h4>
                <p className="text-muted">
                  We couldn't load the business data. Please check your internet connection or try refreshing the page.
                </p>
              </div>
            </div>
          )
                    
          }
        </div>
      </>
      }
    </>
  );
}

export default withRouter(Dashboard);
