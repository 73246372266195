import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; 

const ScheduleCalender = () => {
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);

  // Updated data structure to support multiple events on the same date
  const importantDates: Record<string, string[]> = {
    "2024-11-25": ["Project Deadline"],
    "2024-11-30": ["Team Meeting"],
    "2024-12-01": ["Client Presentation", "Marketing Review", "Budget Discussion"],
  };

  // Function to render tiles with custom content
  const tileContent = ({ date }: { date: Date }) => {
    const formattedDate = date.toISOString().split("T")[0];
    if (importantDates[formattedDate]) {
      return (
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: "red",
            borderRadius: "50%",
            margin: "0 auto",
            position: "relative",
          }}
          onMouseEnter={() => setHoveredDate(formattedDate)}
          onMouseLeave={() => setHoveredDate(null)}
        />
      );
    }
    return null;
  };

  return (
    <Card className="custom-calendar-card">
      <CardBody>
        <div className="d-flex flex-wrap align-items-start">
          <div className="me-2">
            <h5 className="card-title mb-3">Scheduled Posts</h5>
          </div>
        </div>

        <div style={{ position: "relative" }}>
          <Calendar
            tileContent={tileContent} // Custom content for tiles
            tileClassName={({ date }) => {
              const formattedDate = date.toISOString().split("T")[0];
              return importantDates[formattedDate] ? "highlighted-date" : "";
            }}
          />
          {hoveredDate && (
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                background: "black",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                pointerEvents: "none",
                whiteSpace: "nowrap",
              }}
            >
              <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                {importantDates[hoveredDate]?.map((event, index) => (
                  <li key={index} style={{ textAlign: "left" }}>
                    {event}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ScheduleCalender;
