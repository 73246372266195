import React, { useState } from "react";
import { Col, Card, CardBody, Button, Spinner } from "reactstrap";
import CustomIcon from "../../../../../Components/Custom/CustomIcon";
import placeholderImage from "assets/images/img.png";
import Dropzone from "Components/Custom/DropZone";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import thumbnail from 'assets/images/thumbnail.png'
import EditModal from "./EditTagsModal";
import { editFileTags } from "helpers/API Calls/Project/Activity/files";

interface MediaHolderProps {
  media: any;
  onMediaUpload: (file: File | null, type: "image" | "video") => void;
  onDeleteMedia: () => void;
  onSave: (tags: any[], id: number) => void;
  tagOptions: [],  
  loading: boolean;
  canUploadFiles: boolean;
}

function MediaHolder({ media, onMediaUpload, onDeleteMedia,onSave, tagOptions, loading, canUploadFiles,}: MediaHolderProps) {
  const tag = media?.id; 
  const mediaType = media.type; 
  const selectedMedia = media.file; 

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const toggleEditModal = () => setEditModalOpen(prev => !prev);  

  const handleSaveEdit = async (tags: any[]) => {
    const data = {integration_types: tags.map(item => ({integration_type_id: item.value}))}; 
    const response = await editFileTags(data, tag)
    if (response.success){
      onSave(tags, tag)
    }
    toggleEditModal();
  };

  return (
    <>
    <EditModal
      isOpen={editModalOpen}
      toggle={toggleEditModal}
      selectedMedia={selectedMedia}
      mediaTags={media.integration_types}
      tagOptions= {tagOptions}
      mediaType={mediaType}
      onSave={handleSaveEdit}
    />
    <DeleteModal
      show={deleteModal}
      onDeleteClick={() => {setDeleteModal(false); onDeleteMedia();}}
      onCloseClick={() => {setDeleteModal(false);}}
      message='media'
    />
    <Col>
      <Card className="p-0 mb-0" style={{ width: "auto" }}>
        <CardBody className="p-0">
          <div className="text-center position-relative">
            {/* Conditionally apply dropzone only for empty media slots */}
            {selectedMedia ? (
              <div className="avatar-xl rounded">
                <div className="avatar-title bg-light">
                  {mediaType === "image" ? (
                    <img
                      src={selectedMedia}
                      id={`${tag}-img`}
                      alt=""
                      className="avatar-xl rounded h-100"
                    />
                  ) : (
                    <video
                      id={`${tag}-video`}
                      className="avatar-xl rounded h-100"
                      controls
                      preload="none" 
                      poster={thumbnail}
                    >
                      <source src={selectedMedia} type="video/mp4"></source>
                    </video>
                  )}
                </div>
              </div>
            ) : (
            <>
              <Dropzone
                accept = {{"image/*": [".png", ".jpeg", ".jpg", ".gif"],"video/*": [".mp4", ".mov", ".avi"],}}
                onDrop = {(acceptedFiles) => {
                  if (acceptedFiles.length === 0) return;
                  const file = acceptedFiles[0];
                  const fileType = file.type.startsWith("image/") ? "image" : "video";
                  onMediaUpload(file, fileType); // Send the File object and file type to the parent
                }}
                disabled={!!selectedMedia}
                renderContent = {() => (
                  <div className="avatar-xl rounded">
                  <div className="avatar-title bg-light">
                    <img
                      src={placeholderImage}
                      id="media-placeholder"
                      alt="Placeholder"
                      className="avatar-xl rounded h-100"
                    />
                  </div>
                </div>
                )}
              />
            </>
            )}

            {/* Buttons for viewing and deleting media */}
            {selectedMedia && (
              <div className="position-absolute top-0 end-0">
                <Button type="button" className="btn btn-primary btn-sm p-1 me-1"
                  onClick={() => window.open(selectedMedia, "_blank")}
                >
                  <CustomIcon className="mdi" iconName="mdi-eye-outline" id={`view-${tag}`} text="View"/>
                </Button>

                <Button type="button" className="btn btn-info btn-sm p-1 me-1"
                  onClick={toggleEditModal}
                >
                  <CustomIcon className="mdi" iconName="mdi-pencil" id={`edit-${tag}`} text="Edit"/>
                </Button>

                <Button type="button" className="btn btn-danger btn-sm p-1"
                  onClick={() => setDeleteModal(true)} disabled={!canUploadFiles || loading}
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : (
                    <CustomIcon className="mdi" iconName="mdi-trash-can" id={`deleteButton_${tag}`} text="Delete"/>
                  )}
                </Button>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
    </>
  );
}

export default MediaHolder;
