import React from "react";
import "./App.css";
import "./assets/scss/theme.scss";
import { authProtectedRoutes, publicRoutes } from "./Routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import NonAuthLayout from "./Layouts/NonLayout";
import AuthProtected from "./Routes/AuthProtected";
import Header from "Layouts/Header/Header";
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider} from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";


function App() {    
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route  path={route.path}  key={idx} element={<NonAuthLayout>{route.component}</NonAuthLayout>}/>
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route path={route.path} key={idx} element={
                <AuthProtected variant = {route.sideBar} routePermissions = {route.routePermissions} path ={route.path}>
                  {route.path !== '/admin/businesses/create' ?
                  <>
                    <Header variant = {route.sideBar}/>
                    {!route.hasNoSideBar
                      ? <VerticalLayout variant = {route.sideBar}>
                         <DndProvider backend={HTML5Backend}>
                          {route.component}
                          </DndProvider>
                        </VerticalLayout> 
                      : route.component
                    }
                  </>
                  : route.component
                }
                </AuthProtected>
            }
          >
          </Route>
        ))}
      </Routes>
      <ToastContainer position="bottom-right"
        hideProgressBar={true}
      />
    </React.Fragment>
  );
}

export default App;

