// Predefined color palette
const colorPalette = [
  "primary", // Bootstrap color
  "success",
  "danger",
  "info",
  "warning",
  "secondary",
];

const getDynamicColor = (index: number): string => {
  return colorPalette[index % colorPalette.length]; // Cycle through the palette
};

export default getDynamicColor;
