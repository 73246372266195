import React from 'react'

function SocialIcon({ title, type="", slug}) {
  
  let img = null;
  
  if (slug) {
    try {
      img = require(`assets/images/social icons/${slug}.png`);
    } catch (error) {
      console.error(`Image for slug "${slug}" not found.`);
    }
  }

  return (
    <div className="social-source d-flex">
        <div className='my-auto'>
        {img ? 
          <img src={img} alt="" className="avatar-xs rounded-circle"/>
          :
          <div className="avatar-xs">
            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
              {title?.charAt(0)}
            </div>
          </div>
        }
        </div>
        <div className='ps-2 my-auto'>
          <h5 className="font-size-15 mb-0">{title}</h5>
          <p className='me-auto card-title-desc mb-0'>{type}</p>
        </div>
    </div>
  )
}

export default SocialIcon