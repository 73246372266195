import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import thumbnail from 'assets/images/thumbnail.png'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

interface EditModalProps {
    isOpen: boolean;
    toggle: () => void;
    selectedMedia: string | null;
    mediaTags: []; 
    mediaType: "image" | "video";
    tagOptions: [], 
    onSave: (tags: any[]) => void;
  }

function EditModal({ isOpen, toggle, selectedMedia,mediaTags, mediaType, onSave, tagOptions }: EditModalProps) {

  const [selectedTags, setSelectedTags] = useState<{ label: string; value: string }[]>([]);
 
  useEffect(() => {
    if (isOpen) {
      setSelectedTags(
        mediaTags?.map((item: any) => ({
          label: item?.type_name,
          value: item?.id,
        }))
      );
    }
  }, [isOpen, mediaTags]);

  const handleSave = () => {
    onSave(selectedTags);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <h5 className="text-center mb-3 text-primary fw-light">Edit Media Tags</h5>
        <div className="mx-auto mb-3 d-flex justify-content-center">
            {mediaType === "image" ? (
            <img src={selectedMedia || ""} alt="Selected Media" className="avatar-xl rounded h-100" />
            ) : (
            <video src={selectedMedia || ""} controls className="avatar-xl rounded h-100" preload="none" poster={thumbnail}>
                <source src={selectedMedia || ""} type="video/mp4"></source>
            </video>
            )}
        </div>
        <Select
          isMulti
          components={animatedComponents}
          options={tagOptions}
          value={selectedTags}
          onChange={(selected) => setSelectedTags(selected as { label: string; value: string }[])}
          placeholder="Select tags..."
          styles={{
            menu: base => ({
              ...base,
              zIndex: 9999,
              backgroundColor: 'white',
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
              color: state.isSelected ? 'white' : 'black',
            }),
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="success" onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditModal