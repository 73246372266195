import React, { useState } from "react";
import { CardBody, Table, Badge } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";

const getBadgeColor = (percentage) => {
  if (percentage >= 90) return "danger"; // Red for critical usage
  if (percentage >= 60) return "warning"; // Yellow for moderate usage
  return "success"; // Green for low usage
};

function PlanStats({subscribedPlan}) {
  const [collapseStates, setCollapseStates] = useState(true);
  return (
      <CollapseItem
        title={`Plan Utilization: ${subscribedPlan?.name}`}
        col={collapseStates}
        toggle={() => setCollapseStates((prevState) => !prevState)}
      >
      
        <CardBody>
          <Table className="table align-middle mb-0">
            <thead>
              <tr className="font-medium">
                <th className="ps-0">Feature</th>
                <th>Usage</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {subscribedPlan?.features?.map((feature, index) => {
                const usedPercentage = parseFloat(((feature.used / feature.limit) * 100).toFixed(1));

                return (
                  <tr key={index}>
                    <td className="ps-0">
                      <strong>{feature.name}</strong>
                    </td>
                    <td>
                      {feature.usage}
                    </td>
                    <td>
                      <Badge color={getBadgeColor(usedPercentage)}>
                        {usedPercentage >= 90
                          ? "Critical"
                          : usedPercentage >= 60
                          ? "Moderate"
                          : "Good"}
                      </Badge>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
        
      </CollapseItem>
  );
}

export default PlanStats;
