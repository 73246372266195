import React, { useState } from "react";
import { Button, ModalBody, ModalHeader, Table, Modal } from "reactstrap";
import convertDateFormat from "Components/Custom/DateFormat";
import { countWords } from "../Details/ActivityDetail";
import DOMPurify from 'dompurify';
import { editActivity } from "helpers/API Calls/Project/Activity/Activity";

const VersioningFeed = ({logs, title, id, setIsUpdated}) => {

  const [selectedVersion, setSelectedVersion] = useState<any>({}); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState<boolean>(false); 
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // New state to track submitting

  const closeModal = () => {setIsModalOpen(false);};
  const closeBtn = (<Button className="btn btn-soft-danger waves-effect waves-light" onClick={closeModal} type="button"><i className="mdi mdi-close-thick"></i></Button>);

  const restoreVersion = async () => {
    setIsSubmitting(true)
    const data = {
      edit_type: 'content',
      title: title,
      content: selectedVersion.content,
    };
    const response = await editActivity(data, id);
    if (response?.success) {
      setIsUpdated(true); 
    }   
    setIsSubmitting(false);
    setModal(false); 
  }

  return(
  <React.Fragment>
    <Modal isOpen={isModalOpen} toggle={closeModal} centered={true} size="lg" scrollable={true}>
        <div className="modal-content">
          <ModalHeader toggle={closeModal} tag="h4" close={closeBtn}>Content Updated By: {selectedVersion?.user?.name}</ModalHeader>
          <ModalBody className="pb-4">
            <h5 className="font-size-21 pb-1">Description:</h5>
            {selectedVersion?.content ? (
              <div className="preview" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedVersion?.content) }} />
            ) : (
              <p className="text-muted">No content available.</p>
            )}
          </ModalBody>
      </div>
    </Modal>

    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 mx-auto">
              <div className="text-primary font-size-20">
                Restore Version
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">Are you sure you want to restore the version.</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
              <button type="button" className="btn btn-danger" disabled={isSubmitting} onClick={() => restoreVersion()}>{isSubmitting ? "Restoring..." : "Restore"}</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    <div className="table-responsive mt-4">
      <Table hover className="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline">
        <thead className="table-light">
          <tr>
            <th>#</th>
            <th>Updated By</th>
            <th>Version</th>
            <th>Words Count</th>
            <th>Log Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((item, index) => 
            <tr key={item?.id} className="ps-0" >
              <th scope="row">{item?.id}</th>
              <td><h5 className='font-size-14 mb-1'>{item?.user?.name}</h5><p className="text-muted mb-0">{item?.user?.email}</p></td>
              <td>Version-{index+1}</td>
              <td>{countWords(item?.content)}</td>
              <td>{convertDateFormat(item?.created_at)}</td>
              <td>
                <button className="btn btn-sm btn-soft-info" onClick={() => {setSelectedVersion(item); setIsModalOpen(true); }}><i className="mdi mdi-eye-outline" id="view-tooltip"></i></button>
                <button className="ms-1 btn btn-sm btn-soft-warning" onClick={() => {setSelectedVersion(item); setModal(true); }}><i className="mdi mdi-refresh" id="restore-version"></i></button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>

  </React.Fragment>
  )
};

export default VersioningFeed;
