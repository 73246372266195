import React from "react";
import { Card, Progress, CardBody, CardTitle } from "reactstrap";
import getDynamicColor from "./getDynamicColor";

interface TeamStatsProps {
  teams: { name: string; count: number }[];
}

const TeamStats: React.FC<TeamStatsProps> = ({ teams }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="h5 ">Team Activity Distribution</CardTitle>
        {teams.length !== 0 ? 
          <>
            <p className="card-title-desc">
              This chart represents the distribution of activity among various
              teams.
            </p>
            <div>
              <ul className="list-group list-group-flush">
                {teams.map((team, index) => {
                  const color = getDynamicColor(index);
                  return (
                    <li className="list-group-item" key={index}>
                      <div className="py-2">
                        <h5 className="font-size-14">
                          {team.name}
                          <span className="float-end">{Number(team.count).toFixed(2)}%</span>
                        </h5>
                        <div className="progress animated-progress progress-sm">
                          <Progress
                            bar
                            color={color}
                            value={team.count}
                            style={{ height: "10px" }}
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
          :
          <p className="text-center text-danger my-4">No teams are currently assigned to this project.</p>
        }
      </CardBody>
    </Card>
  );
};

export default TeamStats;
