import { LinkWithQuery } from "helpers/link_helper";
import React, { useEffect, useState } from "react";
import { CardBody, CardText, CardTitle, Input, Spinner } from "reactstrap";
import { getProjects } from "helpers/API Calls/Space Settings/Projects/projects";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import withRouter from "Components/Common/withRouter";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({user: user.user})
);

function ProjectMenu({ setDropdownOpen, router }) {

  const { navigate, location } = router;
  
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");


  const { user } = useSelector(selectProperties);
  
  const [projects, setProjects] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [filteredProjects, setFilteredProjects] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");


  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const projectData = await getProjects(businessId, navigate);
      if (projectData && projectData.length > 0) {
        setProjects(projectData);
        setFilteredProjects(projectData);
      } else {
        setProjects([]);
        setFilteredProjects([]);
      }
      setIsLoading(false);
    };
    getData();
  }, [businessId, navigate]);

  useEffect(() => {
    if (projects) {
      const filtered = projects.filter(
        (project: any) =>
          project.project_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          project.project_key.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProjects(filtered);
    }
  }, [searchTerm, projects]);

  return (
    <div>
      {!isLoading && projects && projects.length > 0  &&<div className="app-search p-0 m-3 notification-item ">
        <div className="form-group m-0 ">
          <div className="input-group">
            <Input
              type="text"
              placeholder="Search projects by name or key..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="form-control"
            />
          </div>
        </div>
      </div>}
      {isLoading ? (
        <div className="spiner_div my-5">
          <Spinner color="primary" />
        </div>
      ) : filteredProjects && filteredProjects.length > 0 ? (
        <>
          {filteredProjects.slice(0, 2).map((item) => (
            <ProjectItem
              key={item.id}
              id={item.id}
              name={item.project_name}
              description={`${item.project_type}`}
              img={item.thumbnail}
              onClick={() => setDropdownOpen(false)}
            />
          ))}
        </>
      ) : (
        <>
          <CardBody className="m-3">
            <CardTitle tag="h4">Sorry! No project found.</CardTitle>
            {user.user_type !== 'Member' && <CardText className="text-muted d-flex justify-content-between">
              <span className="my-auto">Start a new Project.</span>
              <button className="btn btn-soft-success " onClick={() => {navigate(`/projects/create?businessId=${businessId}`)}}>Create New Project</button>
            </CardText>}
          </CardBody>
          </>
      )}
    </div>
  );
}

export default withRouter(ProjectMenu);

const ProjectItem = (props: any) => {
  return (
    <LinkWithQuery
      to="/project"
      queries={`projectId=${props.id}`}
      className="text-reset notification-item"
      onClick={props.onClick}
    >
      <div className="d-flex">
        <div className="flex-shrink-0 my-auto me-3">
          {props.img ? (
            <img src={props.img} alt="" className="rounded-circle avatar-xs" />
          ) : (
            <div className="avatar-xs">
              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                {props.name?.charAt(0)}
              </div>
            </div>
          )}
        </div>
        {/* <img
          src={props.img}
          className="me-3 rounded-circle avatar-xs"
          alt="user-pic"
        /> */}
        <div className="flex-grow-1">
          <h6 className="mt-0 mb-1">{props.name}</h6>
          <div className="font-size-12 text-muted">
            <p className="mb-1">{props.description}</p>
          </div>
        </div>
      </div>
    </LinkWithQuery>
  );
};
