import { toast } from "react-toastify";
import request from "../../../request_helper";
import toasterTime from "config";

export const getStatuses = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "publish_statuses?type=wordpress",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const getAuthors = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "wordpress/authors?project_integration_id=" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const getCategories = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "wordpress/categories?project_integration_id=" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}