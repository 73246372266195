import React, { useEffect, useRef, useCallback, useState } from "react";
import { LinkWithQuery } from "helpers/link_helper";
//Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
// data
import PROJECT, { SPACE_SETTINGS, SUPER_ADMIN, PROFILE } from "./data";

import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SidebarContent = (props: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const shouldPreserveId = businessId !== null;
  const [isOpen, setIsopen] = useState(false);
  // useEffect(()=>{
  //   setIsopen(!isOpen)
  // },[isOpen]);
  const ref = useRef<any>();

  const [open, setOpen] = useState<boolean>(false);

  const activateParentDropdown = useCallback((item: any) => {
    // console.log('from activateParentDropdown: item',item);
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    // console.log('location from window', window.location.href);
    const pathName = window.location.href;
    // console.log('pathname', pathName);
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);
    // console.log(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].href) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({ user: user.user })
  );
  const { user } = useSelector(selectProperties);

  let content;

  switch (props.variant) {
    case "space-settings":
      content = SPACE_SETTINGS;
      break;
    case "admin":
      content = SUPER_ADMIN;
      break;
    case "project":
      content = user.sidebar || PROJECT;
      break;
    case "profile":
      content = PROFILE;
      break;
    default:
      content = PROJECT;
  }
 
  function openChildItems() {
    var body = document.body;
    body.classList.remove("vertical-collpsed");
      body.classList.remove("sidebar-enable");
   
 
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {/* <button
              type="button"
              onClick={() => setOpen(!open)}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button> */}

            {content.map((item) => (
              <li key={item.key}>
                {item.children && item.children.length > 0 ? (
                  <>
                    <a
                      className="has-arrow"
                      // onClick={() => openChildItems(item)}
                    >
                      <i className={`bx ${item.icon}`}></i>
                      <span>{props.t(`${item.name}`)}</span>
                    </a>

                    <ul className="sub-menu">
                      {item.children.map((subItem) => (
                        <li key={subItem.key}  onClick={() => openChildItems()}>
                          <LinkWithQuery
                            admin={
                              props.variant === "admin" ||
                              (props.variant === "profile" && !shouldPreserveId)
                            }
                            project={props.variant === "project"}
                            to={subItem.link}
                          >
                            {props.t(`${subItem.name}`)}
                          </LinkWithQuery>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <LinkWithQuery
                    admin={
                      props.variant === "admin" ||
                      (props.variant === "profile" && !shouldPreserveId)
                    }
                    project={props.variant === "project"}
                    to={item.link}
                    className={
                      item.children && item.children.length > 0
                        ? "has-arrow"
                        : undefined
                    }
                  >
                    <div  onClick={() => openChildItems()}>
                    <i className={`bx ${item.icon}`}></i>
                    <span>{props.t(`${item.name}`)}</span>
                    </div>
               
                  </LinkWithQuery>
                )}
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
