import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import CustomIcon from "Components/Custom/CustomIcon";

const ItemType = "State";

interface StateProps {
  id: number;
  text: string;
  index: number;
  length: number;
  color: string;
  canEdit: boolean;
  canDelete: boolean;
  moveState: (dragIndex: number, hoverIndex: number) => void;
  onEdit: (item: any) => void;
  onDelete: (item:any) => void;
}

const State: React.FC<StateProps> = ({ id, text, color, index,length, canEdit, canDelete, moveState, onEdit, onDelete }) => {
//   const [{ isDragging }, drag] = useDrag({
  const [, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item: { index: number }) => {
      if (item.index === index) return; // Prevent unnecessary reordering

      moveState(item.index, index);
      item.index = index; // Update the dragged item's index
    },
  });

  return (
  <div ref={(node) => drag(drop(node))}>
    <div className="bg-light p-3 d-flex justify-content-between align-items-center rounded">
      {/* Left Section: Color Box and Text */}
      <div className="d-flex align-items-center flex-grow-1 w-75">
        <div
          className="rounded me-3"
          style={{ backgroundColor: `${color}`, height: "2rem", width: "4rem" }}
        ></div>
        <div className="my-auto">
          <h5 className="font-size-15 mb-0">{text}</h5>
        </div>
      </div>

      {/* Right Section: Dropdown and Sequence */}
      <div className="d-flex align-items-center w-25">
        <div className="me-3">
          {(canEdit || canDelete) && (
            <UncontrolledDropdown>
              <DropdownToggle className="btn btn-soft-primary" type="button">
                <CustomIcon
                  className="bx"
                  iconName="bx-dots-vertical-rounded"
                  id={`action-${id}`}
                  text="actions"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {canEdit && (
                  <li onClick={() => onEdit({ id: id, state_name: text, color: color })}>
                    <DropdownItem>Edit</DropdownItem>
                  </li>
                )}
                {canDelete && (
                  <li onClick={() => onDelete({ id: id, state_name: text, color: color })}>
                    <DropdownItem>Delete</DropdownItem>
                  </li>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>

        {/* Sequence Number */}
        <div className="text-center" style={{ width: "64px" }}>
          <h6 className="mb-0">
            {index === 0 ? "Start" : index === length - 1 ? "End" : index + 1}
          </h6>
        </div>
      </div>
    </div>
  </div>

  );
};

export default State;
