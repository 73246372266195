import React, { useEffect, useState } from "react";
import {Row, Col,CardBody,Card, Alert, Container, Input, Label, Form, FormFeedback, NavItem, NavLink, TabContent, TabPane, Spinner} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { getTimezones } from "helpers/API Calls/General/timezone"
import withRouter from "Components/Common/withRouter";
import Select from "react-select"
import placeholderImage from "assets/images/img.png"
import { registerUser, apiError } from "slices/thunk";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { getRegistrationPerm } from "helpers/API Calls/General/public-registration";
import { resetRegistrationState } from "slices/auth/register/reducer";
import Dropzone from "Components/Custom/DropZone";
import Logo from "./UI/Logo";

const Register = (props: any) => {

  document.title = "Register | Content Pro";

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  // for multi step form 
  const [activeTab, setActiveTab] = useState<number>(1)
  const [passedSteps, setPassedSteps] = useState([1])
  function toggleTab(tab: any) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // prompting unsaved changes message / warning 
  const [formState, setFormState] = useState<"unchanged" | "modified" | "saving">("unchanged");

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => { };
  }, [formState]);

  // form data 
  const [formData, setFormData] = useState({});
  //  img upload
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const validation = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      confirmPassword: "",

    },
    validationSchema: Yup.object({
      email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
      username: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'User Name can only contain letters and spaces')
      .min(3, 'Username must be at least 3 characters')
      .max(20, 'Username cannot exceed 20 characters')
      .required("Please Enter Your Username"),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(RegExp('(.*[a-z].*)'), 'Password must have at least one lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'Password must have at least one uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'Password must have at least one number')
        .required("Please Enter Your Password"),
      confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required("Please Confirm Your Password"),
    }),
    onSubmit: (values) => {
      const data = {
        owner_name: values.username,
        owner_email: values.email,
        password: values.password,
        password_confirmation: values.confirmPassword,
      }
      setFormData(prev => { return { ...prev, ...data } });
      toggleTab(activeTab + 1)
    },
    validate: (values) => {
      setFormState("modified");
    }
  });

  const validation2 = useFormik({
    initialValues: {
      orgName: '',
      timezone: null,
    },

    validationSchema: Yup.object({
      orgName: Yup.string()
      .matches(/^[A-Za-z0-9\s]+$/, 'Organization Name can only contain letters, numbers, and spaces')
      .min(3, 'Organization Name must be at least 3 characters')
      .max(50, 'Organization Name cannot exceed 50 characters')
      .required("Please Enter Your Organization Name"),
      timezone: Yup.object().shape({
        label: Yup.string().required("Please Select a Timezone"),
        value: Yup.number().required("Please Select a Timezone"),
      }).nullable().required("Please Select a Timezone"),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      setFormState("saving");
      const timezoneId = (values.timezone as { value: number } | null)?.value ?? null;
      const data = {
        business_name: values.orgName,
        timezone_id: timezoneId,
      }
      setFormData(prev => { return { ...prev, ...data } });
      try {
        await dispatch(registerUser({ data: { ...formData, ...data }, image: selectedImage || null }, props.router.navigate));
      } catch (error) {
        console.error("Error during registration:", error);
      } finally {
        setSubmitting(false);
        setFormState("unchanged");
      }
    },
    validate: (values) => {
      setFormState("modified");
    }
  });

  // redux
  // Individual selectors
  const selectUserSlice = (state) => state.user;
  const selectAccountSlice = (state) => state.Account;

  // Combined selector
  const selectCombinedProperties = createSelector(
    [selectUserSlice, selectAccountSlice],
    (user, account) => ({
      currentUser: user.user,
      user: account.user,
      registrationError: account.registrationError,
      loading: account.loading,
      message: account.message,
    })
  );

  const { registrationError, message, currentUser } = useSelector(selectCombinedProperties);
  console.log('message from reg component', message)

  useEffect(() => {
    if (registrationError) { setTimeout(() => { dispatch(apiError()) }, 5000); }
  }, [dispatch, registrationError])

  useEffect(() => {
    dispatch(resetRegistrationState()); 
  }, [dispatch])

  const [timezones, setTimezones] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const timezone = await getTimezones();
      const permission = await getRegistrationPerm();
      const user = currentUser;

      if (user && user?.user_type === "Admin") {
        console.log('Admin access allowed');
      } else if (Number(permission.value) === 0) {
        console.log('access denied');
        navigate('/access-denied');
      } else {
        console.log('access allowed');
      }

      setTimezones(timezone?.map(item => ({ label: `${item.name}, ${item.timezone}`, value: item.id })));
      setIsLoading(false);
    }
    getData();
  }, [navigate, currentUser]);

  return (
    <React.Fragment>
      <Logo/>
      <div className="reg-bg py-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center mt-md-5">
            <Col md={9} lg={7} xl={6}>
              {isLoading ? (
                <Spinner
                  color="primary"
                  className="position-absolute top-50 start-50"
                />
              ) : (
                <Card>
                  <div className="py-3">
                    <h2 className="p-4 text-dark"><strong>Let's get your account set up</strong></h2>
                  </div>

                  <CardBody className="pt-0">
                    {/* {user && user ? (<Alert color="success">Successfully Registered.</Alert>) : null} */}
                    {registrationError && registrationError ? (<Alert color="danger">{registrationError}</Alert>) : null}
                    {message && message ? (<Alert color="success">{message}</Alert>) : null}

                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem className={classnames({ current: activeTab === 1 })}>
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => { setActiveTab(1) }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span>User Details
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 2 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => { setActiveTab(2) }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span>Organization Details
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>

                      <div className="content clearfix">
                      <Form className="form-horizontal pt-3"
                        onSubmit={(e) => {
                          e.preventDefault();
                          activeTab === 1 ? validation.handleSubmit() : validation2.handleSubmit();
                          return false;
                        }}
                      >
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>

                          <div className="mb-3">
                              <Label className="form-label">Username <span className="text-danger">*</span></Label>
                              <Input
                                name="username"
                                className="form-control"
                                placeholder="Enter Username"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username && validation.errors.username ? true : false
                                }
                              />
                              {validation.touched.username && validation.errors.username ? (
                                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                              ) : null}
                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Email Address <span className="text-danger">*</span></Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter Email Address"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Password <span className="text-danger">*</span></Label>
                              <Input
                                name="password"
                                type="password"
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Confirm Password <span className="text-danger">*</span></Label>
                              <Input
                                name="confirmPassword"
                                type="password"
                                placeholder="Confirm Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                                invalid={
                                  validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                }
                              />
                              {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-4">
                              <button
                                type="button"
                                className="btn btn-success w-100"
                                onClick={() => validation.handleSubmit()}
                              >Next</button>
                            </div>
                          </TabPane>

                          <TabPane tabId={2}>
                            <div>
                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label className="form-label">Organization Name <span className="text-danger">*</span></Label>
                                    <Input
                                      name="orgName"
                                      type="text"
                                      placeholder="Enter Organization Name"
                                      onChange={validation2.handleChange}
                                      onBlur={validation2.handleBlur}
                                      value={validation2.values.orgName || ""}
                                      invalid={
                                        validation2.touched.orgName && validation2.errors.orgName ? true : false
                                      }
                                    />
                                    {validation2.touched.orgName && validation2.errors.orgName ? (
                                      <FormFeedback type="invalid">{validation2.errors.orgName}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <div className="mb-3">
                                    <Label className="form-label">Timezone <span className="text-danger">*</span></Label>
                                    <Select
                                      name="timezone"
                                      placeholder="Select Timezone"
                                      options={timezones}
                                      value={validation2.values.timezone}
                                      onChange={(value) => validation2.setFieldValue("timezone", value)}
                                      onBlur={validation2.handleBlur}
                                      className={
                                        validation2.touched.timezone && validation2.errors.timezone
                                          ? "is-invalid"
                                          : ""
                                      }
                                    />
                                    {validation2.touched.timezone && validation2.errors.timezone ? (
                                      <FormFeedback type="invalid">{validation2.errors.timezone || validation2.errors.timezone}</FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                              <Col className="mx-auto">
                                <div className="mb-3">
                                  <Label className="form-label mb-3">Logo <span className="text-danger">*</span></Label>
                                  <Dropzone
                                    accept = {{"image/*": [".png", ".jpeg", ".jpg"]}}
                                    onDrop = {(acceptedFiles) => {setSelectedImage(acceptedFiles[0]);}}
                                    renderContent = {() => (
                                      <div className="text-center cursor-pointer">
                                        <div className="position-relative d-inline-block" >
                                          <div className="avatar-xl rounded">
                                          <div className="avatar-title bg-light ">
                                            <img
                                              src={selectedImage ? URL.createObjectURL(selectedImage) : placeholderImage}
                                              id="logo-img"
                                              alt=""
                                              className="avatar-xl rounded h-auto"
                                            />
                                          </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  />
                                </div>
                              </Col>
                              </Row>
                            </div>

                            <div className="actions clearfix mt-4 text-end d-grid gap-2">
                              <button type="submit" className="btn btn-success w-100" disabled={validation2.isSubmitting}>
                                {validation2.isSubmitting ? 'Registering' : "Register"}
                              </button>
                              <button className="btn btn-outline-secondary btn-block" disabled={validation2.isSubmitting} type="button" onClick={() => { toggleTab(activeTab - 1)}}>Previous</button>
                            </div>

                          </TabPane>
                        </TabContent>
                      </Form>
                      </div>
                      {!currentUser && <div className="mt-4 text-center">
                        <p className="mb-0 text-muted">
                          By proceeding, you agree to Content Pro<Link to="#" className="text-success"> Terms of Use</Link> 
                          </p>
                        <hr/>
                        <div className="text-center">
                          <p>
                            <strong>
                            Already have an account? <Link to="/login" className="font-weight-medium text-success">Login</Link>
                            </strong>
                          </p>
                        </div>
                      </div>}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);
