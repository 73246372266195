import React, { useEffect, useState } from "react";
import { Row, Col, CardTitle, CardText, CardBody, Input, Button } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";
import { LinkWithQuery } from "../../helpers/link_helper";
import { useLocation, useNavigate } from "react-router-dom";
import convertDateFormat from "Components/Custom/DateFormat";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({user: user.user})
);


function Projects({projects}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const { user } = useSelector(selectProperties);

  const [collapseStates, setCollapseStates] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showAll, setShowAll] = useState(false);
  
  const navigate = useNavigate();
  
  useEffect(() => {
    if (projects && projects.length > 0) {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects([]);
    }
  }, [projects]);

  useEffect(() => {
    if (projects) {
      const filtered = projects.filter(
        (project: any) =>
          project.project_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          project.project_key.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProjects(filtered);
    }
  }, [searchTerm, projects]);


  return (
    <CollapseItem title="Projects" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
      <CardBody>
        {projects && projects?.length > 0  && <Row>
          <Col sm="12" md="8">
            <div className="app-search p-0">
              <div className="form-group m-0">
                <div className="input-group">
                  <Input
                    type="text"
                    placeholder="Search projects by name or key..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control bg-success-subtle"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>}
        {filteredProjects && filteredProjects?.length > 0 ? (
          <>
          {filteredProjects?.slice(0, showAll ? filteredProjects.length : 2)?.map((item) => (
            <LinkWithQuery to="/project" key={item.id} queries={`projectId=${item.id}`}>
              <div className="d-flex border-bottom">
                <div className="flex-shrink-0 my-auto">
                  {item?.thumbnail ? 
                    <img src={item?.thumbnail} alt="" className="avatar-xs rounded-circle"/>
                    :
                    <div className="avatar-xs">
                      <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                        {item.project_name?.charAt(0)}
                      </div>
                    </div>
                  }
                  </div>
                <div className="flex-grow-1">
                  <CardBody>
                    <CardTitle tag="h4">{item.project_name}, ({item.project_key})</CardTitle>
                    <CardText className="text-muted">{item.project_type} - Created on {convertDateFormat(item.created_at)}</CardText>
                  </CardBody>
                </div>
              </div>
            </LinkWithQuery>
          ))}
          {filteredProjects.length > 2 && <div className="text-center">
            <Button color="btn text-success" onClick={() => setShowAll((prevState) => !prevState)}>
              {showAll ? "Show Less" : "Show All"}
            </Button>
          </div>}
          </>
        ) : (
          <>
          <CardBody>
            <CardTitle tag="h4">No project found.</CardTitle>
            {user.user_type !== 'Member' && 
              <CardText className="text-muted d-flex justify-content-between col-xl-6 col-md-8">
                <span className="my-auto">Start a new Project.</span>
                <button className="btn btn-soft-success " onClick={() => {navigate(`/projects/create?businessId=${businessId}`)}}>Create New Project</button>
              </CardText>
            }
          </CardBody>
          </>
        )}
      </CardBody>
    </CollapseItem>
  );
}

export default Projects;
