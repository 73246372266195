export default function getFormData(data): FormData {
  let formData = new FormData();

// Remove the image URL from the data object
const { image: imageUrl, ...dataWithoutImage } = data.data; 

// Append the cleaned business data (without image URL)
formData.append('data', JSON.stringify(dataWithoutImage));

  // Append image if it exists
  if (data.image) {
  // if (data.image && data.image !== null) {
    formData.append('image', data.image);
  }

  // Append thumbnail if it exists
  if (data.thumbnail) {
    formData.append('thumbnail', data.thumbnail);
  }

  if (data.profile_image) {
    formData.append('profile_image', data.profile_image);
  }

  // console.log("Form data entries:", Array.from(formData.entries())); // To visualize the entries
  return formData;
}
