const convertToTime = (dateString) => {
    // console.log(dateString)
    if (!dateString) {
      return;
    }
    
    const date = new Date(dateString);
    
    // Extract the time in 12-hour format with AM/PM
    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });

    // console.log(time.toLowerCase())
  
    return time.toLowerCase(); // Convert AM/PM to lowercase (e.g., 4:05pm)
  };
  
  export default convertToTime;
  