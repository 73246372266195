import { LinkWithQuery } from "helpers/link_helper";
import React from "react";
// import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props: any) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between pl-30">
          {props.isGoBackButton && 
            <LinkWithQuery project = {props.project || false} to={props.to} admin={props.admin} queries={props.queries}
              className="btn btn-success waves-effect waves-light my-1"
            >
              Back
            </LinkWithQuery>
          }
          {/* <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4> */}
          {/* <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <div>{props.title}</div>
              </BreadcrumbItem>
              <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div> */}
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
