import { toast } from "react-toastify";
import request from "../../../request_helper";
import toasterTime from "config";

export const getActivityStates = async (id, navigate: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_states?limit=10000&project_id=" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args, navigate); 
        const data = await response;
        return data?.data.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const createActivityState = async (reqData) => {
    // console.log(reqData)
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_states",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: toasterTime});
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const editActivityState = async (reqData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_states/" + id,
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: toasterTime});
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const editActivityStateSequence = async (reqData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_states/sequence/" + id,
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData),
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: toasterTime});
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const deleteActivityState = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "activity_states/" + id,
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        };
        const response = await request(args); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: toasterTime});
        return data.success; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}