import { toast } from "react-toastify";
import request from "../../request_helper";
import toasterTime from "config";


export const getTimezones = async () => {
    try {
        const args = {
            url: "timezones",
        };
        const response = await request(args); 
        // toast.success("Timezones retrieved successfully", { autoClose: toasterTime});
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}