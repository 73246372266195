
import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../helpers/request_helper";
import { toast } from "react-toastify";
import toasterTime from "config";

export const getBusinesses = createAsyncThunk("businesses/getAll", async (query: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `businesses?limit=${query.limit}&page=${query.page}&keyword=${query.keyword}&sort_column=${query.sort_column}&sort=${query.sort}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        throw error; // Throw the error to be caught by the rejected case in the slice
    }
});

// export const createBusiness = createAsyncThunk("businesses/createBusiness", async (businessData: any) => {
//     try {
//         const token = localStorage.getItem('token');
//         const args = {
//             url: "businesses",
//             method: "POST",
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + token,
//             },
//             body : businessData,
//         };
//         const response = await request(args); 
//         toast.success("Businesses created Successfully", { autoClose: toasterTime});
//         const data = await response;
//         return data.data
//     } catch (error: any) {
//         toast.error(error.message, { autoClose: toasterTime});
//         throw error; // Throw the error to be caught by the rejected case in the slice
//     }
// }); 

// export const editBusiness = createAsyncThunk("businesses/editBusiness", async (businessData: any) => {
//     try {
//         const token = localStorage.getItem('token');
//         const args = {
//             url: "businesses/" + businessData.id,
//             method: "PUT",
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + token,
//             },
//             body : businessData.data,
//         };
//         const response = await request(args); 
//         toast.success("Businesses updated Successfully", { autoClose: toasterTime});
//         const data = await response;
//         return data.data
//     } catch (error: any) {
//         toast.error(error.message, { autoClose: toasterTime});
//         throw error; // Throw the error to be caught by the rejected case in the slice
//     }
// }); 

// export const deleteBusiness = createAsyncThunk("businesses/deleteBusiness", async (businessId: any) => {
//     try {
//         const token = localStorage.getItem('token');
//         const args = {
//             url: "businesses/" + businessId,
//             method: "DELETE",
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + token,
//             }
//         };
//         const response = await request(args); 
//         toast.success("Businesses deleted Successfully", { autoClose: toasterTime});
//         const data = await response;
//         // console.log('delete data')
//         console.log(data)
//         return businessId
//     } catch (error: any) {
//         toast.error(error.message, { autoClose: toasterTime});
//         throw error; // Throw the error to be caught by the rejected case in the slice
//     }
// })