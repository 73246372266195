import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import convertDateFormat from "Components/Custom/DateFormat";
import { getBusinesses } from "slices/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import CustomIcon from "Components/Custom/CustomIcon";

const head = [
  { id: 1, name: "#", isSortable: true, key: "id" },
  { id: 2, name: "Business", isSortable: true, key: "business_name" },
  { id: 3, name: "Business Owner", isSortable: true, key: "owner_name" },
  { id: 8, name: "Status", isSortable: true, key: "status" },
  { id: 5, name: "Created", isSortable: true, key: "created_at" },
  { id: 7, name: "Time Zone", isSortable: true, key: "timezone_name" },
  { id: 9, name: "Actions" }
]


function Dashboard() {
  document.title = "Admin | Content Pro";

  // redux code
  const selectProperties = createSelector(
    (state: any) => state.businesses,
    (businesses) => ({
      businesses: businesses.businessList,
      loading: businesses.loading,
      pagination: businesses.pagination
    })
  );

  const { businesses, loading, pagination } = useSelector(selectProperties);
  //redux end

  // local state mng
  const [businessData, setBusinessData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    
    setIsLoading(loading);
    setBusinessData(businesses);
  }, [businesses, loading]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs title="Dashboard" breadcrumbItem="Businesses" /> */}
        <Row>
          <Col>
            <Card className="spiner_div_activities">
                <PaginatedTable
                  tableHead={head}
                  onGetData = {getBusinesses}
                  pagination = {pagination}
                  isLoading = {isLoading}
                  isAddButton = {false} 
                  title = 'Businesses'
                  businessHead="business-head"
                >
                  {businessData.length !== 0 && businessData.map((item : any, index : any) => (
                    <tr key={item.id}>
                      <th scope="row">{item.id}</th>
                      <td className="d-flex">
                        <div className="flex-shrink-0 me-2 my-auto">
                          {item?.image ? 
                            <img src={item?.image} alt="" className="avatar-xs rounded-circle"/>
                            :
                            <div className="avatar-xs">
                              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                {item.business_name?.charAt(0)}
                              </div>
                            </div>
                          }
                          </div>
                          <div>
                          <h5 className='font-size-12 mb-1'>
                            <Link to={`/dashboard?businessId=${item.id}`} className='text-dark'>{item.business_name}</Link>
                          </h5>
                          <p className="text-muted font-size-12 mb-0">{item.business_key}</p>
                        </div>
                      </td>
                      <td>
                        <h5 className='font-size-12 mb-1'>{item.owner_name}</h5>
                        <p className="text-muted font-size-12 mb-0">{item.owner_email}</p>
                      </td>
                      <td>{item.status === 1 ? <span className="badge badge-soft-success font-size-11">Active</span> : <span className="badge badge-soft-danger font-size-11">In Active</span> }</td>
                      <td><p className="text-muted font-size-12 mb-0">  {convertDateFormat(item.created_at)}</p>
                      </td>
                      <td>{!(item.timezone) ? <p className="text-danger mx-auto">Not Selected</p> : 
                      <>
                        <h5 className='font-size-12 mb-1'>{item.timezone_name}</h5>
                        <p className="text-muted font-size-12 mb-0">{item.timezone}</p>
                      </>
                      }</td>
                      <td>
                        <Link to={`/dashboard?businessId=${item.id}`}>
                        <CustomIcon className="btn btn-soft-success  bx " iconName= "bx-right-arrow-alt" id={`view-${item.id}`} text="View"/>
                        </Link>
                      </td>
                    </tr>
                  ))}
                  {businessData.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
                </PaginatedTable>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Dashboard;
