import React from 'react';
import { Card, CardBody, Col, Button, } from "reactstrap";
import SocialIcon from "../../../Activity/UI/SocialIcon";
import CustomIcon from "Components/Custom/CustomIcon";

function AccountCard({item, onDeleteClick, onEditClick, onSettingsClick, isDisabled}) {
  return (
    <Col md={6} lg={4} key={item.id} className="my-3">
        <Card className="bg-light h-100">
            <CardBody className="d-flex flex-column pb-0">
            <div className="d-flex flex-wrap align-items-start">
                <SocialIcon 
                type={item?.integration_type?.type_name} 
                slug={item?.integration_type?.type_slug} 
                title={item?.integration_user || item.access_token?.platform_username}
                />
                <div className="ms-auto">
                {!item?.integration_type?.connect_url &&
                    <Button className="btn btn-soft-info btn-sm me-1" onClick={() => onEditClick(item.id)} disabled={isDisabled}>
                    <CustomIcon className="mdi " iconName= "mdi-pencil" id={`edit-${item.id}`} text="Edit"/>
                    </Button>
                }
                <Button className="btn btn-soft-danger btn-sm" onClick={() => onDeleteClick(item.id)} disabled={isDisabled}>
                    <CustomIcon className="mdi " iconName= "mdi-delete-outline" id={`remove-${item.id}`} text="Disconnect" />
                </Button>
                {item?.integration_type?.type_slug === 'wordpress' && <Button className="btn btn-soft-secondary btn-sm ms-1" onClick={() => onSettingsClick(item.id)}>
                    <CustomIcon className="mdi " iconName= "mdi-cog" id={`settings-${item.id}`} text="Settings" />
                </Button>}
                </div>
            </div>

            <div className="d-flex flex-wrap border-bottom ms-4 mt-3">
                <div className="my-auto"><span className='ms-3 '>{item?.app_url || item?.access_token?.platform_id}</span></div>
            </div>
            </CardBody>
        </Card>
    </Col>
  )
}

export default AccountCard