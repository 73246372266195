import convertDateFormat from "Components/Custom/DateFormat";
import { LinkWithQuery } from "helpers/link_helper";
import React from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,} from "reactstrap";
import { createSelector } from "reselect";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    user: user.user,
  })
);

interface TaskProps {
  task: {
    id: number;
    title: string;
    type: string;
    typeColor:string
    creator: string;
    team: string;
    state: string;
    stateColor:string
    priority: string;
    status: string;
    dueAt: string;
    createdAt: string;
    updatedAt: string;
    columnId: number;
  };
  deleteTask: (taskId: number) => Promise<void>;
}

const Task: React.FC<TaskProps> = ({ task, deleteTask }) => {
  const { user} = useSelector(selectProperties);


  const [{ isDragging }, drag] = useDrag(() => ({
    type: "TASK",
    item: { id: task.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const canEdit = user?.user_type !== 'Member'
  // const canDelete = user?.user_type !== 'Member'

  return (
    <div ref={drag} className={`${isDragging ? "dragging" : ""} mb-2 rounded`}  id={task.id + "-task"} style={{ border: `2px solid ${task.stateColor}`}}>
      <Card className="border rounded shadow-sm mb-0">
        <CardBody className="pb-0">

          <UncontrolledDropdown className="float-end">
            <DropdownToggle className="arrow-none" tag="a" color="white">
              <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end ">
                <LinkWithQuery to={`/posts-details/${task.id}`} project={true} className="text-dark" queries={`return=posts-kanban`}>
              <DropdownItem className="view-details">
                  View Details
              </DropdownItem>
                </LinkWithQuery>
             {canEdit && <DropdownItem className="edit-task-details">
                <LinkWithQuery to={`/posts-details/${task.id}/edit`} project={true} className="text-dark" queries={`return=posts-kanban`}>
                  Edit
                </LinkWithQuery>
              </DropdownItem>}
             {/* {canDelete && <DropdownItem className="delete-task" onClick={() => {deleteTask(task.id)}}>
                Delete
              </DropdownItem>} */}
            </DropdownMenu>
          </UncontrolledDropdown>

          <div>
          <h6 className="text-truncate task-title" id={`task-name-${task.id}`}>
            <LinkWithQuery to={`/posts-details/${task.id}`} project={true} className="text-success" queries={`return=posts-kanban`}>
              {task.title}
            </LinkWithQuery>
          </h6>
          
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} className="mt-3 mb-2 medium">
            <span 
              style={{
                backgroundColor: task.typeColor,
                width: '12px',
                height: '12px',
                display: 'inline-block',
                borderRadius: '50%',
                marginRight: '10px'
              }} 
            />
            <p className="fs-6 mb-0">{task.type}</p>
          </div>
          <p className="small mb-2"><strong>Priority:</strong> {task.priority}</p>
          <p className="small mb-2"><strong>Team:</strong> {task.team}</p>
          <p className="small mb-2"><strong>Creator:</strong> {task.creator}</p>
          <p className="small mb-2"><strong>Created:</strong> {convertDateFormat(task.createdAt)}</p>
        </div>
        </CardBody>
      </Card>
      
    </div>
  );
};

export default Task;
