import React from 'react';
import { Col, Label, Row, Input } from "reactstrap";

function SocialMediaForm({ formData, setFormData, isDisabled, validationErrors}) {
  return (
    <Row className='mt-3'>
        <Col sm={12} className='mb-3'>
            <Label className="col-form-label">Enter Description</Label>
            <Input
                id="content"
                name="content"
                tag="textarea"
                rows={25}
                placeholder="Place Your Content Here..."
                value={formData?.content}
                disabled={isDisabled}
                onChange={(event) => setFormData((prev : typeof formData) => {return {...prev, content: event.target.value}})}
            />
            {validationErrors.content && (
                <div className="d-block invalid-feedback">{validationErrors.content}</div>
            )}
        </Col>

        <Col md={7} className='mt-3'>
        <Row> 
            <Col md={2}><Label className="col-form-label">Website URL</Label></Col>
            <Col md={6} >
            <Input
                id="webLink"
                name="webLink"
                type="url"
                placeholder="Enter Web Link..."
                value={formData.webLink}
                disabled={isDisabled}
                onChange={(event) => setFormData((prev : typeof formData) => {return {...prev, webLink: event.target.value}})}
            />
            {validationErrors.webLink && (
                <div className="d-block invalid-feedback">{validationErrors.webLink}</div>
            )}
            </Col>
        </Row>
        </Col>

        <Col md={5} className='mt-3'>
        <Row> 
            <Col md={3}><Label className="col-form-label">Tags</Label></Col>
            <Col md={9}>
            <Input
                id="tags"
                name="tags"
                type="text"
                placeholder="Enter Tags..."
                value={formData.tags}
                disabled={isDisabled}
                onChange={(event) => setFormData((prev : typeof formData) => {return {...prev, tags: event.target.value}})}
            />
            {validationErrors.tags && (
                <div className="d-block invalid-feedback">{validationErrors.tags}</div>
            )}
            </Col>
        </Row>
        </Col>
        
    </Row>
  );
}

export default SocialMediaForm;
