import React from "react"
import { Modal, ModalBody } from "reactstrap"

interface props {
  show: boolean;
  onConfirmClick: any;
  onCloseClick: any;
  message : any
}

const ConformationModal = ({ show, onConfirmClick, onCloseClick, message }:props) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} backdrop={'static'}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-exclamation"></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">Are you sure you want to <strong>{message}</strong>.</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Close</button>
            <button type="button" className="btn btn-success" onClick={onConfirmClick}>Confirm</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ConformationModal
