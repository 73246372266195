import React, { useState } from "react";
import { Table, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import CollapseItem from "./UI/CollapseItem";
export interface ProgressType {
  id: number;
  color: string;
  label: string;
  percentage: number;
  count: number
}
interface PublishStatusProps {
  rawData: { [key: string]: string }; // Flexible object structure
}

const getData = (data: { [key: string]: string }): ProgressType[] => {
  const keys = Object.keys(data);
  const total = Object.values(data).reduce((sum, value) => sum + parseInt(value, 10), 0);

  return keys.map((key, index) => {
    const value = parseInt(data[key] || '0', 10);
    const percentage = total > 0 ? ((value / total) * 100).toFixed(0) : "0"; // Handle zero total

    switch (key) {
      case "published_count":
        return { id: index, label: "Published", percentage: parseInt(percentage, 10), color: '#28a745', count: value };
      case "failed_count":
        return { id: index, label: "Failed", percentage: parseInt(percentage, 10), color: '#dc3545', count: value };
      case "in_progress_count":
        return { id: index, label: "In Progress", percentage: parseInt(percentage, 10), color: '#ffc107', count: value };
      default:
        return { id: index, label: key, percentage: parseInt(percentage, 10), color: '#abcfbd', count: value };
    }
  });
};

const PublishStatusChart: React.FC<PublishStatusProps> = ({ rawData }) => {
  const postStats = getData(rawData || {});
  const [collapseStates, setCollapseStates] = useState(true);

  // Total posts and percentage calculation
  const totalPosts = postStats?.reduce((total, item) => total + item.count, 0);
  const enrichedPostStats = postStats?.map((item) => ({
    ...item,
    percentage: totalPosts > 0 ? (((item?.count) / totalPosts) * 100).toFixed(1) : "0", // Handle zero total
  }));

  // Radial Bar Chart Configuration
  const radialChartOptions = {
    series: enrichedPostStats.map((item) => Number(item.percentage)),
    options: {
      chart: {
        height: 350,
        type: "radialBar" as const,
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "16px",
            },
            value: {
              fontSize: "14px",
              formatter: (val: number) => `${val}%`,
            },
            total: {
              show: true,
              label: "Total",
              formatter: () => `${totalPosts} Posts`,
            },
          },
        },
      },
      labels: enrichedPostStats.map((item) => item.label),
      colors: enrichedPostStats.map((item) => item.color),
    },
  };

  return (
    <CollapseItem
      title="Posts Status"
      col={collapseStates}
      toggle={() => setCollapseStates((prevState) => !prevState)}
    >
        <CardBody>
          <Row>
            <Col xl={6} lg={8} className="text-center mx-auto">
              <h5 className="mb-4 font-medium ">Post Status Overview</h5>
              <ReactApexChart
                options={radialChartOptions.options}
                series={radialChartOptions.series}
                type="radialBar"
                height={350}
              />
            </Col>

            <Col xl={6} lg={8} className="text-center  mx-auto">
              <h5 className="mb-4 font-medium">Detailed Status</h5>
              <Table bordered hover className="mb-0 mt-5">
                <thead className="table-light">
                  <tr>
                    <th>Status</th>
                    <th className="text-center">Count</th>
                    <th className="text-center">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {enrichedPostStats.map((item, index) => (
                    <tr key={index}>
                      <td style={{ color: item.color, fontWeight: "bold" }}>
                        {item.label}
                      </td>
                      <td className="text-center">{item.count}</td>
                      <td className="text-center">{item?.percentage || 0}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
    </CollapseItem>
  );
};

export default PublishStatusChart;
