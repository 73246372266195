import React from "react";
import { Col, Label, Row } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import createIframelyPlugin from "@jimmycode/draft-js-iframely-plugin";

const { Iframely } = createIframelyPlugin();

function CustomEditor({editorState, setEditorState, isDisabled=false}) {

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'history', 'emoji',],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    history: {
      options: ['undo', 'redo'],
    },
    emoji: {}, // enabling emoji option
  };

  return (
    <>
      <Row>
        <Col className="mx-auto">
          <div className="mb-3">
            <Label htmlFor="activityDesc">Enter Description</Label>
            <Editor
              placeholder="Place Your Content Here..."
              editorState={editorState}
              onEditorStateChange={setEditorState}
              editorStyle={{ height: '500px' }}
              plugins={[Iframely]}
              toolbar={toolbarOptions}
              readOnly={isDisabled}
            />
          </div>
          {/* {!editorState.getCurrentContent().hasText()  && (
              <div className="d-block invalid-feedback">Content is required.</div>
          )} */}
        </Col>
      </Row>
    </>
  );
}

export default CustomEditor;
