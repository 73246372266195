import React, { useEffect, useRef, useState } from "react";
import {Card, CardBody, Container, Input, Spinner, Table, Button, UncontrolledAlert, Modal, ModalBody} from "reactstrap";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { CancelScheduledPost, getPublishContentList, verifyPublishContent,} from "helpers/API Calls/Project/Publish/Publish";
import SocialIcon from "./UI/SocialIcon";
import convertDateFormat from "Components/Custom/DateFormat";
import { getActivityDetail } from "helpers/API Calls/Project/Activity/Activity";
import Header from "./UI/activity-header";
import { statusBadge } from "./Publish/Publish List/StatusBadge";
import ActionButton from "./Publish/Publish List/ActionButton";
import usePublishActions from "./Publish/Publish List/usePublishActions";
import PostPublishModal from "./Publish/Publish List/PublishModal";
import withRouter from "Components/Common/withRouter";
import convertToTime from "Components/Custom/TimeFormat";

function PostPublish({ router }: any) {
  const { navigate, location, params } = router;

  document.title = "Publish Activity | Content Pro";

  const searchParams = new URLSearchParams(location.search);
  let projectId = searchParams.get("projectId");
  const returnURL = searchParams.get("return");
  const {id} = params; 
  
  const [publishData, setPublishData] = useState<any>({items: []});
  const [activity, setActivity] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [modal, setModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>(""); //publish | schedule 
  const [errorMessage, setErrorMessage] = useState<any[]>([]);

  const [cancelItem, setCancelItem] = useState<any>(null);
  
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [publishResponses, setPublishResponses] = useState([]);
  
  const inputRef = useRef<HTMLInputElement>(null);

  const closeModal = () => {
    setModal(false); 
    setErrorMessage([]);
  }

  // getting publish data
  const getPublishList = async () =>{
    setIsLoading(true); 
    const response = await getPublishContentList(`activity_id=${id}&project_id=${projectId}`); 
    if(response){
      setPublishData(response)
    }
    const data = await getActivityDetail(id, navigate);
    if (data) {
      setActivity(data)
    }
    setIsLoading(false); 

  }
  useEffect(() => {
    getPublishList(); 
  }, [])

 const getPublishContent = (): any[] => {
    return errorMessage.some(item => !item.status)
      ? selectedItems.filter((_, index) => errorMessage[index]?.status)
      : selectedItems;
  };
  const publishItems = getPublishContent(); 
  
  const {handlePublish, handleSchedule, isSubmitting, setIsSubmitting } = usePublishActions({publishItems,publishData, getPublishList, closeModal, inputRef, setSelectedItems}); 

  // checkbox select 
  const handleSelectItem = (item: any) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
      // setSelectAll(false)
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleAction = async (items, type) => {    
    setIsSubmitting(true)
    setActionType(type);
    setSelectedItems(items);
    const verificationContent = items.map(item => ({activity_id: id, project_integration_id: item.id}))
    const response = await verifyPublishContent({publish_contents: verificationContent}); 
    if(response?.success){
      setErrorMessage(response.data)
      getPublishList();
    }
    else{
      setErrorMessage(items.map(x => ({status: false, errors: 'Could not verify status due to unknown reasons. Refresh the page or try again later.'})));
    }
    setModal(true);
    setIsSubmitting(false)
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.checked){
      const selectableItems = publishData.items.filter(
        (item) => item.publish_status_name !== "Published" && item.publish_status_name !== "Scheduled"
      );
      console.log('selectableItems',selectableItems)
      setSelectedItems(selectableItems);
    }else{
      setSelectedItems([])
    }
  };

  const handlePublishResponses = (responses) => {
    setPublishResponses(responses);
  };

  // displaying bulk publish responses above the table 
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (publishResponses.length > 0) {
      timeoutRef.current = setTimeout(() => {
        setPublishResponses([]);
        timeoutRef.current = null;
      }, 10000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [publishResponses]);

  const handleCancelSchedule = async () => {
    if (cancelItem) {
      setIsSubmitting(true);
      const response = await CancelScheduledPost({ publish_content_id: cancelItem.publish_content_id });
      if (response.success) {
        setCancelItem(null);
        getPublishList();
      }
      setIsSubmitting(false);
    }
  };

  // table head 
  const head = [
    {id: 0, name: (<Input type="checkbox" onChange={handleSelectAll}/>)},
    { id: 2, name: "Platform",},
    { id: 8, name: "Status"},
    { id: 7, name: "Updated"},
    { id: 6, name: "Publish" },
    { id: 26, name: "Published On" },
    { id: 62, name: "Schedule" },
    { id: 42, name: "Scheduled On" },
    { id: 46, name: "Actions" },
  ]
  return(
    <>
      <Modal isOpen={!!cancelItem} toggle={() => setCancelItem(null)} centered={true}>
        <div className="modal-content">
            <ModalBody className="px-4 py-5">
              <button type="button" onClick={() => setCancelItem(null)} className="btn-close position-absolute end-0 top-0 m-3"></button>
              <div className="mb-4 mx-auto text-center">
                <div className="text-primary font-size-20">Cancel Schedule
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4 text-center">{`Are you sure you want to cancel the scheduled post on ${cancelItem?.integration_user || cancelItem?.platform_username}?`}</p>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button type="button" className="btn btn-secondary" onClick={() => setCancelItem(null)} disabled={isSubmitting}>Close</button>
                <button type="button" className='btn btn-danger' onClick={handleCancelSchedule} disabled={isSubmitting}>{isSubmitting ? "Canceling..." : "Cancel"}</button>
              </div>
            </ModalBody>
        </div>
      </Modal>

      <PostPublishModal 
        isOpen={modal} 
        toggle={closeModal} 
        actionType={actionType} 
        selectedPlatform ={selectedItems}  
        onPublishClick={handlePublish}
        onScheduleClick={async (e) => {
          const responses = await handleSchedule(e);
          if (responses) handlePublishResponses(responses)}
        }
        isSubmitting={isSubmitting}
        onSubmitChange={setIsSubmitting}
        inputRef={inputRef}
        errorMessage={errorMessage}
        onPublishComplete={handlePublishResponses}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Activity" breadcrumbItem="Publish Post" queries={`return=${returnURL}`}  isGoBackButton={true} project={true} to={`/posts-details/${id}`}/>
          <Card>
           { isLoading ? (
              <Spinner color="primary" className="position-absolute top-50 start-50" />
              ) : (
              <CardBody>
                {/* header */}
                <Header 
                  state={activity?.activity_state?.state_name || "N/A"}
                  stateColor={activity?.activity_state?.color || "#ccc"}
                  type={activity?.activity_types?.activity_type || "N/A"}
                  typeColor={activity?.activity_types?.color || "#ccc"}
                  date={activity?.created_at || "N/A"}
                  due={activity?.due_date}
                  creator={activity?.creator_name || "N/A"}
                  team={activity?.team_name || ""}
                  title={activity?.title || "Untitled"}
                />

                {publishResponses.map((item:any, index) => {
                  return <UncontrolledAlert color={item?.status ? 'success' : 'danger'} key={index} 
                  className="py-1 mt-3 d-flex col-xl-6 col-md-8 mx-auto">
                    <div className="d-flex me-3">
                      <SocialIcon 
                        type={item?.post?.integration_type_name} 
                        slug={item?.post?.integration_type_slug} 
                        title={item?.post?.integration_user || item?.post?.platform_username}
                      />
                    </div>
                    <span className="my-auto mx-auto">{item?.message}</span>
                  </UncontrolledAlert>
                })}
                
                <div className="my-auto d-flex justify-content-end gap-2">
                  <Button color="info" onClick={() => handleAction(selectedItems, "schedule")} disabled={isSubmitting || selectedItems.length === 0}>
                    Schedule All
                  </Button>
                  <Button color="success" onClick={() => handleAction(selectedItems, "publish")} disabled={isSubmitting || selectedItems.length === 0}>
                    Publish All
                  </Button>
                </div>
                
                {/* table */}
                <div className="table-responsive mt-4">
                  <Table hover className="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline">
                    <thead className="table-light">
                      <tr>
                        {head.map((item) => (<th key={item.id}>{item.name}</th>))}
                      </tr>
                    </thead>
                    <tbody>
                      {publishData && publishData?.items?.length >0 &&  publishData?.items?.map((item) =>
                        <tr key={item.id}>
                          <td>
                            <Input
                              type="checkbox"
                              checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
                              onChange={() => handleSelectItem(item)}
                              disabled={item.publish_status_name === "Published" || item.publish_status_name === "Scheduled"}
                            />
                          </td>
                          <td>
                            <div className="d-flex">
                              <SocialIcon 
                                type={item?.integration_type_name} 
                                slug={item?.integration_type_slug} 
                                title={item?.integration_user || item?.platform_username}
                              />
                            </div>
                          </td>
                          <td>{statusBadge(item?.publish_status_name)}</td>
                          <td>{convertDateFormat(item?.updated_at)}</td>
                          <td>
                            <button className="btn btn-soft-success" 
                              onClick={() => {handleAction([item], "publish");}} 
                              disabled={item?.publish_status_id === 3 || item?.publish_status_id === 5}
                            >
                              {isSubmitting && selectedItems.some(dataItem => dataItem.id === item.id) && actionType === "publish" ? 'Publishing...' : 'Publish'}
                            </button>
                          </td>
                          <td>{convertDateFormat(item?.publish_date) || 'N/A'}</td>
                          <td>
                            <button className="btn btn-soft-info" 
                              onClick={() => {handleAction([item], "schedule"); }} 
                              disabled={item?.publish_status_id === 3 || item?.publish_status_id === 5}
                            >
                              {isSubmitting && selectedItems.some(dataItem => dataItem.id === item.id) && actionType === "schedule" ? 'Scheduling...' : 'Schedule'}
                            </button>
                          </td>
                          <td>{convertDateFormat(item?.schedule_date) || 'N/A'} 
                            {item?.schedule_date && <div>{convertToTime(item?.schedule_date)}</div>}
                          </td>
                          <td>{ActionButton(item, returnURL, setCancelItem)}</td>
                        </tr>
                      )}
                      {publishData && publishData?.items?.length ===0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Integration Found. Add New Integration to Continue!</td></tr>}
                    </tbody>
                  </Table>
                </div>
            </CardBody>
          )}
           </Card>
        </Container>
      </div>
    </>
  )
}

export default withRouter(PostPublish)