import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const WeeklyChart = ({ data }) => {
  const options: any = {
    chart: {
      toolbar: { show: false },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#007bff"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('en', { month: 'short' })), // Jan, Feb...
      title: { text: "Months", style: { fontSize: "12px", color: "#666" } },
    },
    yaxis: {
      title: { text: "Activities Completed", style: { fontSize: "12px", color: "#666" } },
    },
  };

  return (
    <React.Fragment>
      <Card className="shadow-sm border-0 dashboard-activity-bg">
        <CardBody>
          <div className="clearfix">
            <CardTitle tag="h4">
              Monthly Activities in the Last Year
            </CardTitle>
            <p className="text-muted mb-4">
              This chart represents the activities you have completed or closed each month over the past year. Use this
              data to track your monthly productivity and identify trends.
            </p>
          </div>

          <Row>
            <Col>
              <div id="line-chart" dir="ltr">
                <ReactApexChart
                  series={data}
                  options={options}
                  type="line"
                  height={320}
                  className="apex-charts"
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WeeklyChart;
