import { createSlice } from "@reduxjs/toolkit";
import { getBusinesses}  from "./thunk";

export const initialState = {
    businessList: [], 
    loading: true,
    // hasError : false, 
    pagination: {current_page : 1, per_page : 100,}
}

const BusinessSlice = createSlice({
    name: "BusinessesSlice", 
    initialState, 
    reducers: {}, 
    extraReducers: (builder) => {
        builder.addCase(getBusinesses.fulfilled, (state: any, action: any) => {
            state.businessList = action.payload.items;
            state.pagination = action.payload.pagination;
            // console.log(action.payload.pagination)
            state.loading = false; // Set loading to false when request is done
        });
        builder.addCase(getBusinesses.pending, (state: any, action: any) => {
            state.businessList = []; // reset any previous data while fetching new data
            state.loading = true; 
        });
        builder.addCase(getBusinesses.rejected, (state: any, action: any) => {
            state.businessList = []; // reset any previous data
            state.loading = false; 
        });
        // builder.addCase(deleteBusiness.fulfilled, (state: any, action: any) => {
        //     // on deletion, remove the data from the array on front end (no need to fetch it again)
        //     state.businessList = state.businessList.filter(business => business.id !== parseInt(action.payload))
        //     state.loading = false;
        // });
        // builder.addCase(createBusiness.fulfilled, (state: any, action: any) => {
        //    state.hasError = false; 
        // });
        // builder.addCase(createBusiness.pending, (state: any, action: any) => {
        //     state.hasError = false; 
        // });
        // builder.addCase(createBusiness.rejected, (state: any, action: any) => {
        //     state.hasError = true; 
        // });
        // builder.addCase(editBusiness.fulfilled, (state: any, action: any) => {
        //     state.hasError = false; 
        // });
        // builder.addCase(editBusiness.pending, (state: any, action: any) => {
        //     state.hasError = false; 
        // });
        // builder.addCase(editBusiness.rejected, (state: any, action: any) => {
        //     state.hasError = true; 
        // });
    }
    
})


export default BusinessSlice.reducer
