import { toast } from "react-toastify";
import request from "../../../request_helper";
import toasterTime from "config";

export const getSubscription = async (id, navigate: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "stripe/subscriptions/" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args, navigate); 
        // toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data?.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}