import React from 'react';
import { Col, Label, Row } from "reactstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CustomEditor from 'Components/Custom/Editor';

const animatedComponents = makeAnimated();

function WebsiteForm({ formData, setFormData, formOptions, validationErrors,isDisabled, editorState, setEditorState }) {
  return (
    <>
    <CustomEditor editorState={editorState} setEditorState={setEditorState} isDisabled={isDisabled}/>

    <Row>
        <Col md={7} className='mt-3'>
            <Row> 
                <Col md={2}><Label className="col-form-label">Category</Label></Col>
                <Col md={6} >
                <Select
                    components={animatedComponents}
                    value={formData.category}
                    onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, category: selectedOption}})}
                    isSearchable={true}
                    name="category"
                    options={formOptions.categories}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                />
                {validationErrors.category && (
                    <div className="d-block invalid-feedback">{validationErrors.category}</div>
                )}
                </Col>
            </Row>
        </Col>
        
        <Col md={5} className='mt-3'>
            <Row> 
                <Col md={3}><Label className="col-form-label">Status</Label></Col>
                <Col md={9}>
                <Select
                    components={animatedComponents}
                    value={formData.status}
                    onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, status: selectedOption}})}
                    isSearchable={true}
                    name="websiteStatus"
                    options={formOptions.statuses}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                />
                {validationErrors.status && (
                    <div className="d-block invalid-feedback">{validationErrors.status}</div>
                )}
                </Col>
                
            </Row>
        </Col>
        
        <Col md={7} className='mt-3'>
            <Row> 
                <Col md={2}><Label className="col-form-label">Author</Label></Col>
                <Col md={6} >
                <Select
                    components={animatedComponents}
                    value={formData.author}
                    onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, author: selectedOption}})}
                    isSearchable={true}
                    name="author"
                    options={formOptions.authors}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={isDisabled}
                />
                {validationErrors.author && (
                    <div className="d-block invalid-feedback">{validationErrors.author}</div>
                )}
                </Col>
            </Row>
        </Col>
    </Row>
    </>
  );
}

export default WebsiteForm;
