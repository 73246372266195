import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { LinkWithQuery } from "helpers/link_helper";
import convertDateFormat from "Components/Custom/DateFormat";
interface ProjectTitleProps {
  image: string | null;
  name: string;
  projectKey: string;
  date: string;
  type: string;
  activityCounts: { name: string; count: number }[];
  canAdd?: boolean;
}
const ProjectTitle: React.FC<ProjectTitleProps> = ({
  image,
  name,
  projectKey,
  date,
  type,
  activityCounts,
  canAdd,
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <div className="d-flex">
                    <div className="flex-shrink-0 my-auto me-3">
                      {image ? (
                        <img
                          src={image}
                          alt=""
                          className="avatar-sm rounded-circle"
                        />
                      ) : (
                        <div className="avatar-xs">
                          <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                            {name?.charAt(0)}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="">
                        <p className="mb-2">{type}</p>
                        <h5 className="mb-1">{`${name}, ${projectKey}`}</h5>
                        <p className="mb-0">
                          Started on {convertDateFormat(date)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={canAdd ? 6 : 8} className="align-self-center">
                  <div className="text-center mt-4 mt-lg-0">
                    <Row>
                      <Col lg={3} xs={6} className="mb-3">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total Activities
                          </p>
                          <h5 className="mb-0">{activityCounts.reduce((acc, pilot) => acc + pilot.count, 0)}</h5>
                        </div>
                      </Col>
                      {activityCounts.map((activity, index) => (
                        <Col lg={3} xs={6} key={index} className="mb-3">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              {activity.name}
                            </p>
                            <h5 className="mb-0">{activity.count}</h5>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>

                {canAdd && (
                  <Col lg={2}>
                    <div className="text-sm-end text-end clearfix mt-4 mt-lg-0">
                      <LinkWithQuery
                        to={`/posts-create`}
                        project={true}
                        queries={`return=posts-list`}
                        className="btn btn-soft-success"
                      >
                        Add New Activity
                      </LinkWithQuery>
                    </div>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProjectTitle;
