import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback, Spinner } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import { getTeams } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import { getActivityTypes } from "helpers/API Calls/Project/Settings/Activity Type";
import { getActivityStates } from "helpers/API Calls/Project/Settings/Activity State";
import { createActivity } from "helpers/API Calls/Project/Activity/Activity";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import withRouter from "Components/Common/withRouter";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({user: user.user,})
);

function ActivityCreate({ router }: any) {
  const { navigate, location } = router;

  //meta title
  document.title = "Create Activity | Content Pro";
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const projectId = searchParams.get("projectId");
  const returnURL = searchParams.get("return");

  const { user } = useSelector(selectProperties);

  // validation
  const validation: any = useFormik({
    initialValues: {
      actName: '',
      state: null, 
      type: null, 
      creator: null, 
      team: null, 
      dueDate: '', 
    },
    validationSchema: Yup.object({
      actName: Yup.string()
      .min(3, 'Activity Name must be at least 3 characters')
      .required("Please Enter Activity Name"),
      type: Yup.object().shape({label: Yup.string().required(),value: Yup.string().required(),}).nullable().required("Please Select Activity Type"),
      team: Yup.object().shape({label: Yup.string().required(),value: Yup.string().required(),}).nullable().required("Please Select a Team"),
      state: Yup.object().shape({label: Yup.string().required(),value: Yup.string().required(),}).nullable().required("Please Select Activity state"),
      creator: Yup.object().shape({label: Yup.string().required(),value: Yup.string().required(),}).nullable().required("Please Select a Creator"),
      dueDate: Yup.string(),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        project_id: projectId,
        title: values.actName, 
        activity_type_id: values.type.value,
        team_id: values.team.value,
        creator_id: values.creator.value,
        activity_state_id: values.state.value,
        parent_id: null,
        due_date: values.dueDate ? values.dueDate : null,
      }
      // console.log(data);
      const response = await createActivity(data); 
      if(response){setSubmitting(false);}
      if (response.success) {
        validation.resetForm();
        const id = response?.data?.id; 
        setTimeout(() => {navigate(`/posts-details/${id}?return=${returnURL !== null ? returnURL : 'posts-list'}&projectId=${projectId}&businessId=${businessId}`);}, 2000);
      }
      return;
    }
  });

  const [activityTypes, setActivityTypes] = useState<any>([]);
  const [activityStates, setActivityStates] = useState<any>([]);
  const [teams, setTeams] = useState<any>([]);
  const [creators, setCreators] = useState<any>([]); 
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {

      const teamData = await getTeams(businessId, `${user.user_type === 'Member' ? `user_id=${user.id}` : ''}`, navigate);
      if (teamData && teamData.length > 0) {
        setTeams(teamData?.map(item => ({ label: item.team_name, value: item.id, members: item.members })));
      }else{setTeams([]); }

      const typesData = await getActivityTypes(projectId, navigate); 
      if (typesData && typesData.length > 0) {
        setActivityTypes(typesData?.map(item => ({ label: item.activity_type, value: item.id })));
      }else{setActivityTypes([]);}

      const stateData = await getActivityStates(projectId, navigate); 
      if (stateData && stateData.length > 0) {
        setActivityStates(stateData?.map(item => ({ label: item.state_name, value: item.id })));
      }else{setActivityStates([]); }

      setIsLoading(false);
    }
    getData();
  }, [businessId, projectId, navigate, user]);

  const handleTeamChange = (selectedOption: any) => {
    validation.setFieldValue("team", selectedOption);
    const selectedTeam = teams.find((team: any) => team.value === selectedOption.value);
    if (selectedTeam && selectedTeam.members) {
      const mappedCreators = selectedTeam.members.map((member: any) => ({
        label: member.name,
        value: member.id,
      }));
      setCreators(mappedCreators);
      validation.setFieldValue("creator", null); // Reset the creator field
    } else {
      setCreators([]);
    }
  };

  return (
    <>
    <div className="page-content">
      <Container fluid>
        {isLoading ? (
          <div className="text-center spiner_div">
            <Spinner color="primary" />
          </div>
        ) : (
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <h5 className="mb-0 flex-grow-1 ">Create Activity</h5>
                <div className="flex-shrink-0">{/* ride justified data */}</div>
              </div>
              <Row>
                <Col lg={5} sm={7} className="mx-auto">
                  <Form id="create-form" className="mt-4" onSubmit={(e: any) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <Col className="mx-auto">
                        <div className="mb-3">
                          <Label htmlFor="name-input">Title</Label>
                          <Input
                            id="actName"
                            name="actName"
                            type="text"
                            placeholder="Enter Activity Title..."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.actName}
                          />
                          {validation.touched.actName && validation.errors.actName ? (
                            <FormFeedback type="invalid" className="d-block">{validation.errors.actName}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="mx-auto">
                        <Label htmlFor="type">Activity Type</Label>
                        <Select
                          id="type"
                          name="type"
                          className="border border-1 border light"
                          options={activityTypes}
                          onChange={(selectedOption) => {validation.setFieldValue("type", selectedOption)}}
                          onBlur={validation.handleBlur}
                          value={validation.values.type}
                        />
                        {validation.touched.type && validation.errors.type ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.type}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="mx-auto">
                        <Label htmlFor="team">Team</Label>
                        <Select
                          id="team"
                          name="team"
                          className="border border-1 border light"
                          options={teams}
                          onChange={handleTeamChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.team}
                        />
                        {validation.touched.team && validation.errors.team ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.team}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="mx-auto">
                        <Label htmlFor="creator">Creator</Label>
                        <Select
                          id="creator"
                          name="creator"
                          className="border border-1 border light"
                          options={creators}
                          onChange={(selectedOption) => {validation.setFieldValue("creator", selectedOption)}}
                          onBlur={validation.handleBlur}
                          value={validation.values.creator}
                        />
                        {validation.touched.creator && validation.errors.creator ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.creator}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="mx-auto">
                        <Label htmlFor="act-state">Activity State</Label>
                        <Select
                          id="act-state"
                          name="act-state"
                          className="border border-1 border light"
                          options={activityStates}
                          onChange={(selectedOption) => {validation.setFieldValue("state", selectedOption)}}
                          onBlur={validation.handleBlur}
                          value={validation.values.state}
                        />
                        {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.state}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="mx-auto">
                        <Label>Due Date</Label>
                        <Input 
                          id="dueDate"
                          name="dueDate"
                          type="date" 
                          placeholder="Enter Due Date"
                          className="form-control d-block"
                          onChange={(event: any) => validation.setFieldValue("dueDate", event.target.value)}
                          onBlur={validation.handleBlur}
                          value={validation.values.dueDate || ''}
                          invalid={validation.touched.dueDate && validation.errors.dueDate ? true : false}
                        />
                        {validation.errors.dueDate && validation.touched.dueDate ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.dueDate}</FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto mt-1">
                      <Button type="submit" color="success" disabled={validation.isSubmitting}>{validation.isSubmitting ? 'Creating...' : 'Create Activity'}</Button>
                      </div>
                    </Row>

                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
    </>
  );
};

export default withRouter(ActivityCreate);
