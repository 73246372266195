import React, { useEffect, useState } from "react";
import { Label, Row, Col, Button, Modal, ModalBody, ModalHeader } from "reactstrap";

interface ImageData { id: number; src: string; type: 'image' | 'video'; }

function ImageGallery({ files, modalSelectedImages, setModalSelectedImages, isDisabled, mediaType }) {
  const [images] = useState<ImageData[]>((files || []).map(item => ({ id: item.id, src: item.file, type: item.file_type })));
  const [displayedImages, setDisplayedImages] = useState<ImageData[]>(modalSelectedImages || []);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  // Synchronize the displayed images with modal selected images when the modal opens
  useEffect(() => {
    if (modalOpen) {
      setDisplayedImages(modalSelectedImages);
    }
  }, [modalOpen, modalSelectedImages]);

  const toggleModal = () => {
    setModalOpen(prev => !prev);
  };

  const handleSelectImage = (image: ImageData) => {
    const selectedImages = modalSelectedImages.filter(img => img.type === 'image');
    const selectedVideos = modalSelectedImages.filter(img => img.type === 'video');
    const isSelected = modalSelectedImages.some((img) => img.id === image.id);
    if (!isSelected) {
      if (image.type === 'image' && selectedImages.length >= mediaType[0]) {
        alert(
          mediaType[0] > 0 
          ? `You can select up to ${mediaType[0]} image${mediaType[0] > 1 ? 's' : ''} only.` 
          : `Image posting is not allowed on this platform.`
        );
        return;
      }
      if (image.type === 'video' && selectedVideos.length >= mediaType[1]) {
        alert(
          mediaType[1] > 0 
          ? `You can select up to ${mediaType[1]} video${mediaType[1] > 1 ? 's' : ''} only.` 
          : `Video posting is not allowed on this platform.`
        );
        return;
      }
    }
    setModalSelectedImages((prevSelectedImages) =>
      isSelected
        ? prevSelectedImages.filter((img) => img.id !== image.id)
        : [...prevSelectedImages, image]
    );
  };

  const handleSaveSelection = () => {
    setDisplayedImages(modalSelectedImages);
    toggleModal();
  };

  return (
    <>
      <Row className="mt-3">
        <Col md={7} className="mt-3">
          <Row>
            <Col xs={2}>
              <Label htmlFor="images" className="col-form-label">Media</Label>
            </Col>
            <Col xs={10} className="p-0 mx-auto">
              <div className="position-relative px-0">
                <Button onClick={toggleModal} className="ms-2 btn-soft-success" disabled={isDisabled}>
                  Select media
                </Button>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Display selected images */}
        <Row className="mt-3">
          {displayedImages.map((image) => (
            <Col key={image.id} xs={2} className="mb-3 avatar-lg rounded me-2">
              <div className="avatar-title bg-white">
                {image.type === 'video' ? 
                  <video
                    src={image.src}
                    id={`${image.id}-video`}
                    className="avatar-lg rounded h-100"
                    controls
                  />
                  : 
                  <img
                    src={image.src}
                    alt={`act-${image.id}`}
                    className="img-fluid"
                  />
                }
              </div>
            </Col>
          ))}
        </Row>
      </Row>

      <Modal isOpen={modalOpen} toggle={toggleModal} centered={true} backdrop={'static'}>
        <ModalHeader toggle={toggleModal}>Select Images</ModalHeader>

        <ModalBody className="px-4 py-5 text-center">
          <Row className="flex-wrap mx-auto text-center position-relative">
            {images.map((image) => (
              <Col key={image.id} xs={6} sm={4} md={3} className="avatar-xl rounded me-2 mb-2 p-0" 
              style={{cursor: "pointer", border: modalSelectedImages.some((img) => img.id === image.id) ? "2px solid #008957" : "none",}}
              >
                <div className="avatar-title bg-white">
                  <input
                    type="checkbox"
                    checked={(modalSelectedImages || [])?.some((img) => img.id === image.id)}
                    onChange={() => handleSelectImage(image)}
                    className="position-absolute form-check-input top-0 start-0 border-success border-2"
                  />
                  {image.type === 'video' ? 
                    <video
                      src={image.src}
                      id={`${image.id}-video`}
                      className="img-fluid"
                      controls
                      onClick={() => handleSelectImage(image)}
                    />
                    : 
                    <img
                      src={image.src}
                      alt={`act-${image.id}`}
                      className="img-fluid"
                      onClick={() => handleSelectImage(image)}
                    />
                  }
                </div>
              </Col>
            ))}
            {images && images.length === 0 && (
              <p className="text-center text-danger">
                No media available. Please add media to the activity, then return here to select it for posting.
              </p>
            )}
          </Row>

          <div className="hstack gap-2 mt-3 mb-0 justify-content-end">
            <button type="button" className="btn btn-secondary" onClick={() => setModalOpen(false)}>Close</button>
            {images && images.length !== 0 && <button type="button" className="btn btn-success" onClick={handleSaveSelection}>Select</button>}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ImageGallery;
