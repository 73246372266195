import request from "../../request_helper";

export const forgetPassword = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "password/forgotPassword",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        const data = await response;
        return data; 
    } catch (error: any) {
        return {success: false, message: error.message}
    }
}


export const resetPassword = async (reqData) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "password/resetPassword",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        const data = await response;
        return data; 
    } catch (error: any) {
        return {success: false, message: error.message}
    }
}