import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import { LinkWithQuery } from "helpers/link_helper";
import convertDateFormat from "Components/Custom/DateFormat";
interface LatestActivityProps {
  activities: {
    id: number;
    title: string;
    activity_type: string;
    state_name: string;
    created_at: "string";
  }[];
  canPublish: boolean;
}
const LatestActivity: React.FC<LatestActivityProps> = ({ activities, canPublish }) => {
  return (
    <React.Fragment>
      <Card className="shadow-sm border-0 dashboard-activity-bg">
        <CardBody>
          <div className="d-flex align-items-start justify-content-between">
            <div className="me-2">
              <h5 className="card-title mb-4">Latest Activities</h5>
            </div>
            <div className="text-end">
              <LinkWithQuery
                to="/posts-list"
                project={true}
                className="text-success"
              >
                View All
              </LinkWithQuery>
            </div>
          </div>
          {activities.length !== 0 && <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">Activity</th>
                  <th scope="col">State</th>
                  {canPublish && <th scope="col">Action</th>}
                </tr>
              </thead>

              <tbody>
                {activities?.map((item: any) => (
                  <tr key={item.id}>
                    <td>
                      <LinkWithQuery
                        to={`/posts-details/${item.id}`}
                        key={item.id}
                        project={true}
                        queries="return=posts-list"
                      >
                        <div className="d-flex">
                          <div className="flex-shrink-0 my-auto me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                {item.title?.charAt(0)}
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5
                              className="font-size-13 text-truncate mb-1 text-dark"
                              title={item.title}
                              style={{ maxWidth: "280px" }}
                            >
                              {item.title}
                            </h5>
                            <p className="text-muted mb-0">
                              {item.activity_type} - Started on{" "}
                              {convertDateFormat(item.created_at)}
                            </p>
                          </div>
                        </div>
                      </LinkWithQuery>
                    </td>
                    <td>{item.state_name}</td>
                    {canPublish && <td>
                      <LinkWithQuery
                        to={`/posts-details/${item.id}/publish`}
                        project={true}
                        queries="return=posts-list"
                        className="btn btn-soft-success btn-sm me-1"
                      >
                        Publish
                      </LinkWithQuery>
                    </td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>}
          {activities.length === 0 && (
            <p className="text-center text-danger my-4">
              No activities found. Please create a new activity or check back later.
            </p>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LatestActivity;
