import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormFeedback, Label, Row, Spinner } from "reactstrap";
import { Button, Form } from "reactstrap";
import InputElement from "Components/Custom/InputElement";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import GoBackButton from "Components/Common/GoBackButton";
import { editUser, getUserDetail } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getRoles } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";
import withRouter from "Components/Common/withRouter";

function EditUser({ router }: any) {
  const { navigate, location, params } = router;
  document.title = "Edit User | Content Pro";

  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const userId: any = params.id;
  
  const formik: any = useFormik({
    initialValues: {
      userName: "",
      role: null,
      email: "",
    },
    validationSchema: yup.object().shape({
      userName: yup.string()
      .matches(/^[A-Za-z\s]+$/, 'User Name can only contain letters and spaces')
      .min(3, 'Username must be at least 3 characters')
      .max(20, 'Username cannot exceed 20 characters')
      .required("Please Enter Username"),
      role: yup
        .object()
        .shape({
          label: yup.string().required('Please Select a Role'),
          value: yup.string().required('Please Select a Role'),
        }).nullable().required("Please Select Role"),
      email: yup.string()
      .email("Please enter a valid email address")
      .required("Please Enter Your Email"),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        name: values.userName,
        role_id: values.role.value,
        email: values.email,
        business_id: businessId, 
      };
      const response = await editUser(data, userId, navigate); 
      if (response) {
        setSubmitting(false);
      }
      if (response?.success) {
        setTimeout(() => { navigate(`/users?businessId=${businessId}`); }, 2000);
      }
      return;
    },
  });

  const [roles, setRoles] = useState<any>([]);
  const [user, setUser] = useState(null); 
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const role = await getRoles(businessId, navigate);
      setRoles(role?.map(item => ({ label: `${item.name}`, value: item.id })));
      const data = await getUserDetail(userId, navigate); 
      if(data){
        setUser(data); 
        formik.setValues({
          userName: data?.name,
          role: { label: `${data?.roles?.[0]?.name}`, value: data?.roles?.[0]?.id },
          email: data?.email,
        });
      }
      setIsLoading(false);
    }
    getData();
  }, [userId, businessId, navigate]);

  return (
    <div className="page-content">
      <div className="container-fluid">
      <GoBackButton to="/users" />
        <Row>
          <Col>
          {isLoading ? (
              <div className="spiner_div">
                <Spinner color="primary" />
              </div>
            ) : (
            <Card>
              <CardBody>
              {roles && roles.length !== 0 && user ? (
                <>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0 flex-grow-1 ">Edit User</h5>
                  <div className="flex-shrink-0">{/* ride justified data */}</div>
                </div>
                <Form
                  id="users-form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  // className="overflow-hidden"
                >
                  <InputElement
                    name="User Name"
                    type="text"
                    identifier="userName"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    errors={formik.errors.userName}
                    touched={formik.touched.userName}
                    onBlur={formik.handleBlur}
                  />
                 
                  <InputElement
                    name="Email Address"
                    type="email"
                    identifier="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    errors={formik.errors.email}
                    touched={formik.touched.email}
                    onBlur={formik.handleBlur}
                  />

                  <Row className="mb-3">
                    <Col lg={5} sm={7} className="mx-auto">
                      <div className="mb-3">
                        <Label className="form-label">Role</Label>
                        <Select
                          name="role"
                          placeholder="Select Role"
                          options={roles}
                          value={formik.values.role}
                          onChange={(value) => formik.setFieldValue("role", value)}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.role && formik.errors.role
                              ? "is-invalid"
                              : ""
                          }
                        />
                        {formik.touched.role && formik.errors.role ? (
                          <FormFeedback type="invalid">{formik.errors.role || formik.errors.role}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  
                  <div className="d-flex flex-wrap gap-2 justify-content-end col-lg-7 col-md-10 mx-auto px-1 mt-1">
                    <Button type="submit" color="success" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                  </div>
                </Form>
                </>
              ) : (
                <p className="text-danger text-center">Failed to fetch data. Please refresh the page or try later.</p>
              )}
              </CardBody>
            </Card>
          )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(EditUser);
