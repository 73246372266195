import { toast } from "react-toastify";
import request from "../../request_helper";
import toasterTime from "config";

export const resendVerificationEmail = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "resendVerificationEmail/" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            method: 'POST'
        };
        const response = await request(args); 
        toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return {success: false}
    }
}

export const verifyAccount = async (userData: any) => {
    try {
        const args = {
            url: "verifyAccount",
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST', 
            body: JSON.stringify(userData)
        };
        const response = await request(args); 
        toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return {success: false, message: error.message}
    }
}