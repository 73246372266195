import React, { useState } from "react";
import { CardBody, CardTitle, Progress } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";
import getDynamicColor from "pages/Project/Home/getDynamicColor";
interface TeamStatsProps {
  teams: { name: string; count: number }[];
}

const TeamStats: React.FC<TeamStatsProps> = ({ teams }) => {
  const [collapseStates, setCollapseStates] = useState(true);

  return (
    <CollapseItem
      title="Teams"
      col={collapseStates}
      toggle={() => setCollapseStates((prevState) => !prevState)}
    >
      <CardBody>
        {teams?.length !== 0 ? <>
        <CardTitle className="h5 font-medium">Team Activity Distribution</CardTitle>
          <p className="card-title-desc">
            This chart represents the distribution of projects among various
            teams.
          </p>
          <div>
            <ul className="list-group list-group-flush">
              {teams?.map((team, index) => {
                const color = getDynamicColor(index);
                return (
                  <li className="list-group-item" key={index}>
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {team.name}
                        <span className="float-end">{Number(team.count)}%</span>
                      </h5>
                      <div className="progress animated-progress progress-sm">
                        <Progress
                          bar
                          color={color}
                          value={team.count}
                          style={{ height: "10px" }}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
        : 
        <CardBody>
          <CardTitle tag="h4">Oops! It seems you're not part of any team yet.</CardTitle>
          <p className="text-muted">Please contact your administrator to be added to a team.</p>
        </CardBody>     
        }
      </CardBody>
    </CollapseItem>
  );
};

export default TeamStats;
