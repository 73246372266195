import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormFeedback, Label, Row, Spinner } from "reactstrap";
import { Button, Form } from "reactstrap";
import InputElement from "Components/Custom/InputElement";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import GoBackButton from "Components/Common/GoBackButton";
import { getRoles } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";
import { createUser } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import withRouter from "Components/Common/withRouter";

function CreateUser({ router }: any) {
  const { navigate, location } = router;
  document.title = "Create User | Content Pro";
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  
  const formik: any = useFormik({
    initialValues: {
      userName: "",
      role: null,
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      userName: yup.string()
      .matches(/^[A-Za-z\s]+$/, 'User Name can only contain letters and spaces')
      .min(3, 'Username must be at least 3 characters')
      .max(20, 'Username cannot exceed 20 characters')
      .required("Please Enter Username"),
      role: yup
        .object()
        .shape({
          label: yup.string().required('Please Select a Role'),
          value: yup.string().required('Please Select a Role'),
        }).nullable().required("Please Select Role"),
      email: yup.string()
      .email("Please enter a valid email address")
      .required("Please Enter Your Email"),
      password: yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least one lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least one uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .required("Please Enter Your Password"),
      confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match').required("Please Confirm Your Password"),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      // console.log("create values");
      // console.log(values);
      const data = {
        name: values.userName,
        role_id: values.role.value,
        email: values.email,
        password: values.password,
        password_confirmation: values.confirmPassword,
        business_id: businessId, 
      };
      const response = await createUser(data, navigate); 
      if(response){setSubmitting(false);}
      if (response.success) {
        formik.resetForm();
        setTimeout(() => {navigate(`/users?businessId=${businessId}`);}, 2000);
      }
      return;
    },
  });

  const [roles, setRoles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const role = await getRoles(businessId, navigate);
      setRoles(role?.map(item => ({ label: `${item.name}`, value: item.id })));
      setIsLoading(false);
    }
    getData();
  }, [businessId, navigate]);

  return (
    <div className="page-content">
      <div className="container-fluid">
      <GoBackButton to="/users" />
        <Row>
          <Col>
          {isLoading ? (
              <div className="spiner_div">
                <Spinner color="primary" />
              </div>
            ) : (
            <Card>
              <CardBody>
                <>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0 flex-grow-1 ">Create User</h5>
                  <div className="flex-shrink-0">{/* ride justified data */}</div>
                </div>
                <Form
                  id="users-form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  className="overflow-hidden"
                >
                  <InputElement
                    name="User Name"
                    type="text"
                    identifier="userName"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    errors={formik.errors.userName}
                    touched={formik.touched.userName}
                    onBlur={formik.handleBlur}
                  />
                  
                  <InputElement
                    name="Email Address"
                    type="email"
                    identifier="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    errors={formik.errors.email}
                    touched={formik.touched.email}
                    onBlur={formik.handleBlur}
                  />

                  <Row className="mb-3">
                    <Col lg={5} sm={7} className="mx-auto">
                      <div className="mb-3">
                        <Label className="form-label">Role</Label>
                        <Select
                          name="role"
                          placeholder="Select Role"
                          options={roles}
                          value={formik.values.role}
                          onChange={(value) => formik.setFieldValue("role", value)}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.role && formik.errors.role
                              ? "is-invalid"
                              : ""
                          }
                        />
                        {formik.touched.role && formik.errors.role ? (
                          <FormFeedback type="invalid">{formik.errors.role || formik.errors.role}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <InputElement
                    name="Password"
                    type="password"
                    identifier="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    errors={formik.errors.password}
                    touched={formik.touched.password}
                    onBlur={formik.handleBlur}
                  />
                  <InputElement
                    name="Confirm Password"
                    type="password"
                    identifier="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    errors={formik.errors.confirmPassword}
                    touched={formik.touched.confirmPassword}
                    onBlur={formik.handleBlur}
                  />

                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-end col-lg-7 col-md-10 mx-auto px-1 mt-1">
                      <Button 
                        type="submit" 
                        color="success" 
                        disabled={formik.isSubmitting} // Disable button during submission
                      >
                        {formik.isSubmitting ? 'Creating...' : 'Create User'}
                      </Button>
                    </div>
                  </Row>
                </Form>
                </>
              </CardBody>
            </Card>
          )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(CreateUser);
