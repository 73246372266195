import React from "react";
import { Container, Card, CardBody, Button, Label, Input, FormFeedback, Form, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { changePassword } from "helpers/API Calls/General/Profile";

function ChangePassword() {

  const passwordValidation = useFormik({
    initialValues: {
      oldPassword: "", 
      password: '',
      confirmPassword: "", 
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Current Password is Required"), 
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(RegExp('(.*[a-z].*)'), 'Password must have at least one lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'Password must have at least one uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'Password must have at least one number')
        .required("Please Enter New Password"),
      confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required("Please Confirm Your Password"),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        old_password: values.oldPassword, 
        new_password: values.password, 
      }
      const response = await changePassword(data); 
      if(response){setSubmitting(false);}
      if (response.success) {passwordValidation.resetForm()}
      return;
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card >
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <h5 className="mb-0 flex-grow-1 ">Change Password</h5>
                <div className="flex-shrink-0">{/* ride justified data */}</div>
              </div>
              <Row>
                <Col lg={5} sm={7} className="mx-auto">
                  <Form id="passwordForm" className="mt-4" onSubmit={(e) => {e.preventDefault(); passwordValidation.handleSubmit(); return false;}}>
                    <div className="form-group">
                        <Label className="form-label">Current Password</Label>
                        <Input
                        name="oldPassword"
                        className="form-control"
                        placeholder="Enter Previous Password"
                        type="password"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.oldPassword || ""}
                        invalid={passwordValidation.touched.oldPassword && !!passwordValidation.errors.oldPassword}
                        />
                        {passwordValidation.touched.oldPassword && passwordValidation.errors.oldPassword ? (
                        <FormFeedback type="invalid">
                            {typeof passwordValidation.errors.oldPassword === 'string' && passwordValidation.errors.oldPassword}
                        </FormFeedback>
                        ) : null}
                    </div>

                    <div className="form-group mt-4">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter New Password"
                          type="password"
                          onChange={passwordValidation.handleChange}
                          onBlur={passwordValidation.handleBlur}
                          value={passwordValidation.values.password || ""}
                          invalid={passwordValidation.touched.password && !!passwordValidation.errors.password}
                        />
                        {passwordValidation.touched.password && passwordValidation.errors.password ? (
                        <FormFeedback type="invalid">
                            {typeof passwordValidation.errors.password === 'string' && passwordValidation.errors.password}
                        </FormFeedback>
                        ) : null}
                    </div>

                    <div className="form-group mt-4">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                        name= "confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        type= "password"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.confirmPassword || ""}
                        invalid={passwordValidation.touched.confirmPassword && !!passwordValidation.errors.confirmPassword}
                        />
                        {passwordValidation.touched.confirmPassword && passwordValidation.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                            {typeof passwordValidation.errors.confirmPassword === 'string' && passwordValidation.errors.confirmPassword}
                        </FormFeedback>
                        ) : null}
                    </div>

                    <div className="text-end mt-4">
                    <Button type="submit" color="success" disabled={passwordValidation.isSubmitting}>{passwordValidation.isSubmitting ? 'Saving...' : 'Save Changes'}</Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword