import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row} from "reactstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getTeams } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import { getRoles } from "helpers/API Calls/Space Settings/Users Settings/Roles/Roles";
import PaginatedTable from "Components/Custom/Table and Cards/PaginatedTable";
import { getUsers } from "slices/thunk";
import convertDateFormat from "Components/Custom/DateFormat";
import withRouter from "Components/Common/withRouter";

const head = [
  { id: 1, name: "#", isSortable: true, key: "id" },
  { id: 2, name: "User", isSortable: true, key: "name"},
  { id: 3, name: "User Type", isSortable: true, key: "user_type"},
  { id: 4, name: "Role", isSortable: true, key: "role"},
  { id: 8, name: "Team", isSortable: true, key: "team_id"},
  { id: 5, name: "Created", isSortable: true, key: "created_at"},
  { id: 6, name: "Updated", isSortable: true, key: "updated_at"},
];
// redux code
const selectProperties = createSelector(
  (state: any) => state.users,
  (users) => ({
    users: users.usersList,
    loading: users.loading,
    pagination: users.pagination
  })
);

function Members({ router }: any) {
  const { navigate, location } = router;

  document.title = "Members | Content Pro";
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  let projectId = searchParams.get('projectId');

  const { users, loading, pagination } = useSelector(selectProperties);

  // local state mng for getting business data
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(loading);
    setUsersData(users);
  }, [users, loading]);

  const [filterData, setFilterData] = useState<any>({roles: [], teams: []}); 
  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {

      const roles = await getRoles(businessId, navigate);
      const teams = await getTeams(businessId, "", navigate); 
      setFilterData({
        teams: teams?.map(item => ({ label: item.team_name, value: item.id })) || [],
        roles: roles?.map(item => ({ label: `${item.name}`, value: item.id })) || []
      });
      setIsLoading(false);
    }
    getData();
  }, [businessId, navigate]);
  
  return (
   <>

    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Card className="spiner_div_activities">
              <PaginatedTable
                tableHead={head}
                onGetData = {getUsers}
                pagination = {pagination}
                isLoading = {isLoading}
                isAddButton = {false} 
                query = {`business_id=${businessId}&project_id=${projectId}`}
                title = 'Members List'
                hasFilter={true}
                businessHead=""
                filterData= {[
                  { label: 'Roles',key: 'role_id',options: filterData.roles}, 
                  { label: 'Teams',key: 'team_id',options: filterData.teams}, 
                ]}
               
              >
                {!isLoading && usersData && usersData?.length !== 0 && usersData.map((item : any, index : any) => (
                  <tr key={item.id}>

                    <th scope="row">{item.id}</th>
                    <td className="d-flex">
                      <div className="flex-shrink-0 me-2 my-auto">
                        {item?.image ? 
                          <img src={item?.image} alt="" className="avatar-xs rounded-circle"/>
                          :
                          <div className="avatar-xs">
                            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                              {item.name?.charAt(0)}
                            </div>
                          </div>
                        }
                        </div>
                        <div>
                        <h5 className='font-size-14 mb-1'>
                          {item.name}
                        </h5>
                        <p className="text-muted mb-0">{item.email}</p>
                      </div>
                    </td>
                    <td>{item.user_type}</td>
                    <td>{item?.roles?.[0]?.name}</td>
                    <td>{item.teams.map(item => <span className="badge badge-soft-info font-size-11 m-1" key={item.id}>{item.team_name}</span>) || 'No Assigned'}</td>
                    <td>{convertDateFormat(item.created_at)}</td>
                    <td>{convertDateFormat(item.updated_at)}</td>
                  </tr>
                ))}
                {!isLoading && usersData && usersData?.length === 0 && <tr className="text-center"><td colSpan={head.length} className="text-danger">No Data Found</td></tr>}
              </PaginatedTable>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
   </>
  );
}

export default withRouter(Members);
