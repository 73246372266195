import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, FormFeedback, Label, Row, Spinner } from "reactstrap";
import { Button, Form } from "reactstrap";
import InputElement from "Components/Custom/InputElement";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { getUsers } from "helpers/API Calls/Space Settings/Users Settings/Users/users";
import { getProjects } from "helpers/API Calls/Space Settings/Projects/projects";
import { editTeam, getTeamDetail } from "helpers/API Calls/Space Settings/Users Settings/Teams/Teams";
import GoBackButton from "Components/Common/GoBackButton";
import withRouter from "Components/Common/withRouter";


function EditTeam({ router }: any) {
  const { navigate, location, params } = router;

  document.title = "Edit Team | Content Pro";

  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const { id } = params;

  const formik: any = useFormik({
    initialValues: {
      teamName: "",
      project: [],
      users: []      
    },
    validationSchema: yup.object().shape({
      teamName: yup.string()
      .matches(/^[A-Za-z0-9\s_-]+$/,'Team Name can only contain letters, numbers, spaces, hyphens, and underscores')
      .min(3, 'Team Name must be at least 3 characters')
      .max(20, 'Team Name cannot exceed 20 characters')
      .required("Please Enter Team Name"),
      project: yup.array()
      .min(1, "Please Pick at least 1 Project")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
      ),
      users: yup.array()
      .min(1, "Pick at least 1 member")
      .of(
        yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required()
        })
      )
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        team_name: values.teamName,
        projects: values.project.map(item => {return {project_id: item.value}}),
        members: values.users.map(item => {return {user_id: item.value}})
      };

      const response = await editTeam(data, id, navigate); 
      if (response) {
        setSubmitting(false);
      }
      if (response?.success) {
        setTimeout(() => { navigate(`/teams?businessId=${businessId}`); }, 2000);
      }
      return;
    },
  });

  const [projects, setProjects] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [team, setTeam] = useState<any>({});

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
  
      // Get all users
      const userData = await getUsers(businessId, navigate);
      const usersOptions = userData?.map(item => ({
        label: `${item.name}, ${item.email}. (${item?.role || item?.user_type})`,
        value: item.id,
      })) || [];
      setUsers(usersOptions);
  
      // Get all projects
      const projectData = await getProjects(businessId, navigate);
      const projectsOptions = projectData?.map(item => ({
        label: `${item.project_name}, ${item.project_key}. (${item.project_type})`,
        value: item.id,
      })) || [];
      setProjects(projectsOptions);
  
      // Get current team data
      const teamData = await getTeamDetail(id, navigate);
      setTeam(teamData);
  
      setIsLoading(false);
    };
    
    getData();
  }, [businessId, id, navigate]);
  
  useEffect(() => {
    if (team && Array.isArray(team.members) && projects.length > 0 && users.length > 0) {
      formik.setValues({
        teamName: team.team_name,
        // project: projects.find(item => item.value === team.project_id) || null,
        project: team.projects
          .map(item => projects.find(proj => proj.value === item.id))
          .filter(proj => proj !== undefined), 
        users: team.members
          .map(member => users.find(user => user.value === member.id))
          .filter(user => user !== undefined),
      });
    }
  }, [team, projects, users]);
  
  

  return (
    <div className="page-content">
      <div className="container-fluid">
      <GoBackButton to="/teams" />
        <Row>
          <Col>
          {isLoading ? (
              <div className="spiner_div">
                <Spinner color="primary" />
              </div>
            ) : (
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0 flex-grow-1 ">Edit Team</h5>
                  <div className="flex-shrink-0">{/* ride justified data */}</div>
                </div>
                <Form
                  id="edit-team-form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <InputElement
                    name="Team Name"
                    type="text"
                    identifier="teamName"
                    value={formik.values.teamName}
                    onChange={formik.handleChange}
                    errors={formik.errors.teamName}
                    touched={formik.touched.teamName}
                    onBlur={formik.handleBlur}
                  />
                  <Row className="mb-3">
                    <Col lg={5} sm={7} className="mx-auto">
                      <Label htmlFor="users">Members</Label>
                      <Select
                        id="multiSelect"
                        name="users"
                        isMulti
                        options={users}
                        onChange={(selectedOption) => formik.setFieldValue("users", selectedOption)}
                        onBlur={formik.handleBlur}
                        value={formik.values.users}
                      />
                      {formik.touched.users && formik.errors.users ? (
                        <FormFeedback type="invalid" className="d-block">
                          {formik.errors.users}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col lg={5} sm={7} className="mx-auto">
                      <Label htmlFor="project">Project</Label>
                      <Select
                        id="multiSelect"
                        name="project"
                        className="border border-1 border light"
                        isMulti
                        options={projects}
                        onChange={(selectedOption) => formik.setFieldValue("project", selectedOption)}
                        onBlur={formik.handleBlur}
                        value={formik.values.project}
                      />
                      {formik.touched.project && formik.errors.project ? (
                        <FormFeedback type="invalid" className="d-block">
                          {formik.errors.project}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <div className="d-flex flex-wrap gap-2 justify-content-end col-lg-7 col-md-10 mx-auto px-1 mt-1">
                    <Button 
                      type="submit" 
                      color="success" 
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                  </div>

                </Form>
              </CardBody>
            </Card>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withRouter(EditTeam);
