import React, { useCallback, useEffect, useState } from "react";
import withRouter from "Components/Common/withRouter";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { createIntegration, getLinkedInProfiles } from "helpers/API Calls/Project/Settings/Integrations";

  
  const ConnectLinkedIn = (props: any) => {

    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    const project_id = localStorage.getItem("project_id");
    const integration_type_id = localStorage.getItem("integration_type_id");
    const businessId = localStorage.getItem("businessId");

    const [profiles, setProfiles] = useState<any>([]);
    const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const redirect = useCallback(() => {
        localStorage.removeItem("project_id");
        localStorage.removeItem("integration_type_id");
        localStorage.removeItem("businessId");
        setTimeout(() => navigate(`/integrations?projectId=${project_id}&businessId=${businessId}`), 5000);
    }, [businessId, project_id, navigate])
      
    useEffect(() => {
        setLoading(true); 
        const getProfiles = async () => {
            const data = await getLinkedInProfiles(code); 
            if(data){setLoading(false);}
            if(data?.success){setProfiles(data?.data)}
            // else{redirect()}
        }
        getProfiles();
    }, [code, redirect]);

    const connect = async () => {  
        const reqData = {
            project_id: project_id, 
            integration_type_id: integration_type_id,
            companies: profiles.filter(item => selectedProfiles.includes(item?.company_id))
        }
        const data = await createIntegration(reqData);
        if(data) redirect();
    }

    const selectCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        setSelectedProfiles((prev) =>
            checked ? [...prev, id] : prev.filter((item) => item !== id)
        );
    }

    return (
        <div className="bg-light">
        <Container fluid className="d-flex align-items-center justify-content-center vh-100">
          <Row className="justify-content-center">
            <Col>
                {loading ? (
                    <div className="spiner_div">
                    <Spinner color="primary" />
                    <p className="mt-3 w-auto mx-auto">Connecting...</p>

                    </div>
                ) : (
                <Card>
                    <CardBody>
                    <div className="text-center">
                        <h4 className="mt-4 fw-semibold">Select Pages to Connect</h4>
                        <p className="text-muted mt-3 mb-4">Choose the LinkedIn pages you want to connect to your project.</p>
                        {profiles.length > 0 ?
                        <form onSubmit={e => e.preventDefault()}>
                            {profiles.map(item => (
                            <div className="form-check mb-2" key={item?.company_id}>
                                <input
                                    className="form-check-input me-3"
                                    type="checkbox"
                                    id={item?.company_id}
                                    checked={selectedProfiles.includes(item?.company_id)}
                                    onChange={selectCompanies}
                                />
                                <label className="form-check-label" htmlFor={item?.company_id}>
                                {`${item?.company_name} - ${item?.company_id}`}
                                </label>
                            </div>
                            ))}
                            <Button color="success" onClick={connect} disabled={selectedProfiles.length === 0} className="mt-4 w-100">{loading ? 'Connecting...' : 'Select and Connect'}</Button>
                        </form>
                        :
                        <>
                        <p className="text-danger text-center mx-auto d-block">
                            No LinkedIn pages found that are connected to your account. Please add a page to proceed or check your LinkedIn account settings to ensure you have pages available to connect.
                        </p>
                        <Button color="success" onClick={redirect} className="mt-4 w-auto mx-auto d-block">Go Back</Button>
                        </>
                        }
                    </div>
                    </CardBody>
                </Card>
                )}
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default withRouter(ConnectLinkedIn);
