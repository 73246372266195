const PROJECT = [
  {key: 101, name: "Home", icon: "bx-home-circle", link: "/project"},
  {key: 102, name: "Activity", icon: "bx-store", link: null, children: [
    {key: 10201, name: "Activity List", link: "/posts-list"},
    {key: 10202, name: "Create New", link: "/posts-create"},
  ]},
  {key: 105, name: "Project Settings", icon: "bx-briefcase-alt-2", link: null, children: [
    {key: 10501, name: "General", link: "/general"},
    {key: 10502, name: "Members", link: "/members"},
    {key: 10504, name: "Activity Types", link: "/activity-type"},
    {key: 10505, name: "States", link: "/state"},
    {key: 10702, name: "Role States", link: "/role-state"},
    {key: 10507, name: "Integrations", link: "/integrations"},
  ]},
  // {key: 103, name: "Reports", icon: "bx-bar-chart-alt-2", link: "/reports"},
  {key: 104, name: "Files", icon: "bx-file", link: "/files"},
  // {key: 10506, name: "Help", icon: "bxs-help-circle", link: "/help"},
];

const SPACE_SETTINGS = [
  {key: 201, name: "General Settings", icon: "bx-store", link: null, children: [
    {key: 20101, name: "Space Settings", link: "/space"},
    {key: 20102, name: "Time Zone", link: "/time"},
  ]},
  {key: 203, name: "Project Settings", icon: "bx-briefcase-alt-2", link: null, children: [
    {key: 20201, name: "Project List", link: "/projects"},
    {key: 20202, name: "Add New", link: "/projects/create"},
  ]},
  {key: 30299, name: "User Settings", icon: "bx-group", link: null, children: [
    {key: 20303, name: "Roles", link: "/roles"},
    {key: 2001, name: "Users", link: "/users"},
    {key: 20302, name: "Teams", link: "/teams"},
  ]},
  {key: 204, name: "Subscriptions", icon: "bx-log-in-circle", link: null, children: [
    {key: 201, name: "Payments", link: "/payments"},
  ]},
  // {key: 205, name: "Help", icon: "bxs-help-circle", link: "/space-help"},
];

const SUPER_ADMIN = [
  {key: 1, name: "Dashboard", icon: "bx-home-circle", link: "/admin"},
  {key: 10, name: "Businesses", icon: "bx-store", link: "/admin/businesses"},
  {key: 345, name: "Billing", icon: "bx-dollar-circle", link: null, children: [
    {key: 109, name: "Plans", link: "/admin/plans"},
    {key: 2, name: "Subscriptions", link: "/admin/subscriptions"},
  ]},
  {key: 4, name: "General Settings", icon: "bx-cog", link: "/admin/settings"},
];

const PROFILE = [
  {key: 1, name: "Profile", icon: "bx-user", link: "/profile"},
  {key: 10, name: "Change Password", icon: "mdi mdi-key-variant", link: "/change-password"},
  // {key: 345, name: "Billing", icon: "bx-dollar-circle", link: null, children: [
  //   {key: 109, name: "Plans", link: "/admin/plans"},
  //   {key: 2, name: "Subscriptions", link: "/admin/subscriptions"},
  // ]},
  // {key: 4, name: "General Settings", icon: "bx-cog", link: "/admin/settings"},
];

export { SPACE_SETTINGS, SUPER_ADMIN, PROFILE };
export default PROJECT;

