import { toast } from "react-toastify";
import request from "../../../request_helper";
import toasterTime from "config";

export const uploadFile = async (data) => {
    let formData = new FormData();
    if (data.file_type) {
        formData.append('file_type', data.file_type);
    }
    if (data.activity_id) {
        formData.append('activity_id', data.activity_id);
    }
    if (data.project_id) {
        formData.append('project_id', data.project_id);
    }
    if (data.file) {
        formData.append('file', data.file);
    }
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "files",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body : formData,
        };
        const response = await request(args); 
        // await response;
        // await response;
        // console.log('from api call' , data)
        toast.success(response.message, { autoClose: toasterTime});
        return response; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return {success: false}
    }
}

export const deleteFile = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "files/" + id,
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        toast.success(response.message, { autoClose: toasterTime});
        await response;
        return true; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return false; 
    }
}

export const editFileTags = async (reqData, id) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `files/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            method: 'PUT',
            body : JSON.stringify(reqData), 
        };
        const response = await request(args); 
        toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return {success: false, message: error.message}
    }
}