import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import Logo from "./UI/Logo";
import { forgetPassword } from "helpers/API Calls/General/forget-password";

const ForgetPasswordPage = (props) => {

    //meta title
    document.title = "Forget Password | Content Pro";
    const [reqResponse, setReqResponse] = useState<any>(null); 

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please Enter Your Email"),
        }),
        onSubmit: async (values: any, { setSubmitting }) => {
            const data = {
              email: values.email, 
            }
            const response = await forgetPassword(data); 
            if(response){setReqResponse(response); setSubmitting(false);}
            if (response.success) {validation.resetForm()}
            return;
          }
    });


    return (
        <React.Fragment>
            <Logo/>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                            <div className="py-3">
                            <h2 className="px-4 pt-4 text-dark"><strong>Reset Password</strong></h2>
                            </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                    {reqResponse && (
                                        <Alert
                                            color={reqResponse.success ? "success" : "danger"}
                                            style={{ marginTop: "13px" }}
                                        >
                                            {reqResponse.message}
                                        </Alert>
                                    )}

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={validation.touched.email && !!validation.errors.email}
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">{typeof validation.errors.email === 'string' && validation.errors.email}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-end d-grid">
                                                    <button
                                                        className="btn btn-success w-md "
                                                        type="submit"
                                                        disabled={validation.isSubmitting}
                                                    >
                                                        {validation.isSubmitting ? 'Sending...' : 'Send Reset Email'}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div className="text-center mt-5">
                                            <p><strong>Go back to <Link to="/login" className="font-weight-medium text-success ms-1">Login</Link></strong></p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ForgetPasswordPage);