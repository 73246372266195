import { LinkWithQuery } from "helpers/link_helper";
import React from "react";
import { Row, Col } from "reactstrap";

const GoBackButton = (props: any) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center back-btn ml-3 mt-3">
            <LinkWithQuery project = {props.project || false} to={props.to} admin={props.admin} queries={props.queries} className="btn btn-success waves-effect waves-light my-1">
              Back
            </LinkWithQuery>
        </div>
      </Col>
    </Row>
  );
};

export default GoBackButton;
