// general Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import AccessDenied from "pages/Utility/pages-access-denied";

// role navigation + email verification
import RoleNavigation from "./RoleNavigation";
import VerifyEmail from "Components/VerifyEmail";

// Auth
import Login from "../pages/Authentication/login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import SignUp from "../pages/Authentication/Register";

// Profile
import UserProfile from "../pages/Profile/user-profile";
import ChangePassword from "pages/Profile/ChangePassword";

// super admin 
import SuperAdminDashboard from 'pages/SuperAdmin/Dashboard'
import BusinessList from "pages/SuperAdmin/Businesses/Business-list";
import EditBusiness from "pages/SuperAdmin/Businesses/Business-edit";
import BusinessDetails from "pages/SuperAdmin/Businesses/Business-details";
import Plans from "pages/SuperAdmin/Billing/Plans";
import Plans2 from "pages/SuperAdmin/Billing/Plans2";
import Subscriptions from "pages/SuperAdmin/Billing/Subscriptions";
import SubscriptionDetails from "pages/SuperAdmin/Billing/SubscriptionDetails";
import General from "pages/SuperAdmin/Settings/General";

// landing page for business owner and all users 
import Dashboard from "pages/Dashboard/Dashboard";

// dashboard
import Home from "../pages/Project/Home/Home";

// space settings 
import Settings from "pages/Space Settings/General/space-general";
import Time from "pages/Space Settings/General/space-timezone";
import ProjectList from "pages/Space Settings/Project Settings/project-list";
import Create from "pages/Space Settings/Project Settings/project-create";
import ProjectDetails from "pages/Space Settings/Project Settings/project-details";
import Users from "pages/Space Settings/User Settings/Users/space-users";
import CreateUser from "pages/Space Settings/User Settings/Users/space-users-create";
import EditUser from "pages/Space Settings/User Settings/Users/space-user-edit";
import Roles from "pages/Space Settings/User Settings/Roles/space-roles";
import RoleForm from "pages/Space Settings/User Settings/Roles/space-role-create";
import EditRoleForm from "pages/Space Settings/User Settings/Roles/space-role-edit";
import Teams from "pages/Space Settings/User Settings/Teams/space-teams";
import CreateTeam from "pages/Space Settings/User Settings/Teams/space-team-create";
import EditTeam from "pages/Space Settings/User Settings/Teams/space-team-edit";
import TeamDetails from "pages/Space Settings/User Settings/Teams/space-team-details";
import Payments from "pages/Space Settings/Subscriptions/payments";
import StripePricingTable from "pages/Space Settings/Subscriptions/StripePricingTable";
import SpaceHelp from "pages/Space Settings/Help";

// activities
import ActivityContent from "pages/Project/Activity/activity-content-edit";
import ActivityCreate from "pages/Project/Activity/activity-create";
import ActivityList from "pages/Project/Activity/activity-list";
import ActivityOverview from "pages/Project/Activity/activity-details";
import ActivityPublish from "pages/Project/Activity/activity-publish";
import Logs from "pages/Project/Activity/activity-logs";
import History from "pages/Project/Activity/activity-history";
import EditSocialActivity from "pages/Project/Activity/activity-publish-details";
import ActivityEdit from "pages/Project/Activity/activity-edit";
import ActivityKanban from "pages/Project/Activity/activity-kanban";

// Project Settings 
import GeneralSettings from "pages/Project/Settings/project-general";
import Members from "pages/Project/Settings/project-members";
import ActivityType from "pages/Project/Settings/project-activity-type";
import Status from "pages/Project/Settings/project-state";
import RoleState from "pages/Project/Settings/project-role-states";
import Integrations from "pages/Project/Settings/project-integrations";
import Reports from "pages/Project/Reports/project-report";
import Files from "pages/Project/Files/project-files";
import Help from "pages/Project/Help/project-help";
// integrations
import ConnectFacebook from 'pages/Project/Settings/Integrations/Connections Callbacks/Facebook'
import ConnectTwitter from 'pages/Project/Settings/Integrations/Connections Callbacks/Twitter'
import ConnectLinkedIn from 'pages/Project/Settings/Integrations/Connections Callbacks/LinkedIn'
import ResetPassword from "pages/Authentication/ResetPassword";

const authProtectedRoutes = [
  // ------------ role navigation --------------- // 
  { path: "/", exact: true, component: <RoleNavigation/> },

  // ------------ Super Admin --------------- //
  { path: "/admin", component: <SuperAdminDashboard/> , sideBar: "admin"},
  // Super Admin Businesses
  { path: "/admin/businesses", component: <BusinessList/> , sideBar: "admin"},
  { path: "/admin/businesses/:businessId?", component: <BusinessDetails/> , sideBar: "admin"},
  { path: "/admin/businesses/:businessId?/edit", component: <EditBusiness/> , sideBar: "admin"},
  { path: "/admin/businesses/create", component: <SignUp/>},
  // settings
  { path: "/admin/settings", component: <General/> , sideBar: "admin"},
  // Billings
  { path: "/admin/plans", component: <Plans/> , sideBar: "admin"},
  { path: "/admin/plans2", component: <Plans2/> , sideBar: "admin"},
  { path: "/admin/subscriptions", component: <Subscriptions/> , sideBar: "admin"},
  { path: "/admin/subscriptions/:businessId?", component: <SubscriptionDetails/> , sideBar: "admin"},

  
  // --------- Dashboard ------------- //
  { path: "/dashboard", component: <Dashboard />, sideBar: "dashboard", hasNoSideBar: true },

  // --------- Project ------------- //
  { path: "/project", component: <Home />, sideBar: "project" },

  // Activities
  { path: "/posts-list", component: <ActivityList />, sideBar: "project", },
  { path: "/posts-kanban", component: <ActivityKanban />, sideBar: "project", },
  { path: "/posts-create", component: <ActivityCreate />, sideBar: "project", routePermissions: ["create_post"] },
  { path: "/posts-details/:id?", component: <ActivityOverview />, sideBar: "project",},
  { path: "/posts-details/:id?/edit", component: <ActivityEdit />, sideBar: "project"}, //allowed to business owner only
  { path: "/posts-details/:id?/content-edit", component: <ActivityContent />, sideBar: "project", routePermissions: ["edit_post"] },
  { path: "/posts-details/:id?/publish", component: <ActivityPublish />, sideBar: "project", routePermissions: ["publish_post"] },
  { path: "/posts-details/:id?/publish/:platform?", component: <EditSocialActivity />, sideBar: "project", routePermissions: ["publish_post"] },
  { path: "/posts-details/:id?/logs", component: <Logs />, sideBar: "project", routePermissions: ["view_version_logs"] },
  { path: "/posts-details/:id?/history", component: <History />, sideBar: "project", routePermissions: ["view_activity_logs"] },

  // Project settings
  { path: "/general", component: <GeneralSettings />, sideBar: "project", routePermissions: ["update_project_settings"] },
  { path: "/members", component: <Members />, sideBar: "project", routePermissions: ["update_project_settings"]},
  { path: "/role-state", component: <RoleState />, sideBar: "project", routePermissions: ["update_project_settings"] },
  { path: "/activity-type", component: <ActivityType />, sideBar: "project", routePermissions: ["add_activity_type", "edit_activity_type", "delete_activity_type"] },
  { path: "/state", component: <Status />, sideBar: "project", routePermissions: ["add_activity_state", "edit_activity_state", "delete_activity_state"] },
  { path: "/integrations", component: <Integrations />, sideBar: "project", routePermissions: ["connect_apps"] },

  // Reports
  { path: "/reports", component: <Reports />, sideBar: "project", routePermissions: ["manage_reports"] },

  // Files
  { path: "/files", component: <Files />, sideBar: "project", routePermissions: ["upload_files", "download_files"] },

  // Help
  { path: "/help", component: <Help />, sideBar: "project" },



  // ---------- space settings  ----------- // 
  // general
  { path: "/space", component: <Settings/> , sideBar: "space-settings"},
  { path: "/time", component: <Time/> , sideBar: "space-settings"},
  // roles
  { path: "/roles", component: <Roles/> , sideBar: "space-settings"},
  { path: "/roles/create", component: <RoleForm/> , sideBar: "space-settings"},
  { path: "/roles/:id?", component: <EditRoleForm/> , sideBar: "space-settings"},
  // users
  { path: "/users", component: <Users/> , sideBar: "space-settings"},
  { path: "/users/create", component: <CreateUser/> , sideBar: "space-settings"},
  { path: "/users/:id?/edit", component: <EditUser/> , sideBar: "space-settings"},
  // { path: "/users/:id?", component: <EditUser/> , sideBar: "space-settings"},
  // teams
  { path: "/teams", component: <Teams/> , sideBar: "space-settings"},
  { path: "/teams/create", component: <CreateTeam/> , sideBar: "space-settings"},
  { path: "/teams/:id?", component: <TeamDetails/> , sideBar: "space-settings"},
  { path: "/teams/:id/edit?", component: <EditTeam/> , sideBar: "space-settings"},

  // projects
  { path: "/projects", component: <ProjectList/> , sideBar: "space-settings"},
  { path: "/projects/:id?", component: <ProjectDetails/> , sideBar: "space-settings"},
  { path: "/projects/create", component: <Create/> , sideBar: "space-settings"},
  // subscription
  { path: "/payments", component: <Payments/> , sideBar: "space-settings"},
  { path: "/pricing-tables", component: <StripePricingTable/> , sideBar: "space-settings"},
  // help
  { path: "/space-help", component: <SpaceHelp/> , sideBar: "space-settings"},

  // general pages 
  { path: "/profile", component: <UserProfile/>, sideBar: "profile" },
  { path: "/change-password", component: <ChangePassword />, sideBar: "profile"},

];

const publicRoutes = [
  {path: '/verifyAccount', component : <VerifyEmail/>},
  {path: '/auth/facebook/callback', component : <ConnectFacebook/>},
  {path: '/auth/linkedin/callback', component : <ConnectLinkedIn/>},
  {path: '/auth/twitter/callback', component : <ConnectTwitter/>},
  
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <SignUp />}, 
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/resetPassword", component: <ResetPassword />}, 

  { path: "/access-denied", component: <AccessDenied /> },
  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/*", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
 
];

export { authProtectedRoutes, publicRoutes};
