// function that interprets the date according to local time zone 
const convertDateFormat = (dateString: string) => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }; // Specify type for TypeScript
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export default convertDateFormat;

// function converts the date with respect to utc time zone 
// const convertDateFormat = (dateString: string) => {
//   if (!dateString) {
//     return;
//   }
//   const date = new Date(dateString);

//   // Extract year, month, and day in UTC
//   const year = date.getUTCFullYear();
//   const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
//   const day = date.getUTCDate();

//   // Format the date as needed (e.g., "Oct 2, 2024")
//   return `${month} ${day}, ${year}`;
// };

// export default convertDateFormat;
