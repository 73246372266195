
import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../helpers/request_helper";
import { toast } from "react-toastify";
import toasterTime from "config";

export const getActivity = createAsyncThunk("activity/getAll", async (query: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `activities?${query.query}&limit=${query.limit}&page=${query.page}&keyword=${query.keyword}&sort_column=${query.sort_column}&sort=${query.sort}&${query.filters}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        throw error; // Throw the error to be caught by the rejected case in the slice
    }
});
