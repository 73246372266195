import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Form, FormGroup, Label, Input } from "reactstrap";

function EditModal({ account, socialPlatforms, onSubmit, onClose, isSubmitting }) {
    const [formValues, setFormValues] = useState({});

  const platform = socialPlatforms.find(
    (platform) => platform.id === account?.integration_type_id
  );

  useEffect(() => {
    if (account && platform) {
      const initialFormValues = {};
      platform.integration_type_fields.forEach((field) => {
        initialFormValues[field.mapping_column] = account[field.mapping_column] || '';
      });
      setFormValues(initialFormValues);
    }
  }, [account, platform]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formValues, integration_type_id: platform.id });
  };


  return (
    <Modal isOpen={!!account} toggle={onClose} centered={true} backdrop={"static"}>
        <div className="modal-content">
        <ModalBody className="px-4 pt-5">
            <button type="button" onClick={onClose} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 text-center font-size-20 text-primary">Connect {platform?.type_name} Account</div>
            {platform && (
            <Form onSubmit={handleSubmit}>
                {platform.integration_type_fields.map((field: any) => (
                <FormGroup key={field.id}>
                    <Label for={field.mapping_column}>{field.field_name}</Label>
                    <Input
                    type={field.field_datatype === 'string' ? 'text' : field.field_datatype}
                    id={field.mapping_column}
                    name={field.mapping_column}
                    value={formValues[field.mapping_column] || ''}
                    onChange={handleInputChange}
                    required
                    max={35}
                    />
                </FormGroup>
                ))}
                <div className="hstack gap-2 mb-0 justify-content-end">
                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Save Changes"}
                </button>
                </div>
            </Form>
            )}
        </ModalBody>
        </div>
    </Modal>
  )
}

export default EditModal