import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

interface ActivityStatisticsProps {
  activityDistribution: { name: string; count: number }[]; // Pie chart data
  activityStates: { name: string; count: number; color: string }[]; // Bar chart data
}

// Helper function to get a default color based on activity name
const getActivityColor = (activityName: string): string => {
  switch (activityName) {
    case "New":
      return "#007bff"; // Blue
    case "In Progress":
      return "#ffc107"; // Yellow
    case "Completed":
      return "#28a745"; // Green
    default:
      return "#6c757d"; // Default Gray
  }
};

const ActivityStatistics: React.FC<ActivityStatisticsProps> = ({
  activityDistribution,
  activityStates,
}) => {
  // Ensure unique categories for the bar chart
  const uniqueCategories = Array.from(
    new Set(activityStates.map((state) => state.name))
  );

  const processedActivityStates = activityStates.map((state) => ({
    name: state.name.trim(),
    count: parseFloat(state.count.toString()) || 0,
    color: state.color || getActivityColor(state.name),
  }));

  // Bar chart options for activity states
  const barChartOptions = {
    chart: {
      type: "bar" as const,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        barHeight: "60%",
        distributed: true, // Enables different colors for each bar
      },
    },
    xaxis: {
      categories: uniqueCategories,
      labels: {
        formatter: (value: string) =>
          value.length > 10 ? `${value.slice(0, 10)}...` : value, // Truncate long labels
      },
      tooltip: {
        enabled: true,
      },
    },
    colors: processedActivityStates.map((state) => state.color),
  };

  const barChartSeries = [
    {
      name: "Activity States",
      data: processedActivityStates.map((state) => state.count),
    },
  ];

  // Pie chart options for overall distribution
  const pieChartOptions = {
    chart: {
      type: "pie" as const,
    },
    labels: activityDistribution.map((activity) => activity.name),
    legend: {
      position: "bottom" as const,
    },
    colors: activityDistribution.map((activity) =>
      getActivityColor(activity.name)
    ),
  };

  const pieChartSeries = activityDistribution.map((activity) => activity.count);

  return (
    <Card className="shadow-sm border-0 dashboard-activity-bg">
      <CardBody>
        <h5 className="card-title mb-4">Activity Overview</h5>
        <Row>
          {/* Bar Chart for Activity States */}
          <Col md={6}>
            <h6 className="text-center card-title mb-0">Activity States</h6>
            <ReactApexChart
              options={barChartOptions}
              series={barChartSeries}
              type="bar"
              height={300}
            />
          </Col>
          {/* Pie Chart for Overall Distribution */}
          <Col md={6}>
            <h6 className="text-center card-title mb-0">
              Overall Distribution
            </h6>
            <ReactApexChart
              options={pieChartOptions}
              series={pieChartSeries}
              type="pie"
              height={300}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ActivityStatistics;
