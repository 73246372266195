import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Spinner, Modal, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import SocialIcon from "../../Activity/UI/SocialIcon";
import { createIntegration, getIntegrationTypes, getTwitterUrl } from 'helpers/API Calls/Project/Settings/Integrations';
import { useLocation } from 'react-router-dom';

const IntegrationCards = ({onAccountAdded, setSocialPlatforms, socialPlatforms}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const businessId = searchParams.get("businessId");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedPlatform, setSelectedPlatform] = useState<any | null>(null);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await getIntegrationTypes();
      if (response) {
        setSocialPlatforms(response);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setSocialPlatforms]);

  // Toggles the modal
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Handle button click
  const handleConnectClick = async (platform: any) => {
    if (platform?.connect_url !== '') {
      let url = platform?.connect_url; 
      if(platform?.connect_url.startsWith('/')){
        const response = await getTwitterUrl(); 
        if(!response?.success){return}
        else{url = response.data}
      }
      localStorage.setItem("project_id", `${projectId}`);
      localStorage.setItem("integration_type_id", `${platform.id}`);
      localStorage.setItem("businessId", `${businessId}`);
      window.location.href = url;
    } else {
      // Initialize form values with empty strings when opening the modal
      const initialFormValues = platform.integration_type_fields.reduce((acc: any, field: any) => {
        acc[field.mapping_column] = '';
        return acc;
      }, {});
      setFormValues(initialFormValues);
      setSelectedPlatform(platform);
      toggleModal();
    }
  };

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    const data = {
      ...formValues, 
      integration_type_id: selectedPlatform.id,
      project_id: projectId,
    }
    const response = await createIntegration(data)
    if(response?.success){
      onAccountAdded()
    }
    setIsSubmitting(false);
    toggleModal();
  };

  return (
    <>
      <Card className="p-3">
        <CardBody>
          <div className="align-items-center mb-4">
            <h5 className="">Connect a new account</h5>
            <p className="card-title-desc">Add more social accounts to increase your online performance.</p>
          </div>
          {isLoading ? (
            <div className="spiner_div">
              <Spinner color="primary" />
            </div>
          ) : (
            <Row>
              {socialPlatforms.map((item) => (
                <Col md={6} lg={3} key={item?.id} className="my-3">
                  <Card className="bg-light h-100">
                    <CardBody className="d-flex flex-column align-items-center pb-0">
                      <SocialIcon title={item?.type_name} slug={item?.type_slug} />
                      <Button
                        className="btn btn-sm btn-soft-success mt-3"
                        onClick={() => handleConnectClick(item)}
                      >
                        <i className="mdi mdi-plus" /> Connect
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </CardBody>
      </Card>

      {/* Modal for form */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} centered={true} backdrop={"static"}>
        <div className="modal-content">
          <ModalBody className="px-4 pt-5">
            <button type="button" onClick={toggleModal} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 text-center font-size-20 text-primary">Connect {selectedPlatform?.type_name} Account</div>
            {selectedPlatform && (
              <Form onSubmit={handleSubmit}>
                {selectedPlatform.integration_type_fields.map((field: any) => (
                  <FormGroup key={field.id}>
                    <Label for={field.mapping_column}>{field.field_name}</Label>
                    <Input
                      type={field.field_datatype === 'string' ? 'text' : field.field_datatype}
                      id={field.mapping_column}
                      name={field.mapping_column}
                      value={formValues[field.mapping_column] || ''}
                      onChange={handleInputChange}
                      required
                      max={35}
                    />
                  </FormGroup>
                ))}
                <div className="hstack gap-2 mb-0 justify-content-end">
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                  <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default IntegrationCards;
