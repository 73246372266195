import React from "react";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Table } from "reactstrap";
import SocialIcon from "../../UI/SocialIcon";

const PostPublishModal = ({ isOpen, toggle, actionType, selectedPlatform, errorMessage, isSubmitting, onScheduleClick, onPublishClick, inputRef, onSubmitChange, onPublishComplete }) => {

  const handlePublishClick = async () => {
    const responses = await onPublishClick();
    if (responses) {
      onPublishComplete(responses);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size='lg'>
      <ModalBody className="px-4 py-5">
        <button type="button" onClick={toggle} className="btn-close position-absolute end-0 top-0 m-3" disabled={isSubmitting}></button>
        <div className="mb-4 text-center">
          <div className="text-primary font-size-20">{actionType === "publish" ? "Publish" : "Schedule"} Post</div>
        </div>
        
        <div className="table-responsive mt-4">
          <Table hover className="project-list-table align-middle w-100 table-borderless mb-0">
            <thead className="table-light">
              <tr><th>Platform</th><th>Validation</th><th>Info</th></tr>
            </thead>
            <tbody>
              {selectedPlatform.map((platform, index) => (
                <tr key={index}>
                  <td>
                    <SocialIcon 
                      type={platform?.integration_type_name} 
                      slug={platform?.integration_type_slug} 
                      title={platform?.integration_user || platform?.platform_username}
                    />
                  </td>
                  <td>
                    <span className={`font-size-11 badge badge-soft-${errorMessage?.[index]?.status ? 'success' : 'danger'}`}>
                      {errorMessage?.[index]?.status ? "Passed" : "Failed"}
                    </span>
                  </td>
                  <td>{errorMessage?.[index]?.errors?.map((error, index) => <p key={index}>{error}</p>)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        
        <Form onSubmit={actionType === "schedule" ? onScheduleClick : undefined}>
          {actionType === "schedule" && 
            <FormGroup className="row align-items-center my-3">
              <Label htmlFor="publish-time" className="col-lg-2 col-form-label text-lg-center fw-bold">Pick time</Label>
              <Col lg={6}><Input type="datetime-local" id="publish-time" name="publish-time"innerRef={inputRef} required/></Col>
            </FormGroup>
          }
          <div className="hstack gap-2 mb-0 justify-content-end">
            <button type="button" className="btn btn-secondary" onClick={() => { onSubmitChange(false); toggle() }} disabled={isSubmitting}>Cancel</button>
            <button type={actionType === "schedule" ? 'submit' : 'button'} className="btn btn-success" 
              onClick={actionType === "publish" ? handlePublishClick : undefined}
              disabled={isSubmitting || !(errorMessage.some(item => item?.status))}>
              {isSubmitting ? (actionType === "publish" ? "Publishing..." : "Scheduling...") : (actionType === "publish" ? "Publish" : "Schedule")}
            </button>
          </div>
        </Form>
     </ModalBody>
    </Modal>
  );
};

export default PostPublishModal;
