import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: null,
    error: false,
    loading: false,
    errorMsg: "",
    permissions: [], 
};

const userSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        getUserRequest(state) {
            state.loading = true;
            state.errorMsg = "";
            state.error = false;
        },
        getUserSuccess(state, action) {
            state.user = action.payload;
            state.loading = false;
            state.error = false;
            state.errorMsg = "";
            state.permissions = action.payload.roles?.[0].permissions.map(permission => permission.slug) ;
        },
        getUserFailure(state, action) {
            state.errorMsg = action.payload;
            state.loading = false;
            state.error = true;
        },
        resetGetUserState(state) {
            state.errorMsg = "";
            state.loading = false;
            state.error = false;
            state.user = null;
        },
        updateUserData(state: any, action) {
            state.user = {
                ...state?.user, 
                name: action.payload?.name,
                phone_number: action.payload?.phone_number,
                profile_image: action.payload?.profile_image,
            }
        },
    },
});

export const { getUserRequest, getUserSuccess, getUserFailure, resetGetUserState,updateUserData } = userSlice.actions;
export default userSlice.reducer;
