import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Spinner,} from "reactstrap";
import { getIntegrations, deleteIntegration, editIntegration } from 'helpers/API Calls/Project/Settings/Integrations';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import EditModal from './EditModal';
import AccountCard from './AccountCard';
import SettingsModal from './SettingsModal';

const ConnectedAccounts = ({refresh, socialPlatforms, OnUpdate}) => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const projectId = new URLSearchParams(location.search).get("projectId");

  const [connectedAccounts, setConnectedAccounts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [deleteModal, setDeleteModal] = useState<boolean>(false); //deleting
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false); //editing
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false); //editing

  const selectedAccount = connectedAccounts?.find(account => account.id === selectedAccountId);

  // fetch connected accounts 
  useEffect(() => {
    const fetchConnectedAccounts = async () => {
      setIsLoading(true);
      const response = await getIntegrations(projectId, navigate);
      if (response) {
        setConnectedAccounts(response);
      }
      setIsLoading(false);
    };

    fetchConnectedAccounts();
  }, [projectId, refresh, navigate]);

   // Open delete modal
  const onDeleteClick = (accountId: number) => {
    setSelectedAccountId(accountId);
    setDeleteModal(true);
  };

  // Handle disconnect account
  const handleDisconnect = async () => {
    if (selectedAccountId) {
      setIsSubmitting(true);
      const response = await deleteIntegration(selectedAccountId);
      if (response) {
        setConnectedAccounts((prevAccounts) =>
          prevAccounts.filter(account => account.id !== selectedAccountId)
        );
      }
      setIsSubmitting(false);
      setDeleteModal(false);
    }
  };

  // Open edit modal
  const onEditClick = (accountId: number) => {
    setSelectedAccountId(accountId);
    setEditModalOpen(true)
  } 

  // submit edited form
  const handleSubmit = async (formData) => {
    setIsSubmitting(true);

    const data = {
      api_type: "edit", 
      ...formData,
      project_id: projectId,
    }
    const response = await editIntegration(data, selectedAccountId)
    if(response){
      OnUpdate()
    }
    setIsSubmitting(false);
    setEditModalOpen(false);
  }

  // Open settings modal
  const onSettingsClick = (accountId: number) => {
    setSelectedAccountId(accountId);
    setSettingsModalOpen(true);
  };

  // submit settings form 
  const handleSettingsSubmit = async (formData) => {
    setIsSubmitting(true);
    const data = {
      api_type: "default", 
      integration_type_id: formData.integration_type_id, 
      author_id: formData.author.value,
      status_id: formData.status.value,
      category_id: formData.category.value,
      project_id: projectId,
    }
    const response = await editIntegration(data, selectedAccountId)
    if(response){OnUpdate()}
    setIsSubmitting(false);
    setSettingsModalOpen(false);
  }

  return (
    <>
      <Card className="p-3">
        <CardBody>
          <div className="align-items-center mb-4">
            <h5 className="">Connected social accounts</h5>
            <p className="card-title-desc">View and manage your social accounts in one place.</p>
          </div>

          {isLoading ? (
            <div className="spiner_div"><Spinner color="primary" /></div>
          ) : (
            <Row>
              {connectedAccounts.length > 0 ? (
                connectedAccounts.map((item) => (
                  <AccountCard 
                    key={item.id} 
                    item={item} 
                    onDeleteClick={onDeleteClick} 
                    onEditClick={onEditClick} 
                    onSettingsClick={onSettingsClick}
                    isDisabled={isSubmitting}/>
                ))
              ) : (
                <div className="text-center"><p>No connected accounts found.</p></div>
              )}
            </Row>
          )}
        </CardBody>
      </Card>
      
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDisconnect}
        onCloseClick={() => setDeleteModal(false)}
        message={`${selectedAccount?.integration_type?.type_name} account "${selectedAccount?.integration_user}"?`}
      />

      {editModalOpen && (
        <EditModal
          account={selectedAccount}
          socialPlatforms={socialPlatforms}
          onSubmit={handleSubmit}
          onClose={() => setEditModalOpen(false)}
          isSubmitting={isSubmitting}
        />
      )}

      {settingsModalOpen && (
        <SettingsModal
          account={selectedAccount}
          onSubmit={handleSettingsSubmit}
          onClose={() => setSettingsModalOpen(false)}
          isSubmitting={isSubmitting}
        />
      )}

    </>
  );
};

export default ConnectedAccounts;
