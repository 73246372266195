import React, { useState } from "react";
import { CardBody, CardTitle, Table } from "reactstrap";
import CollapseItem from "./UI/CollapseItem";
import { LinkWithQuery } from "helpers/link_helper";
import convertDateFormat from "Components/Custom/DateFormat";

interface LatestActivityProps {
  activities: {
    id: number;
    title: string;
    activity_type: string;
    state_name: string;
    created_at: "string";
  }[];
  canPublish: boolean;
}
const LatestActivity: React.FC<LatestActivityProps> = ({ activities, canPublish }) => {
  const [collapseStates, setCollapseStates] = useState(true);
  return (
        <CollapseItem title="Recent Activities" col={collapseStates} toggle={() => setCollapseStates((prevState) => !prevState)}>
           <CardBody>

          {activities?.length !== 0 && <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead>
                <tr className="font-medium">
                  <th scope="col" className="ps-0">Activity</th>
                  <th scope="col">State</th>
                  {canPublish && <th scope="col">Action</th>}
                </tr>
              </thead>

              <tbody>
                {activities?.map((item: any) => (
                  <tr key={item.id}>
                    <td className="ps-0">
                      <LinkWithQuery
                        to={`/posts-details/${item.id}`}
                        key={item.id}
                        queries={`return=posts-list&projectId=${item.project_id}`}
                      >
                        <div className="d-flex">
                          <div className="flex-shrink-0 my-auto me-3">
                            <div className="avatar-xs">
                              <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                                {item.title?.charAt(0)}
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5
                              className="font-size-13 text-truncate mb-1 text-dark"
                              title={item.title}
                              style={{ maxWidth: "280px" }}
                            >
                              {item.title}
                            </h5>
                            <p className="text-muted mb-0">
                              {item.activity_type} - Started on{" "}
                              {convertDateFormat(item.created_at)}
                            </p>
                          </div>
                        </div>
                      </LinkWithQuery>
                    </td>
                    <td>{item.state_name}</td>
                    {canPublish && <td>
                      <LinkWithQuery
                        to={`/posts-details/${item.id}/publish`}
                        queries={`return=posts-list&projectId=${item.project_id}`}
                        className="btn btn-soft-success btn-sm me-1"
                      >
                        Publish
                      </LinkWithQuery>
                    </td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>}
          {activities?.length === 0 && 
          // <p className="text-center text-danger">You do not have any activity assigned.</p>
          <CardBody>
            <CardTitle tag="h4">No Activities Assigned.</CardTitle>
            <p className="text-center text-muted">You currently have no activities assigned. Please check back later or contact your team lead for updates.</p>
          </CardBody>
          }
        </CardBody>
      </CollapseItem>
  );
}

export default LatestActivity;
