import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PaginatedCards from "Components/Custom/Table and Cards/PaginatedCards";
import CustomIcon from "Components/Custom/CustomIcon";
import { createSelector } from "reselect";
import { useLocation } from "react-router-dom";
import DeleteModal from "Components/Custom/DeleteModalWithMsg";
import { useSelector } from "react-redux";
import { getActivityType } from "slices/thunk";
import { createActivityType, deleteActivityType, editActivityType } from "helpers/API Calls/Project/Settings/Activity Type";

const selectProperties = createSelector(
  (state: any) => state.activityType,
  (state: any) => state.user,
  (activityType, user) => ({
    types: activityType.activityTypeList,
    loading: activityType.loading,
    pagination: activityType.pagination,
    permissions: user.permissions,
    user: user.user
  })
);



function ActivityType() {
  document.title = "Activity Type | Content Pro";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");

  const { types, loading, pagination, permissions, user } = useSelector(selectProperties);

  const [typesData, setTypesData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // New state to track submitting

  useEffect(() => {
    setIsLoading(loading);
    setTypesData(types);
  }, [types, loading]);


  const [modal, setModal] = useState<boolean>(false);
  const [typeData, setTypeData] = useState({id: null, activity_type: '', color: '#453656'});
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  // Reset stateData on modal close
  const resetTypeData = () => {
    setTypeData({
      id: null,
      activity_type: '',
      color: '#453656',
    });
  };


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    const data = {
      project_id: projectId, 
      activity_type: typeData.activity_type, 
      color: typeData.color 
    };

    let response; 
    if (typeData.id) {
      response = await editActivityType(data, typeData.id);
    } else {
      response = await createActivityType(data);
    }

    setIsDeleted(response);
    setModal(false);
    resetTypeData();
    setIsSubmitting(false); // Reset submitting state
  };


  const handleDelete = async () => {
    setIsSubmitting(true);
    if (typeData) {
      const response = await deleteActivityType(typeData.id);
      setDeleteModal(false);
      setIsDeleted(response);
      resetTypeData();
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const canAdd = user?.user_type === 'Member' ? permissions?.includes('add_activity_type') : true;
  const canEdit = user?.user_type === 'Member' ? permissions?.includes('edit_activity_type') : true;
  const canDelete = user?.user_type === 'Member' ? permissions?.includes('delete_activity_type') : true;
  

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {resetTypeData(); setDeleteModal(false);}}
        message={`Activity Type: "${typeData?.activity_type}"`}
      />

      <Modal isOpen={modal} toggle={() => {setModal(false); resetTypeData()}} centered={true} backdrop={"static"}>
        <div className="modal-content">
          <ModalBody className="px-4 pt-5">
            <button type="button" onClick={() => {setModal(false); resetTypeData()}} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 text-center font-size-20 text-primary">{typeData.id ? "Edit" : "Add"} Activity Type</div>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label className="col-form-label">Title</Label>
                <Input type="text" id="title" name="title" className="form-control" value={typeData.activity_type} onChange={(e) => setTypeData(prev => ({ ...prev, activity_type: e.target.value }))} disabled={isSubmitting} required maxLength={22} />
                <Label className="col-form-label">Color</Label>
                <Input
                  type="color"
                  id="color"
                  name="color"
                  className="form-control form-control-color w-100"
                  onChange={(e) => setTypeData(prev => ({ ...prev, color: e.target.value }))}
                  value={typeData.color || ""}
                  disabled={isSubmitting} // Disable input during submission
                />
              </FormGroup>
              <div className="hstack gap-2 mb-0 justify-content-end">
                <button type="button" className="btn btn-secondary" onClick={() => { setModal(false); resetTypeData(); }}>Close</button>
                <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Save"}
                </button>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <div className="page-content">
        <Container fluid>
          <Card className="spiner_div_activities">
            <PaginatedCards
              onGetData={getActivityType}
              pagination={pagination}
              isLoading={isLoading}
              isAddButton={false}
              isAddModal={canAdd}
              onClick={() => {setModal(true);}}
              buttonText="Add New Type"
              query={`project_id=${projectId}`}
              isDeleted={isDeleted}
              title="Activity Types"
            >
              <Row>
                {typesData?.length !== 0 && typesData?.map((item) => (
                    <Col lg={4} md={6} key={item.id}>
                      <div className="carousel-item active">
                        <div className="bg-light p-3 d-flex mb-3 rounded">
                          <div className="rounded me-3" style={{ backgroundColor: `${item.color}`, height: "2rem", width: "4rem" }}></div>
                          <div className="my-auto">
                            <h5 className="font-size-15 mb-2">{item.activity_type}</h5>
                          </div>
                          <div className="flex-grow-1 my-auto text-end">
                            {(canEdit || canDelete) && (
                              <UncontrolledDropdown>
                                <DropdownToggle className="btn btn-soft-primary" type="button" id="dropdownMenuButton11">
                                  <CustomIcon className="bx " iconName="bx-dots-vertical-rounded" id={`action-${item.id}`} text="actions" />
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="dropdownMenuButton11" className="dropdown-menu-end">
                                  {canEdit && <li onClick={() => { setTypeData({ id: item.id, activity_type: item.activity_type, color: item.color }); setModal(true); }}><DropdownItem href="#">Edit</DropdownItem></li>}
                                  {canDelete && <li onClick={() => { setTypeData({ id: item.id, activity_type: item.activity_type, color: item.color }); setDeleteModal(true); }}><DropdownItem href="#">Delete</DropdownItem></li>}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                {typesData?.length === 0 && (<div className="text-center"><p className="text-danger">No Data Found</p></div>)}
              </Row>
            </PaginatedCards>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default ActivityType;
