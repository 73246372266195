import React from "react";
import { darken, rgba } from "polished";

function Badge({ color, title }) {
  return (
    <div
      className="badge font-size-12 px-1"
      style={{
        backgroundColor: rgba(color, 0.8),
        color: darken(0.7, color),
        borderRadius: "5px",
      }}
    >
      {title}
    </div>
  );
}

export default Badge;
