import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getBusinessDetail, editBusiness } from "helpers/API Calls/Super Admin/businesses";
import { Spinner } from "reactstrap";
import placeholderImage from "assets/images/img.png"
import Dropzone from "Components/Custom/DropZone";
import withRouter from "Components/Common/withRouter";

function Settings({ router }: any) {
  const { navigate, location } = router;
  document.title = "General Settings | Content Pro";

  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [businessData, setBusinessData] = useState<any>(null); // business data
  const [imageUrl, setImageUrl] = useState<string>(""); // state for image URL

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const data = await getBusinessDetail(businessId, navigate);
      setBusinessData(data);
      setImageUrl(data?.image);
      validation.setValues({
        title: data?.business_name,
        key: data?.business_key,
      });
      setIsLoading(false);
    };
    getData();
  }, [businessId, navigate]);

  // delete modal
  const [modal, setModal] = useState<boolean>(false); 
  const handleDelete = async() => {
    const data = { ...businessData, status: '0' };
    const isDisabled = await editBusiness({data: data,}, businessData.id, navigate);
    if(isDisabled){
      const user = JSON.parse(localStorage.getItem("authUser") || "{}");
      const role = user.role;
      role !== "Admin" && navigate("/logout"); 
    };
    setModal(false)
  }

  // image handling
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  // validation
  const validation: any = useFormik({
    initialValues: {
      title: "",
      key: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
      .matches(/^[A-Za-z0-9\s]+$/, 'Organization Name can only contain letters, numbers, and spaces')
      .min(3, 'Organization Name must be at least 3 characters')
      .max(50, 'Organization Name cannot exceed 50 characters')
      .required("Please Enter Your Organization Name"),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {...businessData, business_name: values.title}
      const response = await editBusiness({data: data, image: selectedImage || null}, businessData.id, navigate); 
      if (response) {setSubmitting(false);}
      return; 
    }
  });

  return (
    <>
    <Modal isOpen={modal} toggle={() => setModal(false)} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={() => setModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="mb-4 mx-auto">
            <div className="text-primary font-size-20">
              Deactivate Business
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">Are you sure you want to deactivate the business. </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-secondary" onClick={() => setModal(false)}>Close</button>
            <button type="button" className="btn btn-danger" onClick={handleDelete}>Deactivate</button>
          </div>
        </ModalBody>
      </div>
    </Modal>

    <div className="page-content">
      <Container fluid>
        <Card className="spiner_div_activities">
        {isLoading ? (
            <Spinner
              color="primary"
              className="position-absolute top-50 start-50"
            />
          ) : (
          <CardBody className="border-bottom p-3">
            <Row>
              <div className="">
                  <h5 className="">Space Setting</h5>
              </div>
            </Row>

            <Row>
              <Col lg={5} sm={7} className="mx-auto">
                <Form className="mt-4" onSubmit={(e: any) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                
                  <Row>
                    <Col className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="name-input">Business Logo</Label>
                        <Dropzone
                          accept = {{"image/*": [".png", ".jpeg", ".jpg"]}}
                          onDrop = {(acceptedFiles) => {setSelectedImage(acceptedFiles[0]);}}
                          renderContent = {() => (
                            <div className="text-center cursor-pointer">
                              <div className="position-relative d-inline-block" >
                                <div className="avatar-xl rounded">
                                  <div className="avatar-title bg-light ">
                                    {selectedImage ? (
                                      <img src={URL.createObjectURL(selectedImage)} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    ) : (
                                      <img src={imageUrl || placeholderImage} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        />

                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="name-input">Business Name</Label>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Enter Title"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title}
                          invalid={validation.touched.title && validation.errors.title ? true : false}
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.title}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mx-auto">
                      <div className="mb-3">
                        <Label htmlFor="key">Business Key</Label>
                        <Input
                          id="key"
                          name="key"
                          type="text"
                          placeholder="Enter Key"
                          disabled={true}
                          value={validation.values.key}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-end mx-auto px-1 mt-4">
                      <Button type="button" color="danger" onClick={() => {setModal(true)}}>Deactivate Business</Button>
                      <Button type="submit" color="success" disabled={validation.isSubmitting}>{validation.isSubmitting ? 'Saving... ' : 'Save Changes'}</Button>
                    </div>
                  </Row>

                </Form>
              </Col>
            </Row>
          </CardBody>
          )
          }
        </Card>
      </Container>
    </div>
    </>
  );
}

export default withRouter(Settings);
