import React, { useEffect } from "react";
import { Container, Alert } from "reactstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

  const selectProperties = createSelector(
    (state: any) => state.user,
    (user) => ({user: user.user,})
  );

const StripePricingTable = () => {
  document.title = "Pricing Tables | Content Pro";

  const { user} = useSelector(selectProperties);

  const subscriptionStatus = user.subscription_status;

  const getMessage = (status) => {
    switch (status) {
      case "paused":
        return {
          message: "Your subscription is currently paused. To regain access, please select a plan and reactivate your subscription.",
          alertType: "warning",
        };
      case "canceled":
        return {
          message: "Your subscription has been canceled. Please choose a new plan to continue using the system.",
          alertType: "danger",
        };
      case "past_due":
        return {
          message: "Your subscription payment is past due. Please update your payment information or select a new plan to continue.",
          alertType: "danger",
        };
      default:
        return null;
    }
  };

  const userMessage = getMessage(subscriptionStatus);

  return (
    <div className="page-content">
      <Container fluid>
        {userMessage && (
          <Alert color={userMessage.alertType} className="text-center">
            <h5 className="mb-3">Subscription Issue</h5>
            <p>{userMessage.message}</p>
          </Alert>
        )}
        <div className="mt-4">
          <Tables />
        </div>
      </Container>
    </div>
  );
};

export default StripePricingTable;

const Tables = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": "prctbl_1PcuzcBqDqlelu6l5c6sVKWE",
    "publishable-key":
      "pk_test_51PQPeBBqDqlelu6lmsRt9XX8Sy4sFE4LRj2a8v4aStBkPaRK52FLw7gEWsMd1uZXS11VQBbtxw9X0xma0gjedQjT006fOSw7HQ",
  });
};

// {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
// <stripe-pricing-table pricing-table-id="prctbl_1PcuzcBqDqlelu6l5c6sVKWE"
// publishable-key="pk_test_51PQPeBBqDqlelu6lmsRt9XX8Sy4sFE4LRj2a8v4aStBkPaRK52FLw7gEWsMd1uZXS11VQBbtxw9X0xma0gjedQjT006fOSw7HQ">
// </stripe-pricing-table> */}