import React from "react";
import { Navigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({ user: user.user })
);

function RoleNavigation() {
  const { user } = useSelector(selectProperties);

  return (
    <>
      {user.user_type === "Admin" ? (
        <Navigate to="/admin" />
      ) : (
        <Navigate to={`/dashboard?businessId=${user.business_id}`} />
      )}
    </>
  );
}

export default RoleNavigation;
