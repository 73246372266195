import React from 'react'
import { Link } from 'react-router-dom'
import logo from "assets/images/logo-sm.png";
import logoDark from "assets/images/logo-large.png";

function Logo() {
  return (
    <div className="home-btn-auth d-none d-sm-block">
    <Link to="/" className="text-dark d-flex">
    <div className="navbar-brand-box">
        <div className="logo logo-dark">
        <span className="logo-sm">
            <img src={logo} alt="" height="40" />
        </span>
        <span className="logo-lg">
            <img src={logoDark} alt="" height="40" />
        </span>
        </div>
    </div>
    </Link>
</div>
  )
}

export default Logo