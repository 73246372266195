import convertDateFormat from "Components/Custom/DateFormat";
import convertToTime from "Components/Custom/TimeFormat";
import React from "react";

const History = ({name, timestamp, action}) => {
  return (
    <li className="event-list">
      <div className="event-timeline-dot">
        <i className="bx bx-right-arrow-circle font-size-18"></i>
      </div>
      <div className="d-flex">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-xs">
            <div className="avatar-title bg-primary-subtle text-primary bg-soft rounded-circle">
                {name?.charAt(0)}
            </div>
          </div>
        </div>
        <div className="flex-grow-1">
          <div>
            <b>{name}</b> {action}
            <p className="mb-0 text-muted">{convertDateFormat(timestamp)} at {convertToTime(timestamp)}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default History;
