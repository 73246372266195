import request from "helpers/request_helper";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import { toast } from "react-toastify";
import toasterTime from "config";

export const loginuser = (user: any, history: any) => {
    return async (dispatch: any) => {
    try {
        const args = {
            url: "login",
            body: JSON.stringify(user),
            method: 'POST', 
            headers: {
            'Content-Type' : 'application/json'
            }
        };

        const response = await request(args, history); 
        const data = await response;

        localStorage.setItem("token", data.data.token);
        const current_user = data.data.user
        const userData = {
            name: current_user.name, 
            email: current_user.email,
            role: current_user.user_type, 
            business_id: current_user.business_id, 
            email_verification: current_user.email_verified_at,
            created_at: current_user.created_at, 
        }
        localStorage.setItem("authUser", JSON.stringify(userData));
        dispatch(loginSuccess(userData));
        // userData.role === "Admin" ? history('/admin'): history(`/dashboard?businessId=${userData.business_id}`);
        if(current_user.user_type === 'Business-Owner' && !data.data.subscription.access_status){
            history(`/payments?businessId=${current_user.business_id}`)
        }else{
            history('/')
        }

    } catch (error: any) {
        dispatch(apiError(error.message));
    }
}
}

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        const token = localStorage.getItem('token')
        const args = {
            url: "logout",
            method: 'POST', 
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
              }
        };

        await request(args, history); 
        
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        dispatch(logoutUserSuccess(true));
        
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        dispatch(apiError(error.message));       
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};

