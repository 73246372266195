import React, { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {Collapse,} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import { addActivityComments } from "helpers/API Calls/Project/Activity/Activity";
import convertToTime from "Components/Custom/TimeFormat";
import convertDateFormat from "Components/Custom/DateFormat";

const Comments = ({feed, user, users}) => {
  const { id } = useParams();

  const [text, setText] = useState("");
  const [formattedComments, setFormattedComments] = useState(feed || []);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [collapse, setCollapse] = useState<boolean>(false)

  const handleAddComment = async () => {
    setIsSubmitting(true)
    const formattedText = text.replace(/@\[(.+?)\]\((\d+?)\)/g, "'@$1'");
    const data = {
      activity_id: id, 
      type: "discussion",
      comment: formattedText, 
      comment_by: user.id,
    }
    const response = await addActivityComments(data); 
    if(response){setIsSubmitting(false)}
    if (response?.success) {
      setFormattedComments((prevComments) => [
        {
          id: new Date().toISOString(),
          user: { id: user.id, name: user.name, img: "" },
          comment: formattedText,
          updatedAt: new Date().toISOString(),
          action_type: 'message'
        },
        ...prevComments,
      ]);
      setText("");
    }
  };



  return (
    <React.Fragment>
        <Card className="Notes-activity-coments">
        <div className="accordion">
          <button
            className={classnames("accordion-button rounded-top ps-0", "fw-medium", {collapsed: !collapse})} type="button" onClick={() => {setCollapse(prev => !prev)}} style={{ cursor: "pointer" }}>
            <h5 className="fw-bold font-size-21 p-2">Comments</h5>
          </button>
        </div>
          <Collapse isOpen={collapse} className="accordion-collapse">
          <CardBody className="pb-3 bg-light rounded">
              <div className="pb-3 rounded rounded-3 col-lg-9">
                <Row className="align-items-center g-2">
                  <Col md={11} sm={9} id='tag-input-holder'>
                    <MentionsInput
                      id='tag-input'
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      className="form-control border-0 rounded p-2"
                      placeholder="Enter Comments..."
                      singleLine = {true}
                    >
                      <Mention
                        trigger="@"
                        data={users.map((user:any) => ({ id: user.id, display: user.name }))}
                        className = 'mention'
                        id='tag-text'
                      />
                    </MentionsInput>
                  </Col>
                  <Col sm="auto">
                    <Button type="submit" color="success" className="w-100" onClick={handleAddComment} disabled={isSubmitting}>
                      <i className="mdi mdi-send" />
                    </Button>
                  </Col>
                </Row>
              </div>
              <ul className="activity-timeline list-unstyled">
                {(formattedComments || [])?.map(
                  (event) => (
                    <li key={event.id} className="event-list">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          {event.user?.img  ? 
                           <img
                              src={event.user?.img}
                              alt={event.user?.name}
                              className="avatar-xs rounded-circle"
                            />
                          :
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary font-size-16">
                              {event.user?.name.charAt(0)}
                            </span>
                          </div>
                          }
                        </div>
                        {/* data or message */}
                        <div className="flex-grow-1">
                          <div>
                            {event.action_type === "activity" && (
                              <>
                                <b>{event.name}</b> {event.action}
                                <b>{event.jobTitle}</b>
                                <p className="mb-0 text-muted">{event.timestamp}</p>
                              </>
                            )}
                            {event.action_type === "message" && (
                              <>
                                <b className="me-1">{event.user?.name}{event.user?.id === user.id && <span className="text-muted"> (You)</span>}</b> {`${convertDateFormat(event.updatedAt)} at ${convertToTime(event.updatedAt)}`}
                                <CardBody className="bg-white py-3 px-2 rounded rounded-3 col-lg-9">
                                  {highlightMentions(event.comment)}
                                </CardBody>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
          </CardBody>
          </Collapse>
        </Card>
    </React.Fragment>
  );
};

export default Comments;

export const highlightMentions = (text) => {
  const mentionRegex = /'@([\w\s]+)'/g;
  const formattedText = text.replace(mentionRegex, (match, p1) => `<span class="fw-bold text-primary">@${p1}</span>`);
  return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
};
