import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import ProjectTitle from "./ProjectTitle";
import MonthlyChart from "./MonthlyChart";
import LatestActivity from "./LatestActivities";
import PublishStatus from "./PublishStatus";
import ScheduleCalender from "./ScheduleCalender";
import TeamStats from "./TeamsStats";
import IntegrationsStats from "./IntegrationsStats";
import ActivityStats from "./ActivityStats";
import { getHomeData } from "helpers/API Calls/Project/Home";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import withRouter from "Components/Common/withRouter";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({
    user: user.user,
    permissions: user.permissions,
  })
);

function Dashboard ({ router }: any) {
  const { navigate, location } = router;

  document.title = "Project | Content Pro";

  const projectId = new URLSearchParams(location.search).get("projectId");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectData, setProjectData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getHomeData(projectId, navigate);
      if (data) {
        setProjectData(data);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [projectId, navigate]);

  const { user, permissions } = useSelector(selectProperties);

  const canPublish =
    user?.user_type === "Member" ? permissions?.includes("publish_post") : true;
  const canAdd =
    user?.user_type === "Member" ? permissions?.includes("create_post") : true;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isLoading ? (
            <Spinner
              color="primary"
              className="position-absolute top-50 start-50"
            />
          ) : projectData ? (
            <>
              <ProjectTitle
                image={projectData?.project?.thumbnail}
                name={projectData?.project?.project_name}
                type={projectData?.project?.project_type}
                projectKey={projectData?.project?.project_key}
                date={projectData?.project?.started_at}
                activityCounts={projectData?.["activity-status"]}
                canAdd={canAdd}
              />

              <Row>
                <Col lg={8}>
                  <ActivityStats
                    activityDistribution={projectData?.["activity-status"]}
                    activityStates={projectData?.["activity-states"]}
                  />
                  <MonthlyChart data={projectData?.["yearly-activities"]} />
                  <LatestActivity
                    activities={projectData?.activities}
                    canPublish={canPublish}
                  />
                </Col>

                <Col lg={4}>
                  <TeamStats
                    teams={projectData?.teams?.map((item) => ({
                      name: item.team_name,
                      count: item.value,
                    }))}
                  />
                  <PublishStatus
                    rawData={projectData?.["posts-status"]?.[0]}
                  />
                  <ScheduleCalender />
                </Col>
              </Row>
              <IntegrationsStats platforms={projectData?.integrations} />
            </>
          ) : (
            <div className="text-center mt-5">
              <h4 className="text-danger">Unable to Load Project Data</h4>
              <p className="text-muted">
                We're sorry, but we couldn't load the project information at
                this time. Please check your internet connection or try
                reloading the page. If the problem persists, contact support for
                assistance.
              </p>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Dashboard);
