import { toast } from "react-toastify";
import request from "../../request_helper";
import toasterTime from "config";

export const getDashboardData = async (id, navigate: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "dashboard/" + id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args, navigate); 
        const data = await response;
        return data?.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}


