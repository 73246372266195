import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, FormFeedback, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from "Components/Common/withRouter";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import placeholderImage from "assets/images/img.png"
import Dropzone from "Components/Custom/DropZone";
import { updateProfile } from "helpers/API Calls/General/Profile";
import { useDispatch } from "react-redux";
import { updateUserData } from "slices/current user/reducer";

const selectProperties = createSelector(
  (state: any) => state.user,
  (user) => ({ user: user.user,})
);

const UserProfile = () => {
  // meta title
  document.title = "Profile | Content Pro";
  const { user } = useSelector(selectProperties);
  
  const dispatch = useDispatch(); 

  //  img upload
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const validation = useFormik({
    initialValues: {
      username: user.name || '',
      email: user.email || "", 
      phone: user.phone_number || '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'User Name can only contain letters and spaces')
      .min(3, 'Username must be at least 3 characters')
      .max(20, 'Username cannot exceed 20 characters')
      .required("Please Enter Your Username"),
      email: Yup.string()
      .email("Please enter a valid email address")
      .required("Please Enter Your Email"), 
      phone: Yup.number()
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .min(8, "Phone Number is too small")
      .required("Please Enter Your Phone Number")
      // .max(30, "Phone Number is too large"), 
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      const data = {
        name: values.username,
        phone_number: values.phone,
      }
      // console.log({ data: data, profile_image: selectedImage || null })
      const response = await updateProfile({ data: data, profile_image: selectedImage || null });

      if (response) {
        setSubmitting(false);
      }
  
      if (response?.success) {
        dispatch(updateUserData({
          name: values.username,
          phone_number: values.phone,
          profile_image: selectedImage ? URL.createObjectURL(selectedImage) : user.profile_image,
        }))
      }
      return;

    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card >
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <h5 className="mb-0 flex-grow-1 ">Profile Settings</h5>
                <div className="flex-shrink-0">{/* ride justified data */}</div>
              </div>
              <Row>
                <Col lg={5} sm={7} className="mx-auto">
                  <Form id="user-info" className="mt-4" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit();return false;}}>

                    <Row>
                      <Col className="mx-auto">
                        <div className="mb-3">
                          <Label htmlFor="name-input">Profile Image</Label>
                          <Dropzone
                            accept = {{"image/*": [".png", ".jpeg", ".jpg"]}}
                            onDrop = {(acceptedFiles) => {setSelectedImage(acceptedFiles[0]);}}
                            renderContent = {() => (
                              <div className="text-center cursor-pointer">
                                <div className="position-relative d-inline-block" >
                                  <div className="avatar-xl rounded">
                                    <div className="avatar-title bg-light ">
                                      {selectedImage ? (
                                        <img src={URL.createObjectURL(selectedImage)} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                      ) : (
                                        <img src={user.profile_image || placeholderImage} id="logo-img" alt="" className="avatar-xl rounded h-auto "/>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group">
                      <Label className="form-label">User Name</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={validation.touched.username && !!validation.errors.username}
                      />
                      {validation.touched.username && validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.username === 'string' && validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-group mt-4">
                      <Label className="form-label">Email Address</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        type="email"
                        disabled
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={validation.touched.email && !!validation.errors.email}
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.email === 'string' && validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-group  mt-4">
                      <Label className="form-label">Phone Number</Label>
                      <Input
                        name="phone"
                        className="form-control"
                        placeholder="Enter phone Number"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone || ""}
                        invalid={validation.touched.phone && !!validation.errors.phone}
                      />
                      {validation.touched.phone && validation.errors.phone ? (
                        <FormFeedback type="invalid">
                          {typeof validation.errors.phone === 'string' && validation.errors.phone}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="text-end mt-4">
                      <Button type="submit" color="success" disabled={validation.isSubmitting}>{validation.isSubmitting ? 'Saving...' : 'Save Changes'}</Button>
                    </div>
                    
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
