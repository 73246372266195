import React, { useEffect, useState } from "react";
import { Col, Row, Container, Spinner, Card, CardBody } from "reactstrap";
import VersioningFeed from "./Logs/VersoningFeed";
import { getActivityDetail } from "helpers/API Calls/Project/Activity/Activity";
import Header from "./UI/activity-header";
import Breadcrumbs from "Components/Common/Breadcrumb";
import withRouter from "Components/Common/withRouter";

function Logs({ router }: any) {
  const { navigate, location, params } = router;
  document.title = "Activity History | Content Pro";
  const { id } = params;
  const searchParams = new URLSearchParams(location.search);
  const returnURL = searchParams.get("return");
  const [activityData, setActivityData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      // Fetch activity data
      const data = await getActivityDetail(id, navigate);
      setActivityData(data || {});  // Set data or empty object if data is null/undefined
      setIsLoading(false);
    };
    getData();
  }, [id, isUpdated, navigate]);

  useEffect(() => {
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs 
          title="Activity" 
          breadcrumbItem="Versions History" 
          isGoBackButton={true} 
          project={true} 
          to={`/posts-details/${id}`}
          queries={`return=${returnURL}`} 
        />
        <Row>
          <Col>
            {isLoading ? (
              <div className="spiner_div">
                <Spinner color="primary" />
              </div>
            ) : Object.keys(activityData).length === 0 ? (
              <div className="text-center">
                <p className="text-danger">Data not found.</p>
              </div>
            ) : (
              <Card>
                <CardBody>
                  <Header 
                    state={activityData?.activity_state?.state_name || "N/A"}
                    stateColor={activityData?.activity_state?.color || "#ccc"}
                    type={activityData?.activity_types?.activity_type || "N/A"}
                    typeColor={activityData?.activity_types?.color || "#ccc"}
                    date={activityData?.created_at || "N/A"}
                    due={activityData?.due_date}
                    creator={activityData?.creator_name || "N/A"}
                    team={activityData?.team_name || ""}
                    title={activityData?.title || "Untitled"}
                  />

                  {/* Conditional rendering for version logs */}
                  {activityData?.version_logs && activityData?.version_logs.length > 0 ? (
                    <VersioningFeed logs={activityData?.version_logs} title={activityData?.title || "Untitled"} id={id} setIsUpdated={setIsUpdated}/>
                  ) : (
                    <div className="text-center mt-4">
                      <p className="text-danger">No version logs available.</p>
                    </div>
                  )}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Logs);
