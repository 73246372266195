// App.tsx
import React from "react";
import { Container, Row } from "reactstrap";
import KanbanBoard from "./kanban/kanban";

const ActivityKanban: React.FC = () => {
  document.title = "Activity Board | Content Pro";

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <KanbanBoard />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ActivityKanban;
