import { getAuthors, getCategories, getStatuses } from 'helpers/API Calls/Project/Publish/WordPress';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Form, Label, Row, Col } from "reactstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function SettingsModal({ account, onSubmit, onClose, isSubmitting }) {
  console.log(account)
    
    const [formOptions, setFormOptions] = useState<any>({}); //dropdown and input options
    const [formData, setFormData] = useState<any>({ author: null, status: null, category: null}); //selected options and inputs
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      // get all options
      const statuses = await getStatuses(); 
      const categories = await getCategories(account.id); 
      const authors = await getAuthors(account.id); 
      // set all options 
      setFormOptions({
          statuses: (statuses || [])?.map(item => ({label: item?.status_name, value:item?.id})),
          categories: (categories || [])?.map(item => ({label: item?.name, value:item?.id})),
          authors: (authors || [])?.map(item => ({label: item?.name, value:item?.id})),
      })
      // find if user has already selected any options and set them
      const selectedAuthor = authors?.find(item => item?.id === account.author_id);
      const selectedStatus = statuses?.find(item => item?.id === account.status_id);
      const selectedCategory = categories?.find(item => item?.id === account.category_id);
      setFormData({ 
        author: { label: selectedAuthor?.name, value: selectedAuthor?.id }, 
        status: { label: selectedStatus?.status_name, value: selectedStatus?.id }, 
        category: { label: selectedCategory?.name, value: selectedCategory?.id }, 
      });
      setIsLoading(false);
    }
    if (account) {
        getData(); 
    }
  }, [account]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({integration_type_id: account.integration_type_id, ...formData });
  };

  return(
    <Modal isOpen={!!account} toggle={onClose} centered={true} backdrop={"static"}>
        <div className="modal-content">
        <ModalBody className="px-4 pt-5">
            <button type="button" onClick={onClose} className="btn-close position-absolute end-0 top-0 m-3"></button>
            <div className="mb-4 text-center font-size-20 text-primary">{account?.integration_user} Settings</div>
            {account && (
            <Form onSubmit={handleSubmit}>
                <Row className='mb-3'> 
                    <Col md={3}><Label className="col-form-label">Category</Label></Col>
                    <Col md={9} >
                    <Select
                        components={animatedComponents}
                        value={formData.category}
                        onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, category: selectedOption}})}
                        isSearchable={true}
                        name="category"
                        options={formOptions.categories}
                        isDisabled={isSubmitting || isLoading}
                        isLoading={isLoading}
                        styles={{
                            menu: base => ({
                                ...base,
                                zIndex: 9999,
                                backgroundColor: 'white',
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
                                color: state.isSelected ? 'white' : 'black',
                            }),
                        }}
                    />
                    </Col>
                </Row>

                <Row className='mb-3'> 
                    <Col md={3}><Label className="col-form-label">Status</Label></Col>
                    <Col md={9}>
                    <Select
                        components={animatedComponents}
                        value={formData.status}
                        onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, status: selectedOption}})}
                        isSearchable={true}
                        name="websiteStatus"
                        options={formOptions.statuses}
                        isLoading={isLoading}
                        isDisabled={isSubmitting || isLoading}
                        styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 9999,
                              backgroundColor: 'white',
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
                              color: state.isSelected ? 'white' : 'black',
                            }),
                        }}
                    />
                    </Col>
                    
                </Row>

                <Row className='mb-3'> 
                    <Col md={3}><Label className="col-form-label">Author</Label></Col>
                    <Col md={9} >
                    <Select
                        components={animatedComponents}
                        value={formData.author}
                        onChange={(selectedOption) => setFormData((prev : typeof formData) => {return {...prev, author: selectedOption}})}
                        isSearchable={true}
                        name="author"
                        options={formOptions.authors}
                        isLoading={isLoading}
                        isDisabled={isSubmitting || isLoading}
                        styles={{
                            menu: base => ({
                              ...base,
                              zIndex: 9999,
                              backgroundColor: 'white',
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#f8f9fa' : 'white',
                              color: state.isSelected ? 'white' : 'black',
                            }),
                        }}
                    />
                    </Col>
                </Row>

                <div className="hstack gap-2 mb-0 justify-content-end">
                  <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                  <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Save"}
                  </button>
                </div>
            </Form>
            )}
        </ModalBody>
        </div>
    </Modal>
  );
}

export default SettingsModal;
