import { toast } from "react-toastify";
import request from "../../../request_helper";
import getFormData from "Components/Custom/Formdata";
import toasterTime from "config";


export const getProjectType = async () => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `project_types`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args); 
        // toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data?.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const getProjects = async (businessId: any, navigate: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `projects?limit=10000&business_id=${businessId}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args, navigate); 
        // toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data?.data?.items
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const createProject = async (projectData, navigate: any) => {
    // console.log('projectData', projectData)
    const formData = getFormData(projectData);
    // console.log('From api call',formData)
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "projects",
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body : formData,
        };
        const response = await request(args, navigate); 
        // await response;
        const data = await response;
        // console.log('from api call' , data)
        toast.success(response.message, { autoClose: toasterTime});
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const getProjectDetail = async (Id: any, navigate: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: `projects/${Id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args, navigate); 
        // toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        return data?.data
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const editProject = async (projectData, id, navigate: any) => {
    // console.log(projectData)
    const formData = getFormData(projectData);
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "projects/" + id,
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body : formData,
        };
        const response = await request(args, navigate); 
        // await response;
        const data = await response;
        // console.log(data)
        toast.success(response.message, { autoClose: toasterTime});
        return data; 
        // history();
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
    }
}

export const deleteProject = async (projectId, navigate: any) => {
    try {
        const token = localStorage.getItem('token');
        const args = {
            url: "projects/" + projectId,
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        };
        const response = await request(args, navigate); 
        toast.success(response.message, { autoClose: toasterTime});
        const data = await response;
        // console.log('delete data')
        console.log(data)
        // setTimeout(()=> {history();}, 1000)
        return true; 
    } catch (error: any) {
        toast.error(error.message, { autoClose: toasterTime});
        return false; 
    }
}


